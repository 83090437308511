import {
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Pagination } from "@material-ui/lab";
import CDialogDelete from "../../../../common/CDialogDelete";
import { useState } from "react";

export default function BanksList(props) {
  const {
    banksList,
    history,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
    deleteBank,
  } = props;

  const [openDelete, setOpenDelete] = useState(false);
  const [dataDelete, setDataDelete]: any = useState({});

  const actionConfirmDeleteBank = () => {
    deleteBank(dataDelete.id);
    setOpenDelete(false);
  };

  return (
    <>
      <Paper className="c-mt-20 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Código</TableCell>
                    <TableCell className="c-text-center">Nombre</TableCell>
                    <TableCell className="c-text-center">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {banksList.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">
                        {item.formattedCode}
                      </TableCell>
                      <TableCell className="c-rows-elipsis">
                        {item.name}
                      </TableCell>
                      <TableCell className="c-mwp-10">
                        <IconButton
                          className="c-grid-btn-green"
                          onClick={() =>
                            history.push("/banco/editar/" + item.id)
                          }
                        >
                          <EditIcon fontSize="medium" />
                        </IconButton>
                        <IconButton
                          className="c-grid-btn-cancel"
                          onClick={() => {
                            setOpenDelete(true);
                            setDataDelete({
                              id: item.id,
                              title: "Eliminar Banco",
                              content: `¿Está seguro de eliminar el banco - ${item.name}?`,
                            });
                          }}
                        >
                          <DeleteForeverIcon fontSize="medium" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount}
            shape="rounded"
          />
          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            style={{ padding: "7px 24px 0px 5px" }}
          >
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
      <CDialogDelete
        open={openDelete}
        setOpen={setOpenDelete}
        title={dataDelete.title}
        content={dataDelete.content}
        actionConfirm={actionConfirmDeleteBank}
      />
    </>
  );
}
