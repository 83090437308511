import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Grid } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import PhoneIcon from "@material-ui/icons/Phone";
import { UrlEnum } from "../enums";
import NavBarLogin from "./NavbarNavigation";
import Sidebar from "./Sidebar";
import { useHistory } from "react-router-dom";

const NavigationBar = ({ isLogged }:any) => {
  const history = useHistory();
  // const [anchor, setAnchor] = useState(true);
  // const userData = getUserData();

  // function getUserData() {
  //   let tokenDecoded = localStorage.getItem("dataBytokenLog");
  //   tokenDecoded = tokenDecoded ? JSON.parse(tokenDecoded) : null;
  //   return {
  //     username: tokenDecoded?.displayName ?? "",
  //     role: tokenDecoded?.roles ?? "",
  //     roleName: undefined,
  //   };
  // }
  // const toggleDrawer = (open) => (event) => {
  //   if (
  //     event.type === "keydown" &&
  //     (event.key === "Tab" || event.key === "Shift")
  //   ) {
  //     return;
  //   }

  //   // setAnchor(open);
  // };
  return (
    <>
      <AppBar
        className={isLogged ? "c-appbar-loged" : "c-appbar"}
        position="fixed"
      >
        {isLogged ? (
          <NavBarLogin history={history}></NavBarLogin>
        ) : (
          <Toolbar className="c-m-n-12">
            <Grid item lg={12} md={12} sm={12} xs={10}>
              <IconButton
                href={UrlEnum.Facebook}
                target="_blank"
                edge="start"
                color="inherit"
                aria-label="open drawer"
              >
                <FacebookIcon className="c-icon-navbar" />
              </IconButton>
              <IconButton
                href={UrlEnum.LinkedIn}
                target="_blank"
                edge="start"
                color="inherit"
                aria-label="open drawer"
              >
                <LinkedInIcon className="c-icon-navbar" />
              </IconButton>
              <IconButton
                className="c-icon-instagram"
                href={UrlEnum.Instagram}
                target="_blank"
                edge="start"
                color="inherit"
                size="small"
                aria-label="open drawer"
              >
                <InstagramIcon className="c-icon-navbar" />
              </IconButton>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="c-text-center"
            >
              <IconButton edge="start" color="inherit" aria-label="open drawer">
                <PhoneIcon className="c-icon-navbar c-icon-green" />
              </IconButton>
              <a className="c-link-phone" href="tel:+54 3564 15506534">
                +54 3564 15506534
              </a>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              style={{ flexDirection: "row" }}
            >
              <div className="item-left">
                <span className="hover-text-navbar">FAQ</span>
              </div>
              <div className="c-text-center c-ml-5">{"|"}</div>
              <div className="item-rigth c-font-fidel-secondary">
                <span className="hover-text-navbar">VOLVER A INICIO</span>
              </div>
            </Grid>
          </Toolbar>
        )}
      </AppBar>
    </>
  );
};

export default NavigationBar;
