import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CTextField from "../../../../common/CTextField";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CustomTooltip from "../../../../common/CTooltip";

export default function OrdersSettingsForm(props) {
  const { values, handleChange } = props;
  return (
    <Grid>
      <Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.sendAutomaticCustomerOrderEmail}
                      onChange={handleChange}
                      name="sendAutomaticCustomerOrderEmail"
                    />
                  }
                  name="sendAutomaticCustomerOrderEmail"
                  label="Envio automatico a Cliente"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, cuando se genera un pedido se envia automaticamente un email al cliente
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.ecommerce ?? false}
                  onChange={handleChange}
                  name="ecommerce"
                />
              }
              name="ecommerce"
              label="Pedidos Web"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.sendAutomaticAccountOrderEmail ?? false}
                      onChange={handleChange}
                      name="sendAutomaticAccountOrderEmail"
                    />
                  }
                  name="sendAutomaticAccountOrderEmail"
                  label="Envio automatico a Cuenta"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, cuando se genera un pedido se envia automaticamente al email de la cuenta
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <CTextField
                label="Pedidos Web URL"
                type="text"
                fullWidth
                onChange={handleChange}
                name="ecommerceUrl"
                helperText=""
                value={values.ecommerceUrl ?? ""}
                disabled={undefined}
                readOnly={undefined}
                multiLine={undefined}
                rows={undefined}
                onClick={undefined}
                error={undefined}
                placeholder={undefined}
                maxLength={undefined}
                AutoComplete={""}
                InputaLabelProps={undefined}
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.allowUpdatePriceActiveStatusOrder ?? false}
                      onChange={handleChange}
                      name="allowUpdatePriceActiveStatusOrder"
                    />
                  }
                  name="allowUpdatePriceActiveStatusOrder"
                  label="Actualizar precios de Pedidos en Estado Activo"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Se podrán actualizar los montos de los Pedidos, al último precio de los productos que los componen
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.ivaWebOrders ?? false}
                    onChange={handleChange}
                    name="ivaWebOrders"
                  />
                }
                name="ivaWebOrders"
                label="IVA en Pedidos Web"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.allowUpdatePriceForOrderToBill ?? false}
                      onChange={handleChange}
                      name="allowUpdatePriceForOrderToBill"
                    />
                  }
                  name="allowUpdatePriceForOrderToBill"
                  label="Actualizar precios cuando se Factura o Presupuesta un pedido"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, cuando se genera una facturao un presupuesto se recalcula el precio del producto
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.profitsPercentageWebOrders ?? false}
                    onChange={handleChange}
                    name="profitsPercentageWebOrders"
                  />
                }
                name="profitsPercentageWebOrders"
                label="% de Ganancia en Pedidos Web"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.allowPrintPricesOrder ?? false}
                      onChange={handleChange}
                      name="allowPrintPricesOrder"
                    />
                  }
                  name="allowPrintPricesOrder"
                  label="Imprimir precios en reporte pedido"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      En el Reporte se describen los Precios y Bonificaciones de cada producto, caso contrario se
                      ocultan las columnas
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.hideBrandWebOrders ?? false}
                    onChange={handleChange}
                    name="hideBrandWebOrders"
                  />
                }
                name="hideBrandWebOrders"
                label="Ocultar Marca"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.transferOrderValueToStock ?? false}
                      onChange={handleChange}
                      disabled={!values.discountManuallyStock}
                      name="transferOrderValueToStock"
                    />
                  }
                  name="transferOrderValueToStock"
                  label="Trasladar Valor Pedido a Stock"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Con esta configuración traslada el valor pedido a Stock y convierte según el coeficiente el valor
                      de Cantidad
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.hideStockWebOrders ?? false}
                    onChange={handleChange}
                    name="hideStockWebOrders"
                  />
                }
                name="hideStockWebOrders"
                label="Ocultar Stock"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.displayQuantityInApp ?? false}
                      onChange={handleChange}
                      name="displayQuantityInApp"
                    />
                  }
                  name="displayQuantityInApp"
                  disabled={!values.discountManuallyStock}
                  label="Visualizar Cantidad en App Móvil"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">Si está activado,se muestra el campo cantidad y no Stock en la App</span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          



          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.searchPricesWithDiscountsWebOrders ?? false}
                    onChange={handleChange}
                    name="searchPricesWithDiscountsWebOrders"
                  />
                }
                name="searchPricesWithDiscountsWebOrders"
                label="Buscar Precios según Descuentos"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>

        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.showBalanceInOrder ?? false}
                      onChange={handleChange}
                      name="showBalanceInOrder"
                    />
                  }
                  name="showBalanceInOrder"
                  label="Mostrar Saldo en Reporte Pedido"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">Si está Activo, se muestra en el pie del Reporte el Saldo Total (Factura + Presupuesto) del Cliente</span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          
          
          <Grid item md={1} xs={1}></Grid>
          {values["ecommerce"] === true && (
            <Grid item md={5} xs={5} className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.searchPricesWithDiscountsWebOrders ?? false}
                    onChange={handleChange}
                    name="searchPricesWithDiscountsWebOrders"
                  />
                }
                name="searchPricesWithDiscountsWebOrders"
                label="Buscar Precios según Descuentos"
                labelPlacement="start"
              />
            </Grid>
          )}
        </Grid>

      </Grid>
    </Grid>
  );
}
