import { Grid, DialogContent, DialogActions, FormControlLabel, IconButton } from "@material-ui/core";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import { Button, Modal } from "semantic-ui-react";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import EditIcon from "@mui/icons-material/Edit";
import CDatePicker from "../../../../common/CDatePicker";
import { Dispatch, SetStateAction } from "react";
import CloseIcon from "@mui/icons-material/Close";

type IProps = {
  openDelete: boolean;
  setOpenDelete: Dispatch<SetStateAction<boolean>>;
  reasonsList?: { value: number; text: string }[];
  dataDelete: {
    id: number;
    title: string;
    content: string;
    motivoBajaId?: number;
    fechaBaja?: Date;
  };
  setDataDelete: Dispatch<
    SetStateAction<{
      id: number;
      title: string;
      content: string;
      motivoBajaId?: number | undefined;
      fechaBaja?: Date | undefined;
    }>
  >;
  actionConfirmUnsubscribeAccount: (values: {
    id: number;
    motivoBajaId?: number | undefined;
    fechaBaja?: Date | undefined;
  }) => void;
  editReason?: boolean;
};

function UnsubscribeAccountModal(props: IProps) {
  let {
    openDelete,
    setOpenDelete,
    dataDelete,
    reasonsList,
    setDataDelete,
    actionConfirmUnsubscribeAccount,
    editReason = false,
  } = props;
  return (
    <Modal
      className="delete-modal"
      onClose={() => {
        setOpenDelete(false);
      }}
      open={openDelete}
    >
      <Modal.Header>
        <Grid container justifyContent="space-between">
          {editReason ? (
            <Grid>
              <EditIcon className="c-text-gray c-mr-10" fontSize="small" />
              Editar motivo de baja
            </Grid>
          ) : (
            <Grid>
              <ThumbDownIcon className="c-text-cancel c-mr-10" fontSize="small" />
              Dar de Baja
            </Grid>
          )}
          <IconButton
            className="c-grid-btn-cancel"
            onClick={() => {
              setOpenDelete(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        {editReason ? (
          <Grid>Editar el motivo de baja a {dataDelete?.content ?? "-"}?</Grid>
        ) : (
          <Grid>Desea dar de baja a {dataDelete?.content ?? "-"}?</Grid>
        )}
        <Grid container spacing={1} justifyContent="center" className="c-mt-20">
          <Grid item xs={12}>
            <CCustomSelectOrSearch
              disabled={false}
              name="motivoBajaId"
              placeHolder="Motivo de Baja"
              value={reasonsList?.find((x: { text: string; value: number }) => {
                return x.value === dataDelete.motivoBajaId;
              })}
              onChange={(e) => {
                setDataDelete({ ...dataDelete, motivoBajaId: e ? e.value : undefined });
              }}
              dataList={reasonsList}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <CDatePicker
                  value={dataDelete.fechaBaja}
                  setValue={(date) => setDataDelete({ ...dataDelete, fechaBaja: date })}
                  label="Fecha"
                  type="dayWithMonth"
                />
              }
              name=""
              label=""
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            actionConfirmUnsubscribeAccount({
              id: dataDelete.id,
              motivoBajaId: dataDelete.motivoBajaId,
              fechaBaja: dataDelete.fechaBaja,
            });
          }}
          color="green"
          autoFocus
        >
          Confirmar
        </Button>
        <Button
          onClick={() => {
            setOpenDelete(false);
          }}
          color="red"
        >
          Cancelar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default UnsubscribeAccountModal;
