import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import CTextField from "../../../../common/CTextField";
import { BillTypeEnum } from "../../../../enums";
import * as citiesService from "../../../../api/services/Cities";
import CustomTooltip from "../../../../common/CTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { toggleMessage } from "../../../../utils/events";
import { City } from "../../../../api/services/Cities/city.types";

type IList = {
  text: string;
  value: number;
};

export default function AccountSettingsForm(props) {
  const { values, handleChange, setFieldValue, existIIBB } = props;

  async function getCities(search: string) {
    let list: IList[] = [];
    if (search && search.length >= 3) {
      let { result, data, message } = await citiesService.getCities({
        search: search,
      });

      if (result && data) {
        data.list.map((item: City) => {
          list.push({ text: item.name, value: item.id });
        });
      } else {
        toggleMessage("error", message);
      }
    }
    return list;
  }

  const loadCities = (
    inputValue: string,
    callback: (options: IList[]) => void
  ) => {
    setTimeout(async () => {
      callback(await getCities(inputValue));
    }, 1000);
  };

  return (
    <Grid>
      <Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.system ?? false}
                  onChange={handleChange}
                  name="system"
                />
              }
              name="system"
              label="Sistema "
              labelPlacement="start"
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20 c-mr-20">
          {!values.wooCommerce && (
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.tiendaNube ?? false}
                  onChange={handleChange}
                  name="tiendaNube"
                />
              }
              name="tiendaNube"
              label="Tienda Nube "
              labelPlacement="start"
            />
          )}
          {!values.tiendaNube && (
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.wooCommerce}
                  onChange={handleChange}
                  name="wooCommerce"
                />
              }
              name="wooCommerce"
              label="WooCommerce "
              labelPlacement="start"
            />
          )}
          </Grid>
        </Grid>
        {values["tiendaNube"] === true && (
          <Grid container>
            <Grid item md={5} xs={5} className="c-mt-20"></Grid>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={5} xs={5} className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.sendUnpaidTNOrders ?? false}
                        onChange={handleChange}
                        name="sendUnpaidTNOrders"
                      />
                    }
                    name="sendUnpaidTNOrders"
                    label="Enviar Pedidos No Pagados "
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1}></Grid>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.shippingCostTN ?? false}
                        onChange={handleChange}
                        name="shippingCostTN"
                      />
                    }
                    name="shippingCostTN"
                    label="Enviar Costo de Envío "
                    labelPlacement="start"
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}
                  className="c-grid-tooltip-info c-ml-10 c-mt-10"
                >
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Cuando está desactivado, no se envía el costo de envío
                        de Transporte como ítem libre a Fidel
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CCustomSelectOrSearch
              disabled={false}
              name="billTypeId"
              placeHolder="Tipo de factura"
              value={BillTypeEnum.find((x: { text: string; value: number }) => {
                return x.value === values.billTypeId;
              })}
              onChange={(e) => {
                setFieldValue("billTypeId", e ? e.value : undefined);
              }}
              dataList={BillTypeEnum}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.productImagesFTP ?? false}
                      onChange={handleChange}
                      name="productImagesFTP"
                    />
                  }
                  name="productImagesFTP"
                  label="Imagenes de Productos FTP "
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, las imágenes de los productos se buscan en
                      la carpeta FTP
                      ('https://www.fidel.com.ar\Images\FTP\Productos\xx' donde
                      xx es el ID de la Cuenta), por Código (sin barras ni
                      espacios). Ej: para el Código '003/123 ABC' el nombre de
                      la imagen debe ser '003123ABC.png' (la extensión debe ser
                      siempre PNG)'
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {values.billTypeId === 2 && (
          <Grid>
            <Grid container>
              <Grid item md={5} xs={5} className="c-mt-20"></Grid>
              <Grid item md={1} xs={1}></Grid>
              <Grid item md={5} xs={5} className="c-mt-20">
                <CTextField
                  label="% Seguro Flete"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  name="freightInsurance"
                  helperText=""
                  value={values.freightInsurance ?? ""}
                  disabled={undefined}
                  readOnly={undefined}
                  multiLine={undefined}
                  rows={undefined}
                  onClick={undefined}
                  error={undefined}
                  placeholder={undefined}
                  maxLength={undefined}
                  AutoComplete={""}
                  InputaLabelProps={undefined}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={5} xs={5} className="c-mt-20"></Grid>
              <Grid item md={1} xs={1}></Grid>
              <Grid item md={5} xs={5} className="c-mt-20">
                <CTextField
                  label="% Seguro Valor Declarado"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  name="declaredValueInsurance"
                  helperText=""
                  value={values.declaredValueInsurance ?? ""}
                  disabled={undefined}
                  readOnly={undefined}
                  multiLine={undefined}
                  rows={undefined}
                  onClick={undefined}
                  error={undefined}
                  placeholder={undefined}
                  maxLength={undefined}
                  AutoComplete={""}
                  InputaLabelProps={undefined}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.useServiceOrder ?? false}
                      onChange={handleChange}
                      name="useServiceOrder"
                    />
                  }
                  name="useServiceOrder"
                  label="Utilizar Modelos, Vehículos, Pre-Órdenes y Órdenes "
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo se podran vizualizar los menús (Modelos,
                      vehículos, pre-órdenes y órdenes)
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.remitReportPrintData ?? false}
                      onChange={handleChange}
                      name="remitReportPrintData"
                    />
                  }
                  name="remitReportPrintData"
                  label="Datos del Reporte Remito de Factura "
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo los datos los coloca el Reporte, de lo
                      contrario, deberán estar en el Preimpreso
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.seeBonus ?? false}
                      onChange={handleChange}
                      name="seeBonus"
                    />
                  }
                  name="seeBonus"
                  label="Ver Recargo y Subtotal con IVA "
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está desactivado, en las Facturas y Presupuestos de
                      Ventas cuando es Recargo se ven en 0 y se recalculan los
                      precios unitarios, además no se ven las columnas de
                      Alícuota de IVA y Subtotal con IVA
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.printDataInReportRemitBudget ?? false}
                      onChange={handleChange}
                      name="printDataInReportRemitBudget"
                    />
                  }
                  name="printDataInReportRemitBudget"
                  label="Datos del Reporte Remito de Presupuesto "
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si esta Activo los datos los coloca el Reporte
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="Fecha de Vencimiento Clientes"
              type="text"
              fullWidth
              onChange={handleChange}
              name="expirationCustomerDefault"
              helperText=""
              value={values.expirationCustomerDefault ?? ""}
              disabled={undefined}
              readOnly={undefined}
              multiLine={undefined}
              rows={undefined}
              onClick={undefined}
              error={undefined}
              placeholder={undefined}
              maxLength={undefined}
              AutoComplete={""}
              InputaLabelProps={undefined}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.retentionAgent ?? false}
                      onChange={handleChange}
                      name="retentionAgent"
                    />
                  }
                  name="retentionAgent"
                  label="Agente de Retención"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si esta Activo se aplicaran las Retenciones de Ganancia
                      automaticamente
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="Fecha de Vencimiento Proveedores"
              type="text"
              fullWidth
              onChange={handleChange}
              name="expirationProviderDefault"
              helperText=""
              value={values.expirationProviderDefault ?? ""}
              disabled={undefined}
              readOnly={undefined}
              multiLine={undefined}
              rows={undefined}
              onClick={undefined}
              error={undefined}
              placeholder={undefined}
              maxLength={undefined}
              AutoComplete={""}
              InputaLabelProps={undefined}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.iibbCalculationCustomerAliquot ?? false}
                      onChange={handleChange}
                      name="iibbCalculationCustomerAliquot"
                    />
                  }
                  name="iibbCalculationCustomerAliquot"
                  label="Agente de Percepción IIBB de Entre Rios"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo se aplicaran las Percepciones IIBB en Venta
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CCustomSelectOrSearch
              isSearch
              disabled={false}
              defaultValue={
                values.customerDefaultCity !== null
                  ? values.customerDefaultCity
                  : null
              }
              name="customerDefaultCity"
              placeHolder="Localidad por Defecto en Nuevo Cliente"
              onChange={(e) => {
                setFieldValue("customerDefaultCity", e ? e.value : null);
              }}
              onLoad={loadCities}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                {existIIBB === true ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        disabled={true}
                        color="default"
                        checked={values.perceptionAgentIIBB ?? false}
                        onChange={handleChange}
                        name="perceptionAgentIIBB"
                      />
                    }
                    name="perceptionAgentIIBB"
                    label="Agente de Percepción IIBB de Santa Fe"
                    labelPlacement="start"
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.perceptionAgentIIBB ?? false}
                        onChange={handleChange}
                        name="perceptionAgentIIBB"
                      />
                    }
                    name="perceptionAgentIIBB"
                    label="Agente de Percepción IIBB de Santa Fe"
                    labelPlacement="start"
                  />
                )}
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo se aplicaran las Percepciones IIBB en Venta
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid item md={10} xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.portalClient ?? false}
                    onChange={handleChange}
                    name="portalClient"
                  />
                }
                name="portalClient"
                label="Portal de clientes"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.ivaPerception ?? false}
                      onChange={handleChange}
                      name="ivaPerception"
                    />
                  }
                  name="ivaPerception"
                  label="Percepción de IVA RG 5329"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Se aplicarán Percepciones en las Facturas de Ventas al 3%
                      ó 1,5%. Según la condición del Cliente y del Producto
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.fiscalBonus ?? false}
                      onChange={handleChange}
                      name="fiscalBonus"
                    />
                  }
                  name="fiscalBonus"
                  label="Bono Fiscal"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, ésta cuenta puede hacer Bonos Fiscales
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {values["fiscalBonus"] === true && (
          <Grid container>
            <Grid item md={5} xs={5} className="c-mt-20"></Grid>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={5} xs={5} className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <CTextField
                    label="Puntos de Venta"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    name="salePointFiscalBonus"
                    helperText=""
                    value={
                      values.salePointFiscalBonus
                        ? values.salePointFiscalBonus
                        : ""
                    }
                    disabled={undefined}
                    readOnly={undefined}
                    multiLine={undefined}
                    rows={undefined}
                    onClick={undefined}
                    error={undefined}
                    placeholder={undefined}
                    maxLength={undefined}
                    AutoComplete={""}
                    InputaLabelProps={undefined}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}
                  className="c-grid-tooltip-info c-ml-10 c-mt-10"
                >
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Si son mas de un punto de venta separarlos con coma
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20"></Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.exportBill ?? false}
                      onChange={handleChange}
                      name="exportBill"
                    />
                  }
                  name="exportBill"
                  label="Exportación"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, ésta cuenta puede hacer Facturas de
                      Exportación
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {values.exportBill === true && (
          <Grid container>
            <Grid item md={5} xs={5} className="c-mt-20"></Grid>
            <Grid item md={1} xs={1}></Grid>
            <Grid item md={5} xs={5} className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <CTextField
                    label="Puntos de venta de Exportación"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    name="salePointExporter"
                    helperText=""
                    value={
                      values.salePointExporter ? values.salePointExporter : ""
                    }
                    disabled={undefined}
                    readOnly={undefined}
                    multiLine={undefined}
                    rows={undefined}
                    onClick={undefined}
                    error={undefined}
                    placeholder={undefined}
                    maxLength={undefined}
                    AutoComplete={""}
                    InputaLabelProps={undefined}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}
                  className="c-grid-tooltip-info c-ml-10 c-mt-10"
                >
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Si son mas de un punto de venta separarlos con coma
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20"></Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.voucherCombine ?? false}
                      onChange={handleChange}
                      name="voucherCombine"
                    />
                  }
                  name="voucherCombine"
                  label="Combinar Cheques"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      La lista de cheques muestra tanto los de presupuesto como
                      los de factura ignorando el filtro
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20"></Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.multiplier ?? false}
                      onChange={handleChange}
                      name="multiplier"
                    />
                  }
                  name="multiplier"
                  label="Multiplicador?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Multiplica el precio de los productos
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20"></Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.quantityPerPackage ?? false}
                      onChange={handleChange}
                      name="quantityPerPackage"
                    />
                  }
                  name="quantityPerPackage"
                  label="Cant por Bulto"
                  labelPlacement="start"
                />
              </Grid>
              <Grid
                item
                md={1}
                xs={1}
                className="c-grid-tooltip-info c-ml-10 c-mt-10"
              >
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Agrega la cantidad por bulto a producto
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20"></Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.dashboard ?? false}
                  onChange={handleChange}
                  name="dashboard"
                />
              }
              name="dashboard"
              label="Dashboard"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
