import { DefaultResponse } from "./SSOClient";
import { API, errorHandling, generateQueryParams } from "./index";

const BASE_URL: string = "api/reasonForUnsubscribe";
export async function getReasonsForUnsubscribe(
  search: string,
  orderBy: null | string,
  page: number,
  pageSize: number
) {
  return API.get(BASE_URL + generateQueryParams({
    search,
    orderBy,
    page,
    pageSize,
  }))
    .then((res) => {
      const data = res.data;
      if (data) {
        return {
          result: true,
          data: data,
        };
      } else {
        return {
          result: false,
          message: data["message"] ?? "Ocurrió un error.",
        };
      }
    })
    .catch((err: Error) => {
      return errorHandling(err);
    });
}

export async function saveReasonForUnsubscribe(name) {
  return API.post(BASE_URL, name).then((res) => {
    const data = res.data;
    if (data) {
      return {
        result: true,
        data: data,
      };
    } else {
      return {
        result: false,
        message: data["message"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getReasonsForUnsubscribeById(id: number) {
  return API.get(BASE_URL + `/${id}`).then((res) => {
    const data = res.data;
    if (data) {
      return {
        result: true,
        data: data,
      };
    } else {
      return {
        result: false,
        message: data["message"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function editReasonsForUnsubscribe(
  reasonForUnsubscribeData: any,
  reasonForUnsubscribeId: number
) {
  return API.put(
    BASE_URL + "/" + reasonForUnsubscribeId,
    reasonForUnsubscribeData
  ).then((res) => {
    const data = {
      response: res.data,
      result: res ? true : false,
    };
    return data;
  });
}

export async function deleteReasonsForUnsubscribe(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    const data = {
      response: res.data,
      result: res ? true : false,
    };
    return data;
  });
}

export async function getReasonsForUnsubscribeSelect(): Promise<DefaultResponse<{ value: number, text: string }[]>> {
  return API.get(BASE_URL + "/select-list").then(({ data }) => {
    const list = data.map((item) => {
      return { value: item.id, text: item.text }
    })
    return { result: true, data: list };
  }).catch(errorHandling);
}