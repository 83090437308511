import { Checkbox, Grid, IconButton, Paper } from "@material-ui/core";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ClearIcon from "@mui/icons-material/Clear";
import * as voucherTypeService from "../../../../api/services/voucher";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import CTextField from "../../../../common/CTextField";
import { FieldArray } from "formik";
import AddIcon from "@mui/icons-material/Add";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { PayCondition } from "../../../../enums";
import { toggleMessage } from "../../../../utils/events";
import { PutConfigAccount } from "../../../../api/services/Account/account.types";

type Voucher = {
  dateDeleteOn: any;
  electronicCredit: boolean;
  id: number;
  letter: string;
  name: string;
};

type IProps = {
  values: PutConfigAccount;
  handleChange: {
    (e: ChangeEvent<PutConfigAccount>): void;
    <T = string | ChangeEvent<PutConfigAccount>>(field: T): T extends ChangeEvent<PutConfigAccount>
      ? void
      : (e: string | ChangeEvent<PutConfigAccount>) => void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setObservationBtn(i: any): void;
};

export default function CreateRow(props: IProps) {
  const { values, handleChange, setFieldValue, setObservationBtn } = props;
  const [voucherType, setVoucherType] = useState<{ text: string; value: number }[]>([]);
  const [days, setDays] = useState<number>(0);

  useEffect(() => {
    getVoucherTypes("", 1, 50, null);
  }, []);

  async function getVoucherTypes(search, page, pageSize, orderBy) {
    let { result, data, message }: { result: boolean; data?: Voucher[]; message?: string } =
      await voucherTypeService.getVoucherTypes(search, page, pageSize, orderBy);

    if (result && data) {
      data.map((e: any) => {
        setVoucherType((prevVouchers) => [...prevVouchers, { text: e.name + " " + e.letter, value: e.id }]);
      });
    }
  }

  const available = (type: "add" | "change" | "addContado") => {
    let response = true;

    if (values.salesConfiguration && values.salesConfiguration.length !== 0) {
      if (type === "add") {
        if (values.salesConfiguration[0].byDays === false) {
          response = false;
        }
      } else if (type === "change") {
        if (values.salesConfiguration.length > 1) {
          response = false;
        }
      } else if ("addContado") {
        values.salesConfiguration.map((item) => {
          if (item.payConditionId === 2) {
            response = false;
          }
        });
      }
    }
    return response;
  };

  return (
    <Grid item md={12} xs={12}>
      <FieldArray
        name="salesConfiguration"
        render={(arrayHelpers) => (
          <>
            <Grid container>
              <Grid item md={10} xs={10} className="c-mt-20"></Grid>
              <Grid item md={2} xs={2} className="c-mt-20">
                <Grid>
                  <IconButton
                    size="small"
                    style={{ color: "#0063cc" }}
                    onClick={() => {
                      if (available("add")) {
                        let length = days + 1;
                        setDays(length);
                        arrayHelpers.push({
                          payConditionName: PayCondition.CuentaCorriente.text,
                          payCondition: PayCondition.CuentaCorriente.value,
                          byDays: true,
                          observation: undefined,
                          priceVariationPercentage: 0,
                          dayFrom: length * 2 - 1,
                          dayTo: length * 2,
                        });
                      } else {
                        toggleMessage(
                          "error",
                          "No puede agregar más de una configuración de Cuenta Corriente cuando la misma no es por días"
                        );
                      }
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <CreditCardIcon fontSize="large" />
                  </IconButton>
                  <IconButton
                    className="c-ml-5"
                    size="small"
                    style={{ color: "#6ea877" }}
                    onClick={() => {
                      if (available("addContado")) {
                        arrayHelpers.push({
                          payConditionName: PayCondition.Contado.text,
                          payCondition: PayCondition.Contado.value,
                          byDays: true,
                          observation: undefined,
                          priceVariationPercentage: 0,
                          dayFrom: 0,
                          dayTo: 0,
                          voucherTypes: undefined,
                        });
                      } else {
                        toggleMessage("error", "No puede agregar más de una Configuración de Contado.");
                      }
                    }}
                  >
                    <AddIcon fontSize="large" />
                    <LocalAtmIcon fontSize="large" />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Paper elevation={3} className="c-grid-results">
                <Grid container className="c-pt-5 c-pb-5" style={{ backgroundColor: "lightgrey", fontWeight: "bold" }}>
                  <Grid item className="c-text-center" md={2} xs={2}>
                    Condicion de venta
                  </Grid>
                  <Grid item className="c-text-center" md={1} xs={1}>
                    Por día
                  </Grid>
                  <Grid item className="c-text-center" md={2} xs={2}>
                    Tipos de Comprobante
                  </Grid>
                  <Grid item className="c-text-center" md={2} xs={2}>
                    Día desde
                  </Grid>
                  <Grid item className="c-text-center" md={2} xs={2}>
                    Día hasta
                  </Grid>
                  <Grid item className="c-text-center" md={2} xs={2}>
                    Porcentaje
                  </Grid>
                  <Grid item className="c-text-center" md={1} xs={1}>
                    Opciones
                  </Grid>
                </Grid>
                {values.salesConfiguration && values.salesConfiguration.length >= 0 ? (
                  values.salesConfiguration.map((item: any, index: number) => (
                    <Grid container className="c-mt-5" key={index}>
                      <Grid item className="c-text-center c-mt-10" md={2} xs={2}>
                        {item.payConditionName}
                      </Grid>
                      <Grid item className="c-text-center" md={1} xs={1}>
                        {item.payCondition === PayCondition.Contado.value && (
                          <ClearIcon className="c-mt-10 c-icon-cancel" />
                        )}
                        {item.payCondition === PayCondition.CuentaCorriente.value && (
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.salesConfiguration[index].byDays}
                            onChange={(e) => {
                              if (available("change")) {
                                handleChange(e);
                              } else {
                                toggleMessage(
                                  "error",
                                  "No puede agregar más de una configuración de Cuenta Corriente cuando la misma no es por días ni dos tipos de Configuraciones de Cuenta Corriente Combinadas."
                                );
                              }
                            }}
                            name={`salesConfiguration[${index}].byDays`}
                            value={values.salesConfiguration[index].byDays}
                          />
                        )}
                      </Grid>
                      <Grid item className="c-text-center" md={2} xs={2}>
                        {item.payCondition === PayCondition.CuentaCorriente.value && (
                          <CCustomSelectOrSearch
                            disabled={false}
                            name={`salesConfiguration[${index}].voucherTypes`}
                            placeHolder="Seleccione..."
                            onChange={(e: { key: any }) => {
                              setFieldValue("voucherTypes", e ? e.key : undefined);
                            }}
                            dataList={voucherType}
                            isMulti
                          />
                        )}
                      </Grid>
                      <Grid item className="c-text-center" md={4} xs={4}>
                        {item.payCondition === PayCondition.CuentaCorriente.value && (
                          <Grid className="c-text-center" container>
                            <Grid item className="c-text-center" md={6} xs={6}>
                              <CTextField
                                label=""
                                type="number"
                                fullWidth
                                onChange={handleChange}
                                name={`salesConfiguration[${index}].dayFrom`}
                                helperText=""
                                value={values.salesConfiguration[index].dayFrom}
                                disabled={values.salesConfiguration[index].byDays}
                                readOnly={undefined}
                                multiLine={undefined}
                                rows={undefined}
                                onClick={undefined}
                                error={undefined}
                                placeholder={undefined}
                                maxLength={undefined}
                                AutoComplete={""}
                                InputaLabelProps={undefined}
                              />
                            </Grid>
                            <Grid item className="c-text-center" md={6} xs={6}>
                              <CTextField
                                label=""
                                type="number"
                                fullWidth
                                onChange={handleChange}
                                name={`salesConfiguration[${index}].dayTo`}
                                helperText=""
                                value={values.salesConfiguration[index].dayTo}
                                disabled={values.salesConfiguration[index].byDays}
                                readOnly={undefined}
                                multiLine={undefined}
                                rows={undefined}
                                onClick={undefined}
                                error={undefined}
                                placeholder={undefined}
                                maxLength={undefined}
                                AutoComplete={""}
                                InputaLabelProps={undefined}
                              />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item className="c-text-center" md={2} xs={2}>
                        <CTextField
                          label=""
                          type="number"
                          fullWidth
                          onChange={handleChange}
                          name={`salesConfiguration[${index}].priceVariationPercentage`}
                          helperText=""
                          value={item.priceVariationPercentage ?? 0}
                          disabled={false}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                      <Grid item className="c-text-center c-mt-5" md={1} xs={1}>
                        <IconButton
                          className="c-grid-btn-cancel"
                          size="small"
                          onClick={() => {
                            if (item.payConditionName === PayCondition.CuentaCorriente.text) {
                              setDays(days - 1);
                            }
                            arrayHelpers.remove(index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton className="c-grid-btn-info" size="small" onClick={() => setObservationBtn(index)}>
                          {!item.observation && (
                            <ZoomInIcon
                              onClick={() => {
                                item.observation = " ";
                              }}
                            />
                          )}
                          {item.observation && (
                            <ZoomOutIcon
                              onClick={() => {
                                item.observation = undefined;
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                      {item.observation && (
                        <Grid className="c-mt-5" item xs={12} md={12}>
                          <CTextField
                            label="Observación"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                            name={`salesConfiguration[${index}].observation`}
                            helperText=""
                            value={values.salesConfiguration[index].observation ?? ""}
                            disabled={undefined}
                            readOnly={undefined}
                            multiLine={undefined}
                            rows={undefined}
                            onClick={undefined}
                            error={undefined}
                            placeholder={undefined}
                            maxLength={undefined}
                            AutoComplete={""}
                            InputaLabelProps={undefined}
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Paper>
            </Grid>
          </>
        )}
      />
    </Grid>
  );
}
