import React from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import { FormControl } from "@material-ui/core";
import functionInterface from "./CCSSInterfaces/interface";

const NoOptionsMessage = (props: any) => {
  return (
    <components.NoOptionsMessage {...props}>
      Al menos 3 caracteres para comenzar la búsqueda
    </components.NoOptionsMessage>
  );
};

const LoadingMessage = (props: any) => {
  return <components.LoadingMessage {...props}>Cargando...</components.LoadingMessage>;
};

const selectStyles = {
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
};

export default function (props: functionInterface) {
  const {
    LoadOptions,
    OnChange,
    PlaceHolder,
    Name,
    Disabled,
    Value,
    Clearable,
    ClassName,
    isMulti,
    defaultOpctions,
    defaultValue,
  } = props;

  return (
    <FormControl variant="outlined" fullWidth={true} size="small">
      <AsyncSelect
        className={ClassName ?? ""}
        styles={selectStyles}
        placeholder={PlaceHolder}
        name={Name}
        value={Value}
        getOptionLabel={(e: any) => e.text}
        getOptionValue={(e: any) => e.value}
        cacheOptions
        loadOptions={LoadOptions}
        onChange={OnChange}
        isDisabled={Disabled ?? false}
        isClearable={Clearable ?? true}
        components={{ NoOptionsMessage, LoadingMessage }}
        isMulti={isMulti}
        defaultOptions={defaultOpctions}
        defaultValue={defaultValue}
      />
    </FormControl>
  );
}
