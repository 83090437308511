import { Formik } from "formik";
import { Button, Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import * as ivaConditionsService from "../../../../api/services/ivaCondition";
import * as vouchersService from "../../../../api/services/voucher";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../../common/CTextField";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as Yup from "yup";
import { IvaCondition } from "../../../../api/services/ivaCondition/ivaCondition.type";

type Voucher = {
  dateDeleteOn: any;
  electronicCredit: boolean;
  id: number;
  letter: string;
  name: string;
};

export default function IvaConditionsEdit() {
  const { id }: any = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<IvaCondition>();
  const [Vouchers, setVouchers] = useState<{ text: string; value: number }[]>([]);
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
    active: Yup.boolean().required("Campo Requerido"),
    vouchersPurchase: Yup.number().required("Campo Requerido"),
    vouchersSale: Yup.number().required("Campo Requerido"),
  });

  useEffect(() => {
    getIvaCondition(id);

    getVouchers();
  }, []);

  async function getVouchers() {
    setIsLoading(true);
    try {
      let { result, data, message }: { result: boolean; data?: Voucher[]; message?: string } =
        await vouchersService.getVoucherTypes();
      if (result && data) {
        let list = data.map((e) => {
          return {
            text: e.name + " " + e.letter,
            value: e.id,
          };
        });
        setVouchers(list);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getIvaCondition(id) {
    setIsLoading(true);
    try {
      const { result, data, message } = await ivaConditionsService.getIvaConditionById(id);
      if (result && data) {
        setInitialValues(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function editIvaConditions(filter) {
    setIsLoading(true);
    let body = {
      name: filter.name,
      active: filter.active,
      voucherPurchaseType: filter.vouchersPurchase,
      voucherSaleType: filter.vouchersSale,
    };
    ivaConditionsService
      .editIvaCondition(id, body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Condicion IVA editada exitosamente");
          history.push("/condicionIva/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} validationSchema={filterSchema} onSubmit={editIvaConditions}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CTextField
                        label="Nombre"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="name"
                        helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                        value={values.name ?? ""}
                        disabled={undefined}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CCustomSelectOrSearch
                        disabled={false}
                        name="vouchersPurchase"
                        placeHolder="Comprobantes Compra"
                        onChange={(e: { text: string; value: number }[]) => {
                          let list: number[] = e.map((item) => {
                            return item.value;
                          });
                          setFieldValue("vouchersPurchase", list ?? undefined);
                        }}
                        value={Vouchers.filter((i) => {
                          return values.vouchersPurchase.find((item) => i.value === item);
                        })}
                        dataList={Vouchers}
                        isMulti
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CCustomSelectOrSearch
                        disabled={false}
                        name="vouchersSale"
                        placeHolder="Comprobantes Venta"
                        onChange={(e: { text: string; value: number }[]) => {
                          let list: number[] = e.map((item) => {
                            return item.value;
                          });
                          setFieldValue("vouchersSale", list ?? undefined);
                        }}
                        value={Vouchers.filter((i) => {
                          return values.vouchersSale.find((item) => i.value === item);
                        })}
                        dataList={Vouchers}
                        isMulti
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.active ?? false}
                            onChange={handleChange}
                            name="active"
                          />
                        }
                        name="active"
                        label="Activo"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
