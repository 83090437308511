import axios from "axios";

export const SSOAPI = axios.create({
  baseURL: `${process.env.REACT_APP_SSO_API_URL}`,
  headers: {
    accept: "*/*",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_FIDEL_ADMIN_FRONT,
    "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type",
  },
});

/**
 * Handler para las llamadas a la API en caso de error
 * @param {*} err Error del catch()
 */
export const errorHandling = (err: any) => {
  let response = "";

  if (err.response) {
    if (err.response.data && err.response.data.errores) {
      response = err.response.data.errores;
    } else response = "Ocurrió un error.";
  } else if (err.request) {
    response = "Ocurrió un error, no se pudo realizar la acción.";
  } else {
    response = "Ocurrió un error, contáctese con el Administrador.";
  }

  return { result: false, message: response };
};

export type DefaultResponse<T = any> = {
  result: boolean;
  data?: T;
  message?: string | string[];
};