import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as reasonsForUnsubscribeService from "../../../api/services/reasonsForUnsubscribe";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import ReasonsForUnsubscribeList from "./reasonsForUnsubscribeList";
import SearchIcon from "@material-ui/icons/Search";

export default function ReasonsForUnsubscribe(history: any) {
  const [valueSearch, setValueSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataTable, setDataTable] = useState([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    reasonsForUnsubscribe("", null, 1, rowsPerPage);
  }, []);

  const reloadData = (page: number, pageSize: number) => {
    reasonsForUnsubscribe(valueSearch ?? "", null, page, pageSize);
  };

  async function reasonsForUnsubscribe(search = "", orderBy = null, page: number, pageSize: number) {
    setIsLoading(true);
    await reasonsForUnsubscribeService.getReasonsForUnsubscribe(search, orderBy, page, pageSize).then((res) => {
      if (res["result"]) {
        let currentPage = page;
        let count = res["data"]["length"];
        let amount = res["data"]["pagesCount"];

        setDataTable(res["data"]);

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
        setIsLoading(false);
      } else {
        toggleMessage("error", res["message"]);
        setIsLoading(false);
      }
    });
  }

  const changePage = (value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const onClickSearch = () => {
    reasonsForUnsubscribe(valueSearch, null, 1, rowsPerPage);
  };

  const deleteReasonsForUnsubscribeById = (id: number) => {
    reasonsForUnsubscribeService.deleteReasonsForUnsubscribe(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Motivo de baja eliminado exitosamente");
        reloadData(paginationData.currentPage ?? 1, rowsPerPage);
      } else {
        toggleMessage("error", res["message"] ?? "Ocurrió un error al eliminar motivo de baja seleccionado");
      }
    });
  };

  return (
    <div className="c-mt-60 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/motivosDeBaja/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Nuevo
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label=""
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-btn-info c-mt-10 c-ml-10 c-cursor-pointer"
          startIcon={<SearchIcon />}
          onClick={() => onClickSearch()}
        >
          Buscar
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <ReasonsForUnsubscribeList
          dataTable={dataTable}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          deleteReasonsForUnsubscribeById={deleteReasonsForUnsubscribeById}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
