import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { RoleEnum } from "../../../../enums";
import shortenText from "../../../../common/CShortText";
import CDialogDelete from "../../../../common/CDialogDelete/index";
import { useHistory } from "react-router";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import UnsubscribeAccountModal from "../modals/unsubscribeAccount";
import * as reasonsForUnsubscribeService from "../../../../api/services/reasonsForUnsubscribe";
import { toggleMessage } from "../../../../utils/events";
import { DeletedAccount } from "../../../../api/services/Account/account.types";

type IProps = {
  dataTable: DeletedAccount[];
  paginationData: {
    amount: number;
    currentPage: number;
    totalRecords: number;
  };
  changePage: (event: any, value: number) => void;
  handleChangeRowsPerPage: (event: {
    target: {
      value: string | number;
    };
  }) => void;
  subscribeAccount(id: number): void;
  unsubscribeAccountById: (
    id: number,
    values: {
      motivoBajaId?: number;
      fechaBaja?: string;
    }
  ) => void;
};

export default function UnsubscribedAccountList(props: IProps) {
  const history = useHistory();
  const { dataTable, paginationData, changePage, handleChangeRowsPerPage, subscribeAccount, unsubscribeAccountById } =
    props;
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [dataSubscribe, setDataSubscribe] = useState<{ accountId: number; title: string; content: string }>();
  const [openDelete, setOpenDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState<{
    id: number;
    title: string;
    content: string;
    motivoBajaId?: number;
    fechaBaja?: Date;
  }>({
    id: 0,
    title: "",
    content: "",
    motivoBajaId: 1,
    fechaBaja: new Date(),
  });
  const [reasonsList, setReasonsList] = useState<{ value: number; text: string }[]>();
  useEffect(() => {
    if (openDelete) {
      reasonsForUnsubscribeService.getReasonsForUnsubscribeSelect().then(({ result, data, message }) => {
        if (result && data) {
          setReasonsList(data);
        } else {
          toggleMessage("error", message);
        }
      });
    }
  }, [openDelete]);

  const getLastConnectionByUsers = (users: any) => {
    let response: any = "-";
    if (users && users.length !== 0) {
      let lastUser = users[users.length - 1];

      if (lastUser?.lastConnection) {
        response = moment(response.lastConnection).format("DD/MM/yyyy hh:mm");
      }
    }
    return response;
  };

  const actionConfirmSubscribeAccount = () => {
    subscribeAccount(dataSubscribe?.accountId ?? 0);
    setOpenSubscribe(false);
  };

  const actionConfirmUnsubscribeAccount = (values: {
    id: number;
    motivoBajaId?: number | undefined;
    fechaBaja?: Date | undefined;
  }) => {
    unsubscribeAccountById(values.id, {
      motivoBajaId: values.motivoBajaId,
      fechaBaja: moment(values.fechaBaja).format("yyyy-MM-DD HH:mm:ss"),
    });
    setOpenDelete(false);
  };

  return (
    <>
      <Paper className="c-mt-20 c-ml-90 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center" className="c-mt-10">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Nombre</TableCell>
                    <TableCell className="c-text-center">Empresa</TableCell>
                    <TableCell className="c-text-center">Area</TableCell>
                    <TableCell className="c-text-center">AFIP</TableCell>
                    <TableCell className="c-text-center">Fecha de Inicio</TableCell>
                    <TableCell className="c-text-center">Ultima Conexión</TableCell>
                    <TableCell className="c-text-center">Motivo de baja</TableCell>
                    <TableCell className="c-text-center ">Fecha de baja</TableCell>
                    <TableCell className="c-text-center ">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">{shortenText(item.name ?? "-", 20)}</TableCell>
                      <TableCell className="c-rows-elipsis">{shortenText(item.company ?? "-", 20)}</TableCell>
                      <TableCell className="c-mwp-10">{item.accountTypeName ?? "-"}</TableCell>
                      <TableCell className="c-mwp-10">
                        {item.accountTypeId === RoleEnum.Sistema.Id ? (
                          !item.whitoutAFIPCertificate ? (
                            <CheckIcon className="c-icon-success" />
                          ) : (
                            <ClearIcon className="c-icon-cancel" />
                          )
                        ) : (
                          <HorizontalRuleIcon className="c-icon-grey" />
                        )}
                      </TableCell>
                      <TableCell className="c-mwp-10">{moment(item.dateCreateOn).format("DD/MM/yyyy hh:mm")}</TableCell>
                      <TableCell className="c-mwp-10">{getLastConnectionByUsers(item.users)}</TableCell>
                      <TableCell className="c-mwp-10">{item.reasonForUnsubscribeName ?? "-"}</TableCell>
                      <TableCell className="c-mwp-10">
                        {item.unsubscribeDate ? moment(item.unsubscribeDate).format("DD/MM/yyyy hh:mm") : "-"}
                      </TableCell>
                      <TableCell>
                        <div>
                          <Grid>
                            <IconButton
                              className="c-grid-btn-green"
                              onClick={() => {
                                setOpenSubscribe(true);
                                setDataSubscribe({
                                  accountId: item.id,
                                  title: "Dar de alta",
                                  content: `¿Está seguro de dar de alta la cuenta de ${item.name} - ${
                                    item.company ?? ""
                                  }?`,
                                });
                              }}
                            >
                              <ThumbUpIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-green"
                              onClick={() => history.push("/cuenta/editar/" + item.id)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-cancel"
                              onClick={() => {
                                history.push("/cuenta/ConfiguracionCuenta/" + item.id);
                              }}
                            >
                              <SettingsIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-info"
                              onClick={() => {
                                history.push("/usuario/lista/" + item.id);
                              }}
                            >
                              <PeopleAltIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-gray"
                              onClick={() => {
                                setOpenDelete(true);
                                setDataDelete({
                                  ...dataDelete,
                                  id: item.id,
                                  title: "Baja de registro",
                                  content: `${item.name} - ${item.company}`,
                                });
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount > 1 ? paginationData.amount : 0}
            shape="rounded"
          />
          <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
      <CDialogDelete
        open={openSubscribe}
        setOpen={setOpenSubscribe}
        title={dataSubscribe?.title ?? ""}
        content={dataSubscribe?.content ?? ""}
        actionConfirm={actionConfirmSubscribeAccount}
      />
      <UnsubscribeAccountModal
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        reasonsList={reasonsList}
        dataDelete={dataDelete}
        setDataDelete={setDataDelete}
        actionConfirmUnsubscribeAccount={actionConfirmUnsubscribeAccount}
        editReason={true}
      />
    </>
  );
}
