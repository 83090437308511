import { useEffect, useState } from "react";
import * as banksService from "../../../../api/services/banks";
import { Button, Grid, Paper, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import BanksList from "./banksList";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

export default function Banks() {
  const [banksList, setBanksList]: any = useState();
  const history = useHistory();
  const [valueSearch, setValueSearch] = useState("");
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    getBanks({ page: 1 });
  }, []);

  async function getBanks(params: {
    page: number;
    search?: string;
    pageSize?: number;
    orderBy?: string;
    deleted?: boolean;
  }) {
    setIsLoading(true);
    try {
      let { result, data, message } = await banksService.getBanks(params);
      if (result && data) {
        let currentPage: number = params.page;
        let count: number = data.totalRecords;
        let amount = Math.ceil(count / rowsPerPage);
        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });

        setBanksList(data.list);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function reloadPage(page: number, search: string, pageSize: number) {
    getBanks({ page, search, pageSize });
  }

  function changePage(event, value: number) {
    let page: number = value;

    setPaginationData({ ...paginationData, currentPage: page });
    reloadPage(page, "", rowsPerPage);
  }

  function handleChangeRowsPerPage(event: { target: { value: number } }) {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadPage(1, "", rowsPerPage);
  }

  function onClickSearch() {
    getBanks({ page: 1, search: valueSearch });
  }

  function handleChangeValueSearch(e: { target: { value: string } }) {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  }

  const deleteBank = (id: number) => {
    banksService.deleteBank(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Banco eliminado exitosamente");
        reloadPage(1, "", rowsPerPage);
      } else {
        toggleMessage("error", res["message"] ?? "Ocurrió un error al eliminar el banco seleccionado");
      }
    });
  };

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
      <a href={"/banco/crear"}>
        <Button
          variant="contained"
          size="small"
          className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
        >
          Agregar Nuevo
        </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Banco"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={() => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {banksList && banksList.length !== 0 ? (
        <BanksList
          banksList={banksList}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          deleteBank={deleteBank}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
