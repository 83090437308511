import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { colourEnum } from "../../../enums";
import React from "react";
import CDialogDelete from "../../../common/CDialogDelete";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory } from "react-router-dom";
import { Notification } from "../../../api/services/notifications/notification.types";

type IProps = {
  activeNotificationList: Notification[];
  editActive(e: any, id: number): void;
  deleteNotification(id: number, value: boolean): void;
  paginationData: {
    amount: number;
    currentPage: number;
    totalRecords: number;
  };
  changePage: (event: any, value: any) => void;
  handleChangeRowsPerPage: (event: {
    target: {
      value: string | number;
    };
  }) => void;
  reloadData: (page: number, pageSize: number) => void;
  rowsPerPageActive: number;
};

export default function ActiveNotificationList(props: IProps) {
  const {
    activeNotificationList,
    editActive,
    deleteNotification,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
    reloadData,
    rowsPerPageActive,
  } = props;
  const history = useHistory();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [dataDelete, setDataDelete]: any = React.useState({});

  const actionConfirm = () => {
    deleteNotification(dataDelete.id, true);
    setOpenDelete(false);
    reloadData(paginationData.currentPage, rowsPerPageActive);
  };

  return (
    <>
      {activeNotificationList && activeNotificationList.length !== 0 ? (
        <>
          <Paper className="c-mt-20 c-grid-results" elevation={3}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="c-table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="c-text-center">Título</TableCell>
                        <TableCell className="c-text-center">Mensaje</TableCell>
                        <TableCell className="c-text-center">Color</TableCell>
                        <TableCell className="c-text-center">Activo</TableCell>
                        <TableCell className="c-text-center">Opciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeNotificationList.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell className="c-rows-elipsis">{item.title}</TableCell>
                          <TableCell className="c-rows-elipsis">{item.message}</TableCell>
                          <TableCell className="c-rows-elipsis">
                            {colourEnum.map((e) => {
                              if (e.value === item.colourEnum) {
                                {
                                  return e.text;
                                }
                              }
                            })}
                          </TableCell>
                          <TableCell className="c-rows-elipsis">
                            <Checkbox
                              style={{ color: "#0063cc" }}
                              checked={item.active}
                              onChange={(e) => {
                                editActive(e, item.id);
                              }}
                              name="active"
                            />
                          </TableCell>
                          <TableCell className="c-rows-elipsis">
                            <div>
                              <IconButton
                                className="c-grid-btn-green"
                                onClick={() => history.push("/notificaciones/editar/" + item.id)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                className="c-grid-btn-cancel"
                                onClick={() => {
                                  setOpenDelete(true);
                                  setDataDelete({
                                    id: item.id,
                                    title: "Dar de baja",
                                    content: `¿Está seguro de dar de baja la notificación?`,
                                  });
                                }}
                              >
                                <BlockIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Pagination
                className="c-pagination"
                onChange={changePage}
                page={paginationData.currentPage}
                count={paginationData.amount > 1 ? paginationData.amount : 0}
                shape="rounded"
              />
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
                Registros por páginas
              </InputLabel>
              <NativeSelect
                defaultValue={20}
                onChange={handleChangeRowsPerPage}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </NativeSelect>
            </Grid>
          </Paper>
          <CDialogDelete
            open={openDelete}
            setOpen={setOpenDelete}
            title={dataDelete.title}
            content={dataDelete.content}
            actionConfirm={actionConfirm}
          />
        </>
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
