import { API, errorHandling } from "./index";
import { DefaultResponse } from "./SSOClient";

const BASE_URL: string = "/api/voucher-type";

export async function getVoucherTypes(
  search?: string,
  page?: number,
  pageSize?: number,
  orderBy?: any
) {
  return API.get(BASE_URL, {
    params: {
      search,
      page,
      pageSize,
      orderBy,
    },
  }).then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function saveVoucher(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getVoucherById(id: number) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function editVoucher(id, body) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteVoucher(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getRelationAfip(): Promise<DefaultResponse<{
  desc: string,
  id: number
}[]>> {
  return API.get(BASE_URL + "/afip").then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function getVoucherTypesWithoutPage() {
  return API.get(BASE_URL).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res["status"],
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res["status"],
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
