import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { FieldArray, FormikErrors } from "formik";
import CTextField from "../../../../common/CTextField";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import {
  TableOfCUIT,
  TableOfCertificate,
} from "../create-editAccount/tableOfCertificate";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import { AccountById } from "../../../../api/services/Account/account.types";
import { RoleEnum } from "../../../../enums";
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import * as accountService from "../../../../api/services/Account";
import * as planService from "../../../../api/services/plan";

type IProps = {
  handleChange: {
    (e: ChangeEvent<AccountById>): void;
    <T = string | ChangeEvent<AccountById>>(
      field: T
    ): T extends ChangeEvent<AccountById>
      ? void
      : (e: string | ChangeEvent<AccountById>) => void;
  };
  errors: FormikErrors<any>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  id?: number;
  values: AccountById;
};

function CreateOrEditAccount(props: IProps) {
  let { handleChange, errors, values, setFieldValue, id } = props;

  const [accountTypesList, setAccountTypesList] = useState<
    { value: number; text: string }[]
  >([]);
  const [planTypeList, setPlanTypeList] = useState<
    { value: number; text: string }[]
  >([]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([accountService.getAccountsTypes(), planService.getPlanList()])
      .then((res) => {
        let list = res[0].map((item) => {
          return { value: item.key, text: item.text };
        });
        list = list.filter(
          (item) =>
            item.text === "Contabilidad" ||
            item.text === "Sistema" ||
            item.text === "Sistema - Básico"
        );
        setAccountTypesList(list);
        if (res[1].data) {
          let list = res[1].data.sort((a, b) => {
            const numA = parseInt(a.text.split("-")[0]);
            const numB = parseInt(b.text.split("-")[0]);
            return numA - numB;
          });

          setPlanTypeList(list);
        }
      })
      .catch(() =>
        toggleMessage(
          "error",
          "Ocurrió un error al cargar los tipos de cuenta."
        )
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5} className="c-border-botom-green-ligth">
              <h3 className="c-text-fidel-green">Información Básica</h3>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5} className="c-border-botom-green-ligth">
              <h3 className="c-text-fidel-green">Configuración</h3>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={5} xs={5} className="c-mt-20">
              <Grid container>
                <CTextField
                  label="Nombre"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  name="name"
                  helperText={
                    <span className="c-font-cancel">
                      {errors["name"] ?? null}
                    </span>
                  }
                  value={values.name ?? ""}
                />
              </Grid>
              <Grid container className="c-mt-20">
                <CTextField
                  label="Empresa"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  name="company"
                  helperText={
                    <span className="c-font-cancel">
                      {errors["company"] ?? null}
                    </span>
                  }
                  value={values.company ?? ""}
                />
              </Grid>
              <Grid container className="c-mt-20">
                <CTextField
                  label="Email Admin"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  name="email"
                  AutoComplete="new-password"
                  helperText={
                    <span className="c-font-cancel">
                      {errors["email"] ?? null}
                    </span>
                  }
                  value={values.email ?? ""}
                />
              </Grid>
              {!id && (
                <Grid container className="c-mt-20">
                  <CTextField
                    label="Contraseña"
                    type="password"
                    fullWidth
                    onChange={handleChange}
                    name="password"
                    AutoComplete="new-password"
                    helperText={
                      <span className="c-font-cancel">
                        {errors["password"] ?? null}
                      </span>
                    }
                    value={values.password ?? ""}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item md={5} xs={5} className="c-mt-20">
              <Grid container>
                <CCustomSelectOrSearch
                  disabled={id ? true : false}
                  name="accountType"
                  placeHolder="Tipo de Cuenta"
                  onChange={(e: { value: number; text: string }) => {
                    setFieldValue("accountType", e ? e.value : undefined);
                    setFieldValue("accountTypeName", e ? e.text : undefined);
                  }}
                  value={accountTypesList.find(
                    (x: { text: string; value: number }) => {
                      return x.value === values.accountType;
                    }
                  )}
                  dataList={accountTypesList}
                />
                {errors["accountType"] && (
                  <FormHelperText
                    className={errors["accountType"] ? "c-font-cancel" : ""}
                  >
                    {errors["accountType"] ?? null}
                  </FormHelperText>
                )}
              </Grid>
              {values.accountType === RoleEnum.Sistema.Id && (
                <>
                  {!id && (
                    <Grid container className="c-mt-20">
                      <CTextField
                        label="Cotización Dólar"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="dolarValue"
                        helperText={
                          <span className="c-font-cancel">
                            {errors["dolarValue"] ?? null}
                          </span>
                        }
                        value={values.dolarValue ?? ""}
                      />
                    </Grid>
                  )}
                  {/* <Grid container className="c-mt-20">
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "#0063cc" }}
                          checked={values.whitoutAFIPCertificate}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("certificateList", []);
                          }}
                          name="whitoutAFIPCertificate"
                        />
                      }
                      name="whitoutAFIPCertificate"
                      label="Sin Certificados"
                      labelPlacement="start"
                    />
                    {errors["whitoutAFIPCertificate"] && (
                      <FormHelperText className={errors["whitoutAFIPCertificate"] ? "c-font-cancel" : ""}>
                        {errors["whitoutAFIPCertificate"] ?? null}
                      </FormHelperText>
                    )}
                  </Grid> */}
                </>
              )}
              <Grid container className="c-mt-20">
                <CCustomSelectOrSearch
                  disabled={false}
                  name="accountPlanId"
                  placeHolder="Plan"
                  onChange={(e: { value: any }) => {
                    setFieldValue("accountPlanId", e ? e.value : undefined);
                  }}
                  value={planTypeList.find(
                    (x: any) => x.value === values.accountPlanId
                  )}
                  dataList={planTypeList}
                />
                {errors["accountPlanId"] && (
                  <FormHelperText
                    className={errors["accountPlanId"] ? "c-font-cancel" : ""}
                  >
                    {errors["accountPlanId"] ?? null}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid className="c-grid-header c-mt-50" container spacing={1}>
          <Grid item xs={12} className="c-border-botom-green-ligth">
            <h3 className="c-text-fidel-green">Datos del Contador</h3>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="c-grid-header-filter c-mt-20" item xs={12}>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="Nombre"
              type="text"
              fullWidth
              onChange={handleChange}
              name="accountantName"
              helperText={
                <span className="c-font-cancel">
                  {errors["accountantName"] ?? null}
                </span>
              }
              value={values.accountantName ?? ""}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="E-mail"
              type="text"
              fullWidth
              onChange={handleChange}
              name="accountantEmail"
              helperText={
                <span className="c-font-cancel">
                  {errors["accountantEmail"] ?? null}
                </span>
              }
              value={values.accountantEmail ?? ""}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="Estudio Contable"
              type="text"
              fullWidth
              onChange={handleChange}
              name="accountantStudy"
              helperText={
                <span className="c-font-cancel">
                  {errors["accountantStudy"] ?? null}
                </span>
              }
              value={values.accountingStudy ?? ""}
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <CTextField
              label="Contacto"
              type="text"
              fullWidth
              onChange={handleChange}
              name="accountantContact"
              helperText={
                <span className="c-font-cancel">
                  {errors["accountantContact"] ?? null}
                </span>
              }
              value={values.accountantContact ?? ""}
            />
          </Grid>
        </Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Teléfonos"
            type="text"
            fullWidth
            onChange={handleChange}
            name="accountantPhone"
            helperText={
              <span className="c-font-cancel">
                {errors["accountantPhone"] ?? null}
              </span>
            }
            value={values.accountantPhone ?? ""}
          />
        </Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Datos Adicionales"
            type="text"
            fullWidth
            onChange={handleChange}
            name="otherInformation"
            helperText={
              <span className="c-font-cancel">
                {errors["otherInformation"] ?? null}
              </span>
            }
            rows={5}
            multiLine
            value={values.otherInformation ?? ""}
          />
        </Grid>
        {values.accountType === RoleEnum.Sistema.Id && (
          <Grid container spacing={1}>
            <FieldArray
              name="certificateList"
              render={(arrayHelpers) => (
                <>
                  <Grid className="c-grid-header c-mt-50" container spacing={1}>
                    <Grid item xs={11} className="c-border-botom-green-ligth">
                      <h3 className="c-text-fidel-green">Certificados</h3>
                    </Grid>
                    <Grid item xs={1} className="c-border-botom-green-ligth">
                      <AddIcon
                        fontSize="small"
                        className="c-icon-green c-item-f-r c-cursor-pointer c-fs-25"
                        onClick={() =>
                          arrayHelpers.push({
                            certificate: undefined,
                            cUIT: undefined,
                            apiKey: undefined,
                            certificateExpiredDay: undefined,
                            afipSalePoint: undefined,
                          })
                        }
                      ></AddIcon>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="c-mt-20">
                    <TableContainer>
                      <Table className="c-table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="c-text-center">
                              Certificado AFIP/CUIT
                            </TableCell>
                            <TableCell className="c-text-center">
                              ApiKey
                            </TableCell>
                            <TableCell className="c-text-center">
                              Sin AFIP?
                            </TableCell>
                            <TableCell className="c-text-center">
                              Fecha Vencimiento
                            </TableCell>
                            <TableCell className="c-text-center ">
                              Opciones
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        {values.certificateList.some(x => x.whitoutCertificateAFIP) ? (
                          <TableBody>
                            {values.certificateList.map((item, index) => (
                              <Fragment key={index}>
                                <TableOfCUIT
                                  item={item}
                                  id={id}
                                  index={index}
                                  arrayHelpers={arrayHelpers}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                ></TableOfCUIT>
                              </Fragment>
                            ))}
                          </TableBody>
                        ) : (
                          <TableBody>
                            {values.certificateList.map((item, index) => (
                              <Fragment key={index}>
                                <TableOfCertificate
                                  item={item}
                                  id={id}
                                  index={index}
                                  arrayHelpers={arrayHelpers}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                ></TableOfCertificate>
                              </Fragment>
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Grid>
                </>
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default CreateOrEditAccount;
