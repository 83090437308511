import { useHistory } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import * as accountService from "../../../api/services/Account";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import CreateOrEditAccount from "./createOrEdit";
import {
  AccountById,
  CreateAccount,
} from "../../../api/services/Account/account.types";
import moment from "moment";

export default function AccountCreate() {
  const history = useHistory();

  //#region Formulario
  const initialValuesForm: AccountById = {
    name: "",
    accountType: 2,
    accountTypeName: "",
    whitoutAFIPCertificate: false,
    company: "",
    dateCreateOn: "",
    email: "",
    dolarValue: 0,
    accountantName: "",
    accountingStudy: "",
    accountantPhone: "",
    accountantContact: "",
    accountantEmail: "",
    otherInformation: "",
    accountConfigurationId: 0,
    orderConfigurationId: 0,
    certificateList: [],
    reasonForUnsubscribeId: 0,
    reasonForUnsubscribeName: "",
    unsubscribeDate: "",
    accountPlanId: 0,
    accountPlanName: "",
    id: 0,
  };

  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
    company: Yup.string().required("Campo Requerido"),
    email: Yup.string().email("Email Inválido ").required("Campo Requerido"),
    password: Yup.string().required("Campo requerido"),
    accountType: Yup.number().required("Campo Requerido"),
    accountantName: Yup.string().nullable(),
    accountingStudy: Yup.string().nullable(),
    accountantPhone: Yup.string()
      .trim()
      .matches(
        /^[0-9]*$/g,
        "El teléfono debe contener sólo números y sin espacios"
      )
      .nullable(),
    accountantContact: Yup.string().nullable(),
    accountantEmail: Yup.string().email("Email Inválido").nullable(),
    additionalData: Yup.string().nullable(),
    whitoutAFIPCertificate: Yup.boolean(),
    dolarValue: Yup.string().when("accountType", {
      is: 2,
      then: Yup.string().required("Campo Requerido"),
      otherwise: Yup.string().nullable(),
    }),
    accountPlanId: Yup.number().required("Campo Requerido"),
    certificateAFIP: Yup.boolean(),
    certificateList: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number(),
          changeCertificate: Yup.boolean(),
          certificate: Yup.mixed().when("whitoutAFIPCertificate", {
            is: false,
            then: Yup.mixed()
              .nullable()
              .test("es-archivo", "Debe ser un archivo", (value) => {
                return value instanceof File;
              }),
            otherwise: Yup.mixed().nullable(),
          }),
          contentLenght: Yup.number(),
          cUIT: Yup.string(),
          apiKey: Yup.string(),
          certificateExpiredDay: Yup.string(),
          deleted: Yup.boolean(),
          afipSalePoint: Yup.array().of(Yup.string()),
          businessName: Yup.string(),
        })
      )
      .nullable(),
  });
  //#endregion

  async function saveAccount(values: AccountById) {
    let list: {
      id: number;
      whitoutCertificateAFIP: boolean;
      changeCertificate: boolean;
      certificate?: File;
      contentLenght?: number;
      cUIT: string;
      apiKey?: string;
      certificateExpiredDay?: Date;
      deleted?: boolean;
      afipSalePoint?: string[];
      businessName?: string;
    }[] = [];
    if (!values.whitoutAFIPCertificate) {
      values.certificateList.forEach(async (item) => {
        if (item.certificate) {
          list.push({
            id: item.id,
            whitoutCertificateAFIP: item.whitoutCertificateAFIP,
            changeCertificate: item.changeCertificate,
            cUIT: item.cUIT,
            apiKey: item.apiKey,
            certificateExpiredDay: item.certificateExpiredDay,
            certificate: item.certificate,
          });
        }
      });
    } else {
      values.certificateList.forEach((item) => {
        list.push({
          id: item.id,
          whitoutCertificateAFIP: item.whitoutCertificateAFIP,
          changeCertificate: item.changeCertificate,
          cUIT: item.cUIT,
        });
      });
    }
    let body: CreateAccount = {
      ...values,
      certificateList: list,
    };

    accountService
      .saveAccount(body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Cuenta creada exitosamente");
          history.push("/cuenta/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik
            initialValues={initialValuesForm}
            validateOnChange={false}
            validationSchema={filterSchema}
            onSubmit={saveAccount}
          >
            {({
              values,
              errors,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <CreateOrEditAccount
                  handleChange={handleChange}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <Grid>
                  <Grid container spacing={1}>
                    <Grid
                      item
                      xs={12}
                      className="c-border-botom-green-ligth c-mt-20"
                    ></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                        onClick={() => setIsLoading(true)}
                      >
                        Guardar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
