import React from "react";
import Select, { components } from "react-select";
import { FormControl } from "@material-ui/core";

const NoOptionsMessage = (props: any) => {
  return <components.NoOptionsMessage {...props}>Sin resultados</components.NoOptionsMessage>;
};

const LoadingMessage = (props: any) => {
  return <components.LoadingMessage {...props}>Cargando...</components.LoadingMessage>;
};

const selectStyles = {
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (provided: any) => ({ ...provided, zIndex: "9999 !important" }),
};

/** @ deprecated USAR src/common/CSelect */
export default function (props: any) {
  const {
    Options,
    OnChange,
    PlaceHolder,
    Name,
    Disabled,
    Value,
    Clearable,
    Loading,
    ClassName,
    isMulti,
    defaultValue,
  } = props;

  // const customStyles = {
  //   control: (base, state) => ({
  //     ...base,
  //     borderColor: state.isFocused
  //       ? "rgba(0, 0, 0, 0.30)"
  //       : isValid
  //       ? "rgba(0, 0, 0, 0.30)"
  //       : "red",
  //     "&:hover": {
  //       borderColor: state.isFocused
  //         ? "rgba(0, 0, 0, 0.80)"
  //         : isValid
  //         ? "rgba(0, 0, 0, 0.80)"
  //         : "red",
  //     },
  //   }),
  // };

  return (
    <FormControl variant="outlined" fullWidth={true} size="small">
      <Select
        className={ClassName ?? ""}
        styles={selectStyles}
        placeholder={PlaceHolder}
        name={Name}
        value={Value}
        defaultValue={defaultValue}
        getOptionLabel={(e: any) => e.text}
        getOptionValue={(e: any) => e.value}
        cacheOptions
        options={Options}
        onChange={OnChange}
        isDisabled={Disabled ?? false}
        isClearable={Clearable ?? true}
        isLoading={Loading ?? false}
        components={{ NoOptionsMessage, LoadingMessage }}
        isMulti={isMulti}
      />
    </FormControl>
  );
}
