import { Categories } from "./category.types";
import { API, errorHandling } from "../index";
import { DefaultResponse } from "../SSOClient";

const BASE_URL: string = "api/categories";

export async function getCategories(id: number): Promise<DefaultResponse<Categories[]>> {
    return API.get(BASE_URL + "/select-admin/" + id, {
        params: {
            onlyMains: true
        }
    }).then(({ data }) => {
        return { result: true, data }
    }).catch(errorHandling)
}