import { API } from "..";
import { DefaultResponse } from "../SSOClient";

const BASE_URL: string = "/api/plan";

export async function getPlanList(): Promise<DefaultResponse<{ value: number; text: string }[]>> {
    return API.get(BASE_URL + "/select-list").then(({ data }) => {
        let list = data.map((item) => {
            return { text: item.text, value: item.id }
        })
        return {
            result: true,
            data: list,
        };
    });
}
