import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dispatch, Fragment, SetStateAction } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Button, Grid } from "@material-ui/core";
import * as userService from "../../../../../api/services/AdminUsers";
import { toggleMessage } from "../../../../../utils/events";

type IProps = {
  open: boolean;
  user: { id: number; name: string };
  handleClose: Dispatch<SetStateAction<boolean>>;
  unlockUserAdmin(id: any): Promise<void>;
};

function UnblockUser(props: IProps) {
  let { open, handleClose, user, unlockUserAdmin } = props;

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={() => {
          handleClose(false);
        }}
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="c-border-bottom">
          <Grid container>
            <h3>
              <LockIcon fontSize="small" />
              Desbloqueo
            </h3>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <h4>Está a punto de desbloquear el Usuario.</h4>
          <h5>¿Está seguro que desea continuar?</h5>
        </DialogContent>
        <Grid container justifyContent="flex-end" className="c-border-top">
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-m-5"
            onClick={() => {
              unlockUserAdmin(user.id);
            }}
          >
            Si
          </Button>
          <Button
            className="c-btn-cancel  c-cursor-pointer c-m-5"
            onClick={() => {
              handleClose(false);
            }}
          >
            No
          </Button>
        </Grid>
      </Dialog>
    </Fragment>
  );
}

export default UnblockUser;
