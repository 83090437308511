import { Grid } from "@material-ui/core";
import CTextField from "../../../../common/CTextField";
import CustomTooltip from "../../../../common/CTooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function ImportConfigurations(props) {
  const { values, handleChange, errors } = props;

  return (
    <>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Código"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="code"
            helperText={<span className="c-font-cancel">{errors["code"] ?? null}</span>}
            value={values.code ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Área Teléfono"
            type="number"
            fullWidth
            onChange={handleChange}
            name="phoneArea"
            helperText={<span className="c-font-cancel">{errors["phoneArea"] ?? null}</span>}
            value={values.phoneArea ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Razón Social"
            type="number"
            fullWidth
            onChange={handleChange}
            name="identificationName"
            helperText={<span className="c-font-cancel">{errors["identificationName"] ?? null}</span>}
            value={values.identificationName ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Teléfono"
            type="number"
            fullWidth
            onChange={handleChange}
            name="phone"
            helperText={<span className="c-font-cancel">{errors["phone"] ?? null}</span>}
            value={values.phone ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Nombre"
            type="number"
            fullWidth
            onChange={handleChange}
            name="name"
            helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
            value={values.name ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Área Celular"
            type="number"
            fullWidth
            onChange={handleChange}
            name="mobileArea"
            helperText={<span className="c-font-cancel">{errors["mobileArea"] ?? null}</span>}
            value={values.mobileArea ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Apellido"
            type="number"
            fullWidth
            onChange={handleChange}
            name="surname"
            helperText={<span className="c-font-cancel">{errors["surname"] ?? null}</span>}
            value={values.surname ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Celular"
            type="number"
            fullWidth
            onChange={handleChange}
            name="mobile"
            helperText={<span className="c-font-cancel">{errors["mobile"] ?? null}</span>}
            value={values.mobile ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Nombre Fantasía"
            type="number"
            fullWidth
            onChange={handleChange}
            name="fantasyName"
            helperText={<span className="c-font-cancel">{errors["fantasyName"] ?? null}</span>}
            value={values.fantasyName ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Código Postal"
            type="number"
            fullWidth
            onChange={handleChange}
            name="cp"
            helperText={<span className="c-font-cancel">{errors["cp"] ?? null}</span>}
            value={values.cp ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Email"
            type="number"
            fullWidth
            onChange={handleChange}
            name="email"
            helperText={<span className="c-font-cancel">{errors["email"] ?? null}</span>}
            value={values.email ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Provincia"
            type="number"
            fullWidth
            onChange={handleChange}
            name="province"
            helperText={<span className="c-font-cancel">{errors["province"] ?? null}</span>}
            value={values.province ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="DNI/CUIT"
            type="number"
            fullWidth
            onChange={handleChange}
            name="identificationNumber"
            helperText={<span className="c-font-cancel">{errors["identificationNumber"] ?? null}</span>}
            value={values.identificationNumber ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Localidad"
            type="number"
            fullWidth
            onChange={handleChange}
            name="city"
            helperText={<span className="c-font-cancel">{errors["city"] ?? null}</span>}
            value={values.city ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Contacto"
            type="number"
            fullWidth
            onChange={handleChange}
            name="contact"
            helperText={<span className="c-font-cancel">{errors["contact"] ?? null}</span>}
            value={values.contact ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Ajuste Saldo Factura"
            type="number"
            fullWidth
            onChange={handleChange}
            name="adjustBalanceInvoice"
            helperText={<span className="c-font-cancel">{errors["adjustBalanceInvoice"] ?? null}</span>}
            value={values.adjustBalanceInvoice ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">El ajuste de saldo tendrá el certificado cuenta por defecto de la cuenta</span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Domicilio"
            type="number"
            fullWidth
            onChange={handleChange}
            name="domicile"
            helperText={<span className="c-font-cancel">{errors["domicile"] ?? null}</span>}
            value={values.domicile ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Ajuste Saldo Presupuesto"
            type="number"
            fullWidth
            onChange={handleChange}
            name="adjustBalanceBudget"
            helperText={<span className="c-font-cancel">{errors["adjustBalanceBudget"] ?? null}</span>}
            value={values.adjustBalanceBudget ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">El ajuste de saldo tendrá el certificado cuenta por defecto de la cuenta</span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Zona"
            type="number"
            fullWidth
            onChange={handleChange}
            name="zone"
            helperText={<span className="c-font-cancel">{errors["zone"] ?? null}</span>}
            value={values.zone ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Condición Pago"
            type="number"
            fullWidth
            onChange={handleChange}
            name="payCondition"
            helperText={<span className="c-font-cancel">{errors["payCondition"] ?? null}</span>}
            value={values.payCondition ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">
                Ingresar contado, si esta mal escrito le asigna cuenta corriente al cliente
              </span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Vendedor"
            type="number"
            fullWidth
            onChange={handleChange}
            name="seller"
            helperText={<span className="c-font-cancel">{errors["seller"] ?? null}</span>}
            value={values.seller ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={<span className="c-fs-11 ">Se debe poner el correo del Usuario Vendedor para que lo tome</span>}
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Condición IVA"
            type="number"
            fullWidth
            onChange={handleChange}
            name="ivaCondition"
            helperText={<span className="c-font-cancel">{errors["ivaCondition"] ?? null}</span>}
            value={values.ivaCondition ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Descuento"
            type="number"
            fullWidth
            onChange={handleChange}
            name="discount"
            helperText={<span className="c-font-cancel">{errors["discount"] ?? null}</span>}
            value={values.discount ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Vencimiento"
            type="number"
            fullWidth
            onChange={handleChange}
            name="expiration"
            helperText={<span className="c-font-cancel">{errors["expiration"] ?? null}</span>}
            value={values.expiration ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Observación"
            type="number"
            fullWidth
            onChange={handleChange}
            name="observation"
            helperText={<span className="c-font-cancel">{errors["observation"] ?? null}</span>}
            value={values.observation ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="IIBB"
            type="number"
            fullWidth
            onChange={handleChange}
            name="iibb"
            helperText={<span className="c-font-cancel">{errors["iibb"] ?? null}</span>}
            value={values.iibb ?? ""}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20"></Grid>
        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Lista de precios"
            type="number"
            fullWidth
            onChange={handleChange}
            name="priceList"
            helperText={<span className="c-font-cancel">{errors["priceList"] ?? null}</span>}
            value={values.priceList ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">
                Ingresar el nombre de la lista de precios, si esta mal escrito no le asigna ninguna al cliente
              </span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6} xs={6} className="c-mt-20" />
        <Grid item md={5} xs={5} className="c-mt-20">
          <CTextField
            label="Depósito"
            type="number"
            fullWidth
            onChange={handleChange}
            name="deposit"
            helperText={<span className="c-font-cancel">{errors["deposit"] ?? null}</span>}
            value={values.deposit ?? ""}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-mt-30">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">
                Ingresar el nombte del depósito, si esta mal escrito no le asigna ninguno al cliente
              </span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
    </>
  );
}
