import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import CustomTooltip from "../../../../common/CTooltip";
import { CoinEnum, PayConditionEnum } from "../../../../enums";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { PutConfigAccount } from "../../../../api/services/Account/account.types";
import { ChangeEvent } from "react";

type IProps = {
  values: PutConfigAccount;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  salePoints:
    | {
        value: string;
        text: string;
      }[];
};

export default function VoucherSettingsForm(props: IProps) {
  const { values, handleChange, setFieldValue, salePoints } = props;

  const validateChecks = (
    applyAutoBalanceInPurchaseCreditNoteAndReturn: boolean,
    applyAutoBalanceInPurchaseInvoiceAndBudget: boolean,
    applyAutoBalanceOnSaleCreditNoteAndReturn: boolean,
    applyAutoBalanceOnSaleInvoiceAndBudget: boolean
  ) => {
    let result: boolean = true;
    if (
      applyAutoBalanceInPurchaseInvoiceAndBudget ||
      applyAutoBalanceInPurchaseCreditNoteAndReturn ||
      applyAutoBalanceOnSaleCreditNoteAndReturn ||
      applyAutoBalanceOnSaleInvoiceAndBudget
    ) {
      result = false;
    }
    return result;
  };

  return (
    <Grid className="c-mt-20">
      <Grid container>
        <Grid item md={5} xs={5} className="c-mt-20">
          <CCustomSelectOrSearch
            disabled={false}
            name="payConditionId"
            placeHolder="Condicion de Venta"
            onChange={(e: { value: number }) => {
              setFieldValue("payConditionId", e ? e.value : 1);
            }}
            value={PayConditionEnum.find((e) => {
              return e.value === values.payConditionId;
            })}
            dataList={PayConditionEnum.filter((item) => {
              return item.value !== 0;
            })}
          />

          <Grid className="c-mt-20">
            <CCustomSelectOrSearch
              disabled={false}
              name="selectedSalePoint"
              placeHolder="Puntos de venta"
              onChange={(e: { value: string;}[]) => {
                let list: string[] = [];
                e.map((item: { value: string; }) => {
                  return list.push(item.value);
                });
                setFieldValue("selectedSalePoint", e ? list : undefined);
              }}              
              dataList={salePoints}
              isMulti       
              value={
                salePoints && salePoints.length > 0 
                ? values.selectedSalePoint.map(x => ({
                    text: salePoints.find(y => y.value === x)?.text, 
                    value: x
                  }))
                : []           
              }
            />
          </Grid>
          <Grid className="c-mt-20">
            <CCustomSelectOrSearch
              disabled={false}
              name="coinDefaultId"
              placeHolder="Moneda"
              onChange={(e: { value: any }) => {
                setFieldValue("coinDefaultId", e ? e.value : undefined);
              }}
              value={CoinEnum.find((e) => {
                return e.value === values.coinDefaultId;
              })}
              dataList={CoinEnum}
            />
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.dolarBlue ?? false}
                      onChange={handleChange}
                      name="dolarBlue"
                    />
                  }
                  name="dolarBlue"
                  label="Dolar blue"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">Si está Activo el Usuario podrá seleccionar la moneda Dólar Blue</span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.multipleCoins ?? false}
                      onChange={handleChange}
                      name="multipleCoins"
                    />
                  }
                  name="multipleCoins"
                  label="Facturar en todas las Monedas"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo el Usuario podrá seleccionar cualquier moneda para realizar una Factura
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.mandatoryConceptForSale ?? false}
                  onChange={handleChange}
                  name="mandatoryConceptForSale"
                />
              }
              name="mandatoryConceptForSale"
              label="Concepto Obligatorio en Venta"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.mandatoryConceptForPurchase ?? false}
                  onChange={handleChange}
                  name="mandatoryConceptForPurchase"
                />
              }
              name="mandatoryConceptForPurchase"
              label="Concepto Obligatorio en Compra"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.multipleCoinsBudget ?? false}
                  onChange={handleChange}
                  name="multipleCoinsBudget"
                />
              }
              name="multipleCoinsBudget"
              label="Presupuestar en todas las Monedas"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.hideBalanceSalesReceipts ?? false}
                  onChange={handleChange}
                  name="hideBalanceSalesReceipts"
                />
              }
              name="hideBalanceSalesReceipts"
              label="Ocultar Saldo en Comprobantes de Ventas"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.totalSalesReports ?? false}
                  onChange={handleChange}
                  name="totalSalesReports"
                />
              }
              name="totalSalesReports"
              label="Mostrar totales en Ventas"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.extendedObservationReport ?? false}
                      onChange={handleChange}
                      name="extendedObservationReport"
                    />
                  }
                  name="extendedObservationReport"
                  label="Reporte de Observación Extendida"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      El Reporte quitará las distintas alícuotas, extendiendo el campo Observaciones y dejando en un
                      renglón el campo Remitos
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.sendEmailSavingVouchers ?? false}
                      onChange={handleChange}
                      name="sendEmailSavingVouchers"
                    />
                  }
                  name="sendEmailSavingVouchers"
                  label="Enviar email al guardar el comprobante"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      En caso de NO estar activada, al guardar los comprobantes, el modal de envío de email viene por
                      defecto destildado
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1} xs={1} className="c-mt-20" />
        <Grid item md={5} xs={5} className="c-mt-20">
          <Grid>
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.confirmCommandVoucher ?? false}
                      onChange={handleChange}
                      name="confirmCommandVoucher"
                    />
                  }
                  name="confirmCommandVoucher"
                  label="Confirmar comprobantes presionando una tecla (F9)"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo en la creación de los comprobantes se confirmaran solo con apretar una tecla
                      establecida, y se salteara el paso de la confirmación a través del modal. F9 para guardar y F7
                      para guardar e imprimir
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.quickPrint ?? false}
                      onChange={handleChange}
                      name="quickPrint"
                    />
                  }
                  name="quickPrint"
                  label="Impresión Rápida"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo al guardar e imprimir una factura o presupuesto de venta la impresion no va a abrir
                      una pestaña nueva sino que va a salir la vista previa en la misma pagina
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.barCodeReaderDefault ?? false}
                      onChange={handleChange}
                      name="barCodeReaderDefault"
                    />
                  }
                  name="barCodeReaderDefault"
                  label="Lector de códigos por defecto?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo aparecerá por defecto el campo del Lector de códigos de barra, de lo contrario,
                      aparecerá el listado de Productos seleccionables (en compra y venta)
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.automaticApplyPositiveBalance ?? false}
                      onChange={(e) => {
                        setFieldValue("automaticApplyPositiveBalance", e.target.checked);
                        setFieldValue("applyAutoBalanceInPurchaseCreditNoteAndReturn", e.target.checked);
                        setFieldValue("applyAutoBalanceOnSaleCreditNoteAndReturn", e.target.checked);
                        setFieldValue("applyAutoBalanceInPurchaseInvoiceAndBudget", e.target.checked);
                        setFieldValue("applyAutoBalanceOnSaleInvoiceAndBudget", e.target.checked);
                      }}
                      name="automaticApplyPositiveBalance"
                    />
                  }
                  name="automaticApplyPositiveBalance"
                  label="Aplicar saldo a favor automáticamente"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo se aplicaran automáticamente los saldos a favor a los nuevos comprobantes
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          {values.automaticApplyPositiveBalance ? (
            <>
              <Grid className="c-mt-20">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.applyAutoBalanceOnSaleCreditNoteAndReturn ?? false}
                      onChange={(e) => {
                        setFieldValue("applyAutoBalanceOnSaleCreditNoteAndReturn", e.target.checked);
                        if (
                          validateChecks(
                            values.applyAutoBalanceInPurchaseCreditNoteAndReturn,
                            values.applyAutoBalanceInPurchaseInvoiceAndBudget,
                            e.target.checked,
                            values.applyAutoBalanceOnSaleInvoiceAndBudget
                          )
                        ) {
                          setFieldValue("automaticApplyPositiveBalance", false);
                        }
                      }}
                      name="applyAutoBalanceOnSaleCreditNoteAndReturn"
                    />
                  }
                  name="applyAutoBalanceOnSaleCreditNoteAndReturn"
                  label="Ventas (NC y Devolución)"
                  labelPlacement="start"
                />
              </Grid>
              <Grid className="c-mt-20">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.applyAutoBalanceOnSaleInvoiceAndBudget ?? false}
                      onChange={(e) => {
                        setFieldValue("applyAutoBalanceOnSaleInvoiceAndBudget", e.target.checked);
                        if (
                          validateChecks(
                            values.applyAutoBalanceInPurchaseCreditNoteAndReturn,
                            values.applyAutoBalanceInPurchaseInvoiceAndBudget,
                            values.applyAutoBalanceOnSaleCreditNoteAndReturn,
                            e.target.checked
                          )
                        ) {
                          setFieldValue("automaticApplyPositiveBalance", false);
                        }
                      }}
                      name="applyAutoBalanceOnSaleInvoiceAndBudget"
                    />
                  }
                  name="applyAutoBalanceOnSaleInvoiceAndBudget"
                  label="Ventas (Recibos Fac y Pre)"
                  labelPlacement="start"
                />{" "}
              </Grid>
              <Grid className="c-mt-20">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.applyAutoBalanceInPurchaseCreditNoteAndReturn ?? false}
                      onChange={(e) => {
                        setFieldValue("applyAutoBalanceInPurchaseCreditNoteAndReturn", e.target.checked);
                        if (
                          validateChecks(
                            e.target.checked,
                            values.applyAutoBalanceInPurchaseInvoiceAndBudget,
                            values.applyAutoBalanceOnSaleCreditNoteAndReturn,
                            values.applyAutoBalanceOnSaleInvoiceAndBudget
                          )
                        ) {
                          setFieldValue("automaticApplyPositiveBalance", false);
                        }
                      }}
                      name="applyAutoBalanceInPurchaseCreditNoteAndReturn"
                    />
                  }
                  name="applyAutoBalanceInPurchaseCreditNoteAndReturn"
                  label="Compras (NC y Devolución)"
                  labelPlacement="start"
                />
              </Grid>
              <Grid className="c-mt-20">
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.applyAutoBalanceInPurchaseInvoiceAndBudget ?? false}
                      onChange={(e) => {
                        setFieldValue("applyAutoBalanceInPurchaseInvoiceAndBudget", e.target.checked);
                        if (
                          validateChecks(
                            e.target.checked,
                            values.applyAutoBalanceInPurchaseCreditNoteAndReturn,
                            values.applyAutoBalanceOnSaleInvoiceAndBudget,
                            values.applyAutoBalanceOnSaleCreditNoteAndReturn
                          )
                        ) {
                          setFieldValue("automaticApplyPositiveBalance", false);
                        }
                      }}
                      name="applyAutoBalanceInPurchaseInvoiceAndBudget"
                    />
                  }
                  name="applyAutoBalanceInPurchaseInvoiceAndBudget"
                  label="Compras (OP y OP Pre)"
                  labelPlacement="start"
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.allowSendProductsInSales ?? false}
                      onChange={handleChange}
                      name="allowSendProductsInSales"
                    />
                  }
                  name="allowSendProductsInSales"
                  label="Activar Productos Entregados en Venta"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo no se tendran en cuenta los comprobantes en la hoja de ruta de presupuesto y
                      factura
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.dontUpdatePurchaseCost ?? false}
                      onChange={handleChange}
                      name="dontUpdatePurchaseCost"
                    />
                  }
                  name="dontUpdatePurchaseCost"
                  label="NO Actualizar Costos por Defecto en Compras"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo NO se actualizararán automaticamente los costos en las compras
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.logoFidelVouchers ?? false}
                  onChange={handleChange}
                  name="logoFidelVouchers"
                />
              }
              name="logoFidelVouchers"
              label="Mostrar Logo Fidel"
              labelPlacement="start"
            />
          </Grid>
          <Grid className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.commander ?? false}
                  onChange={handleChange}
                  name="commander"
                />
              }
              name="commander"
              label="Comandera"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
