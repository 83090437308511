import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import CTextField from "../../../common/CTextField";
import * as accountingAccountService from "../../../api/services/accountingAccount";
import React from "react";
import { useHistory } from "react-router-dom";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function AccountingAccountCreate() {
  const history = useHistory();

  function saveAccountingAccount(e) {
    setIsLoading(true);
    let accountPlanName = e.accountPlanName;
    accountingAccountService
      .saveAccountingAccount(accountPlanName)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Cuenta creada exitosamente");
          history.push("/cuentaContable/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  const initialValuesForm = { accountPlanName: undefined };

  return (
    <Grid className="c-mt-60 c-ml-100">
      <Formik initialValues={initialValuesForm} onSubmit={saveAccountingAccount}>
        {({ values, errors, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={5} xs={5} className="c-mt-20">
                    <CTextField
                      label="Nombre"
                      type="text"
                      fullWidth
                      onChange={handleChange}
                      name="accountPlanName"
                      helperText={<span className="c-font-cancel">{errors["accountPlanName"] ?? null}</span>}
                      value={values.accountPlanName ?? ""}
                      disabled={undefined}
                      readOnly={undefined}
                      multiLine={undefined}
                      rows={undefined}
                      onClick={undefined}
                      error={undefined}
                      placeholder={undefined}
                      maxLength={undefined}
                      AutoComplete={""}
                      InputaLabelProps={undefined}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                <Grid item xs={6} className="c-mt-20">
                  <Button
                    variant="contained"
                    size="small"
                    className="c-btn-fidel-green c-cursor-pointer c-m-5"
                    type="submit"
                  >
                    Guardar
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    className="c-btn-cancel  c-cursor-pointer c-m-5"
                    onClick={() => history.goBack()}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Grid>
  );
}
