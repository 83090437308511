import { API, errorHandling, changeToken } from "./index";
import { RoleEnum } from "../../enums/index";

export async function auth(username: string) {
  let params: string = "grant_type=password";
  params += "&username=" + username;

  return API.post("/token", params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  })
    .then((res) => {
      const data = res.data;
      if (data["isValid"] === "True") {
        let dataByToken = {
          displayName: data["displayName"],
          roles: data["roles"],
          userId: data["userId"],
          userName: data["userName"],
          tokenExp: data["expires_in"],
          dateExp: data[".expires"],
        };
        if (validateRole(dataByToken["roles"])) {
          changeToken(data["access_token"], dataByToken);
          return {
            response: true,
            access_token: data["access_token"],
            decode: new Date(data[".expires"]).getTime(),
            message: "Success"
          };
        }
        else if(dataByToken && dataByToken.roles === RoleEnum.Sistema.Description){
          return { response: true, message: "Success", role: RoleEnum.Sistema.Description };
        } else {
          return { response: false, message: "No tienes acceso al sistema." };
        }
      } else {
        return {
          response: false,
          message: data["message"] ?? "Ocurrió un error.",
        };
      }
    })
    .catch((err) => {
      return errorHandling(err);
    });
}

function validateRole(role: string | string[]) {
  let response = false;
  let allowed = [
    RoleEnum.Admin.Description,
    // RoleEnum.Sistema.Description,
    // RoleEnum.Contabilidad.Description,
    // RoleEnum.Basico.Description,
  ];

  if (Array.isArray(role)) {
    if (allowed.some((r) => role.includes(r))) response = true;
  } else if (allowed.find((x) => x === role)) response = true;

  return response;
}
