import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { MainContext } from "./context/mainContext";
import CToast from "./common/CToast/index";
import "semantic-ui-css/semantic.min.css";
import "../src/App.scss";
import NavbarLogin from "./layout/NavbarLogin";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import IsLoading from "./common/Loader";
import { toggleMessage } from "./utils/events";

export default function App() {
  const [globalState, setGlobalState] = useState({ itemsPerPage: 25 });

  function updateGlobalState(key: number, value: number) {
    setGlobalState({ ...globalState, [key]: value });
  }

  function checkLocalSession() {
    // localStorage.setItem("urlOrigin", window.location.href);
    let response = false;
    let message = "";
    let token = localStorage.getItem("tokenLog");
    let dataByToken: any = localStorage.getItem("dataBytokenLog");
    dataByToken = dataByToken ? JSON.parse(dataByToken) : null;
    if (token) {
      try {
        let dateTokenExp: Date = new Date(dataByToken.dateExp);
        let toDay = new Date();
        if (dateTokenExp <= toDay) {
          message = "Tu sesión expiró.";
          localStorage.removeItem("tokenLog");
          localStorage.removeItem("dataBytokenLog");
        } else {
          response = true;
        }
      } catch (error) {
        message = "Debe iniciar sesión";
      }
    }

    if (message) toggleMessage("error", message);
    return response;
  }

  return (
    //TODO: mejorar renderizado, al actualizarce se renderiza X veces (toggleError, setIsLoading)
    <MainContext.Provider
      value={{
        globalState,
        updateGlobalState,
      }}
    >
      <Router>
        <NavbarLogin isLogged={checkLocalSession()} />
        {checkLocalSession() ? <PrivateRoutes /> : <PublicRoutes url={window.location.href} />}
      </Router>
      <CToast />
      <IsLoading />
    </MainContext.Provider>
  );
}
