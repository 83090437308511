import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import * as newsService from "../../../api/services/news";
import { Button, Grid, TextField } from "@material-ui/core";
import ActiveNewsList from "./activeNewsList";
import SearchIcon from "@material-ui/icons/Search";
import { News } from "../../../api/services/news/news.type";

export default function NewsList() {
  const history = useHistory();

  const [newsActiveList, setNewsActiveList]: any = useState([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    getActiveNews({ search: "", page: 1, pageSize: 20, orderBy: "-Id" });
  }, []);

  useEffect(() => {
    getActiveNews({ search: valueSearch, page: 1, pageSize: 20, orderBy: "-Id" });
  }, [valueSearch]);

  async function getActiveNews(props: { search: string; page: number; pageSize: number; orderBy: any }) {
    setIsLoading(true);
    let { search, page, pageSize, orderBy } = props;
    try {
      let { result, data, message } = await newsService.getNews(search, orderBy, false, page, pageSize);

      if (result && data) {
        let currentPage = page;
        let amount = Math.ceil(data.totalRecords / pageSize);

        let table = data.list.filter((news: News) => {
          return news.titulo.toLowerCase().includes(search);
        });

        setNewsActiveList(table);

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: data.totalRecords,
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const reloadData = (page: number, pageSize: number, orderBy?: string) => {
    getActiveNews({ search: valueSearch ?? "", page, pageSize, orderBy: orderBy ?? "-Id" });
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const changePage = (event, value) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const onClickSearch = (e) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
    reloadData(1, rowsPerPage);
  };

  function deleteNew(id: number) {
    newsService.deleteNew(id);

    reloadData(1, 20);
  }

  function sortTable(orderBy: "+Id" | "-Id") {
    reloadData(paginationData.currentPage, rowsPerPage, orderBy);
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="space-between">
        <a href={"/novedades/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>
        <a href={"/novedades/dadosDeBaja"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-cancel  c-cursor-pointer c-mt-5 c-ml-5"
            onClick={() => {
              history.push("/novedades/dadosDeBaja");
            }}
          >
            Dados de baja
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Buscar..."
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={(e) => {
            onClickSearch(e);
          }}
        >
          Buscar
        </Button>
      </Grid>
      <ActiveNewsList
        history={history}
        paginationData={paginationData}
        changePage={changePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        newsActiveList={newsActiveList}
        deleteNew={deleteNew}
        sortTable={sortTable}
      />
    </div>
  );
}
