import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CustomTooltip from "../../../../common/CTooltip";

type IProps = {
  values;
  handleChange;
  setFieldValue;
  categories: { value: number; text: string }[];
};

export default function BudgetSettingsForm(props: IProps) {
  const { values, handleChange, setFieldValue, categories } = props;

  return (
    <Grid>
      <Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.ivaBudgetSalePrice ?? false}
                      onChange={handleChange}
                      name="ivaBudgetSalePrice"
                    />
                  }
                  name="ivaBudgetSalePrice"
                  label="Venta con IVA"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si tiene asignada alguna Categoría, a los Productos vinculados los trae con Precio con IVA pero al
                      resto de los productos NO. Al procesar el comprobante a Factura, toma el precio como final y no en
                      el Neto. Ej: $ 100 en Presupuesto No Confirmado, lo pasa a Factura en $ 100 Final. Es decir,
                      aplica la regla sólo para el select de Producto
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.confirmBudgetDefault ?? false}
                      onChange={handleChange}
                      name="confirmBudgetDefault"
                    />
                  }
                  name="confirmBudgetDefault"
                  label="Presupuesto Confirmado Por Defecto"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo el nuevo Presupuesto tendra tildado el campo Confirmado por defecto
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
        <Grid container>
          <Grid item md={10} xs={10}>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.netSubtotalInUnconfirmedBudget ?? false}
                  onChange={handleChange}
                  name="netSubtotalInUnconfirmedBudget"
                />
              }
              name="netSubtotalInUnconfirmedBudget"
              label="Reporte con SubTotal Neto? "
              labelPlacement="start"
            />
          </Grid>
          <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
            <CustomTooltip
              title={
                <span className="c-fs-11 ">
                  El Reporte tiene una columna con el Subtotal sin IVA y otra con IVA, por ítem.
                </span>
              }
            >
              <HelpOutlineIcon fontSize="medium" />
            </CustomTooltip>
          </Grid>
        </Grid>
        </Grid>
        <Grid item md={5} xs={5} className="c-mt-20">
          <Grid container>
          {values["netSubtotalInUnconfirmedBudget"] === true && (
            <>
            <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.showSubtotalVATInBudgetNotConfirmed ?? false}
                        onChange={handleChange}
                        name="showSubtotalVATInBudgetNotConfirmed"
                      />
                    }
                    name="showSubtotalVATInBudgetNotConfirmed"
                    label="Mostrar Subtotal/IVA?"
                    labelPlacement="start"
                  />
            </Grid>
            <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        En el Reporte de Cotización que Discrimina IVA, se mostrarán las columnas Subtotal y Subtotal/CIVA
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
            </>
            )}
            <Grid item md={6} xs={6} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            {values["ivaBudgetSalePrice"] === true && (
              <CCustomSelectOrSearch
                disabled={false}
                name="selectedSalesCategories"
                placeHolder="Categorias de ventas"
                onChange={(e: { value: number; text: string }[]) => {
                  let list: number[] = [];
                  e.map((item: { value: number; text: string }) => {
                    return list.push(item.value);
                  });
                  setFieldValue("selectedSalesCategories", e ? list : undefined);
                }}
                dataList={categories}
                isMulti={true}
                defaultValue={categories.filter((e) => {
                  return values.selectedSalesCategories.includes(e.value) ?? e;
                })}
              />
            )}
          </Grid>
          <Grid item md={1} xs={1} />
          <Grid item md={5} xs={5} className="c-mt-20" />
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.ivaBudgetPurchasePrice ?? false}
                  onChange={handleChange}
                  name="ivaBudgetPurchasePrice"
                />
              }
              name="ivaBudgetPurchasePrice"
              label="Compra con IVA"
              labelPlacement="start"
            />
          </Grid>
          <Grid item md={1} xs={1}></Grid>
          <Grid item md={5} xs={5} className="c-mt-20">
            <FormControlLabel
              control={
                <Checkbox
                  style={{ color: "#0063cc" }}
                  color="default"
                  checked={values.budgetData ?? false}
                  onChange={handleChange}
                  name="budgetData"
                />
              }
              name="budgetData"
              label="Datos en Presupuesto"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            {values["ivaBudgetPurchasePrice"] === true && (
              <>
                <CCustomSelectOrSearch
                  disabled={false}
                  name="selectedPurchaseCategories"
                  placeHolder="Categorias de compras"
                  onChange={(e: { value: number; text: string }[]) => {
                    let valuesSelected: number[] = e.map((x) => x.value);
                    setFieldValue("selectedPurchaseCategories", e ? valuesSelected : undefined);
                  }}
                  defaultValue={categories.filter((e) => {
                    return values.selectedPurchaseCategories.includes(e.value) ?? e;
                  })}
                  dataList={categories}
                  isMulti={true}
                />
              </>
            )}
          </Grid>
          <Grid item md={6} xs={6} />
        </Grid>
      </Grid>
    </Grid>
  );
}
