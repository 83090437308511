import { API, errorHandling, generateQueryParams } from "./index";

const BASE_URL: string = "api/admin/accounting-account";
const accountId = null;

export async function getAccountingAccountList(
  search: string,
  orderBy: null | string,
  page: number,
  pageSize: number
) {
  return API.get(BASE_URL + "?accountId=" + accountId + generateQueryParams({
    search,
    orderBy,
    page,
    pageSize,
  }))
    .then((res) => {
      const data = res.data;
      if (data) {
        return {
          result: true,
          data: data,
        };
      } else {
        return {
          result: false,
          message: data["message"] ?? "Ocurrió un error.",
        };
      }
    })
    .catch((err: Error) => {
      return errorHandling(err);
    });
}

export async function editAccountingAccount(body: any, id: number) {
  return API.put(BASE_URL + "/" + id + "?accountPlanName=" + body).then((res: any) => {
    if (res["status"]) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function saveAccountingAccount(body: any) {
  return API.post(BASE_URL + "?accountPlanName=" + body).then((res) => {
    if (res["status"]) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getAccountingAccountById(id: number) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res["status"]) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
