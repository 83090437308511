import { API, errorHandling, generateQueryParams } from "../index";
import { DefaultResponse } from "../SSOClient";
import { City } from "./city.types";

const BASE_URL: string = "/api/cities";
export async function getCities(params?: {
  search?: string,
  orderBy?: null | string,
  page?: number,
  pageSize?: number
}): Promise<DefaultResponse<{ list: City[], totalRecords: number }>> {
  return API.get(BASE_URL + generateQueryParams(params))
    .then(({ data, headers }) => {
      return {
        result: true,
        data: { list: data, totalRecords: headers["total-records"] },
      };
    })
    .catch(errorHandling);
}

export async function saveCities(body) {
  return API.post(BASE_URL, body)
    .then((res) => {
      let data = { response: res.data, result: true };
      return data;
    })
    .catch((err: Error) => {
      return errorHandling(err);
    });
}

export async function getCitiesById(id: number) {
  return API.get(BASE_URL + "/" + id)
    .then((res) => {
      const data = res.data;
      return data;
    })
    .catch((err: Error) => {
      return errorHandling(err);
    });
}

export async function editCity(body: any, id: number) {
  return API.put(BASE_URL + "/" + id, body).then((res: any) => {
    let data = { response: res.data, result: true };
    return data;
  });
}

export async function deleteCity(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    const data = {
      response: res.data,
      result: res.status === 200 ? true : false,
    };
    return data;
  })
}


