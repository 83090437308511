import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Grid,
  Button,
  MenuItem,
  Menu,
  ListItemText,
  ListItemIcon,
  useMediaQuery,
  useTheme,
  Drawer,
  ListItem,
  List,
} from "@material-ui/core";
import shortenText from "../common/CShortText/index";
import CustomTooltip from "../common/CTooltip/index";
import PersonIcon from "@material-ui/icons/Person";
import DescriptionIcon from "@material-ui/icons/Description";
import WorkIcon from "@material-ui/icons/Work";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MailIcon from "@material-ui/icons/Mail";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ComputerIcon from "@material-ui/icons/Computer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import EqualizerTwoToneIcon from "@material-ui/icons/EqualizerTwoTone";
import SettingsIcon from "@material-ui/icons/Settings";
import CloudIcon from "@material-ui/icons/Cloud";
import GroupIcon from "@material-ui/icons/Group";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import fidelLogo from "../img/logo_white_png.png";
import { RoleEnum } from "../enums";
import Badge from "@material-ui/core/Badge";

const NavBarNavigation = ({ history }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState<{ roles: string; displayName: string }>();
  const [dataAccount, setDataAccount] = useState({
    name: "",
  });
  const isMedium = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [optionsHeader, setOptionsHeader] = useState([
    {
      text: "Perfil",
      icon: <PersonIcon fontSize="small" />,
      click: () => logout(),
    },
    {
      text: "Cuenta",
      icon: <ComputerIcon fontSize="small" />,
      click: () => logout(),
    },
    {
      text: "Cerrar Sesión",
      icon: <ExitToAppIcon fontSize="small" />,
      click: () => logout(),
    },
  ]);

  const logout = () => {
    localStorage.removeItem("tokenLog");
    localStorage.removeItem("dataBytokenLog");
    
    window.location.href = `${process.env.REACT_APP_FIDEL_VIEJO}/Home/logOut`;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let dataString = localStorage.getItem("dataBytokenLog");
    let dataToken: { roles: string; displayName: string } = dataString ? JSON.parse(dataString) : null;

    let nameByToken = dataToken.roles !== RoleEnum.Admin.Description ? "Usuario" : "Cuenta";

    let arrayOptionsHeader =
      dataToken.roles === RoleEnum.Admin.Description ? optionsHeader.filter((o) => o.text !== "Perfil") : optionsHeader;

    setOptionsHeader(arrayOptionsHeader);
    setData(dataToken);
    setDataAccount({
      ...dataAccount,
      name: nameByToken,
    });
  }, []);

  const DrawerComponent = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    return (
      <>
        <Drawer anchor="left" onClose={() => setOpenDrawer(false)} open={openDrawer} className="c-menu">
          <List>
            <ListItem divider button>
              <ListItemIcon>
                <PersonIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Clientes"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <WorkIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Ventas"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <WorkIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Vendedores"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <LocalShippingIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Proveedores"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <ShoppingCartIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Compras"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <AccountTreeOutlinedIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Productos/Servicios"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <ListAltOutlinedIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Caja"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <EqualizerTwoToneIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Estadísticas"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <AccountBoxIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Contabilidad"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <SettingsIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Configuraciones"}></ListItemText>
              </ListItemIcon>
            </ListItem>
            <ListItem divider button>
              <ListItemIcon>
                <CloudIcon className="c-m-3 c-cursor-pointer" />
                <ListItemText primary={"Tienda Nube"}></ListItemText>
              </ListItemIcon>
            </ListItem>
          </List>
        </Drawer>
        <MenuIcon className="c-m-5 c-cursor-pointer" onClick={() => setOpenDrawer(!openDrawer)} />
      </>
    );
  };

  const DropdownNavBar = () => {
    return (
      <Menu
        className="c-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
      >
        {optionsHeader.map(({ text, icon, click }) => (
          <MenuItem
            className="c-item-menu-yellow c-background-color-white"
            onClick={() => {
              click();
              setAnchorEl(null);
            }}
            key={text}
          >
            <ListItemIcon>{<>{icon}</>}</ListItemIcon>
            <ListItemText primary={text} />
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <>
      <Toolbar className={isExtraSmall ? "c-m-n-8" : "c-m-n-12"}>
        {data && data.roles !== RoleEnum.Admin.Description ? (
          <>
            {isMedium ? (
              <DrawerComponent></DrawerComponent>
            ) : (
              <>
                <CustomTooltip title={<span className="c-fs-11">Nuevo Cliente</span>}>
                  <PersonIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11 ">Nueva Factura de Cuenta</span>}>
                  <WorkIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Presupuesto</span>}>
                  <DescriptionIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Recibo</span>}>
                  <MenuBookIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Proveedor</span>}>
                  <LocalShippingIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nueva Factura de Compra</span>}>
                  <ShoppingCartIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nueva Orden de Pago</span>}>
                  <ListAltOutlinedIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Producto/Servicio</span>}>
                  <AccountTreeOutlinedIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Movimiento</span>}>
                  <LocalAtmIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Consultar Precio Producto/Servicio</span>}>
                  <AttachMoneyIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nueva Factura de Venta a Consumidor Final</span>}>
                  <AccountBoxIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Nuevo Presupuesto a Consumidor Final</span>}>
                  <AccountBoxOutlinedIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>

                <CustomTooltip title={<span className="c-fs-11">Cerrar Caja</span>}>
                  <CancelPresentationIcon className="c-icon-navbar c-m-5 c-cursor-pointer" />
                </CustomTooltip>
              </>
            )}

            <Grid item xl={12} lg={12} md={8} sm={8} xs={6}>
              <Grid className="c-text-center c-ml-2 c-mr-10">
                <img src={fidelLogo} width="150px"></img>
              </Grid>
            </Grid>

            <NotificationsIcon className=" c-m-5 c-cursor-pointer" />
            <MailIcon className=" c-m-5 c-cursor-pointer" />
            <CustomTooltip title={<span className="c-fs-11">WhatsApp Soporte</span>}>
              <WhatsAppIcon className=" c-m-5 c-cursor-pointer" />
            </CustomTooltip>

            <Grid container justifyContent="flex-end">
              {!isExtraSmall && (
                <div className="c-m-3 c-inline-flex">
                  <Button
                    className="c-navbar-button c-font-white c-background-color-gray-dollar"
                    variant="contained"
                    disableElevation
                  >
                    U$S
                  </Button>
                  <Button className="c-navbar-button c-font-black c-background-color-white" variant="outlined" disabled>
                    110,00
                  </Button>
                </div>
              )}

              <Grid item xl={4} lg={5} md={5} sm={5} xs={isExtraSmall ? 2 : 6}>
                <div className="c-m-3">
                  <ArrowDropDownIcon className="c-item-f-r" onClick={(e) => handleClick(e)}></ArrowDropDownIcon>
                  {!isExtraSmall && (
                    <>
                      <small className="c-item-f-r">{"Usuario "}</small>

                      <br />
                      <span className="c-item-f-r">{shortenText("Demo", 20)}</span>
                    </>
                  )}
                </div>

                <DropdownNavBar />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid container>
              <Grid item xl={5} lg={5} md={4} sm={1}></Grid>
              <Grid item xl={2} lg={2} md={2} sm={5} xs={3} className="c-text-center">
                <img src={fidelLogo} width="150px" className="c-mt-10 c-mb-5"></img>
              </Grid>
              <Grid item xl={3} lg={3} md={3} sm={3} xs={5}>
                {/* <Badge
                  badgeContent={0}
                  showZero
                  color="secondary"
                  name={"value"}
                  className="c-mt-20 c-item-f-r c-ml-10"
                  max={999}
                >
                  <GroupIcon className="c-cursor-pointer" />
                </Badge>
                <Badge
                  badgeContent={0}
                  showZero
                  color="secondary"
                  name={"value"}
                  className="c-mt-20 c-item-f-r c-ml-30"
                  max={999}
                >
                  <EventBusyIcon className="c-cursor-pointer" />
                </Badge> */}
                 <div
                  style={{
                    marginTop: "8px",
                    position: "absolute",
                    right: "185px",
                  }}
                >
                  <Button
                    //variant=""
                    type="button"
                    onClick={() => {
                      window.location.href = `${process.env.REACT_APP_FIDEL_ADMIN}`;
                    }}
                    style={{ backgroundColor: "#fff", color: "#80c47c" }}
                    className="btn btn-outline-light button-icon btn-icons"
                  >
                    Versión Anterior
                  </Button>
                </div>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={3} xs={4}>
                <div className="c-m-5">
                  <ArrowDropDownIcon className="c-item-f-r" onClick={(e) => handleClick(e)}></ArrowDropDownIcon>

                  <small className="c-item-f-r">{dataAccount.name}</small>

                  <br />
                  <span className="c-item-f-r">{shortenText((data && data.displayName) ?? "", 10)}</span>
                </div>
                <DropdownNavBar />
              </Grid>
            </Grid>
          </>
        )}
      </Toolbar>
    </>
  );
};
export default NavBarNavigation;
