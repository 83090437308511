import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import CTextField from "../../../../common/CTextField";
import { BarCodeFormatEnum } from "../../../../enums";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CustomTooltip from "../../../../common/CTooltip";

export default function ProductsAndServicesSettingsForm(props) {
  const { values, handleChange, setFieldValue } = props;

  return (
    <Grid>
      <Grid container justifyContent="space-between" className="c-mt-20">
        <Grid item md={5} xs={5}>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.productServiceConfiguration.allowTotalEdit ?? false}
                      onChange={handleChange}
                      name="productServiceConfiguration.allowTotalEdit"
                    />
                  }
                  name="productServiceConfiguration.allowTotalEdit"
                  label="Habilitar edición de total"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está checkeado se permite editar el total del precio de un Producto/Servicio
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <CCustomSelectOrSearch
              disabled={false}
              name="barCodeFormatId"
              placeHolder="Formato código de Barra" 
              value={BarCodeFormatEnum.find((x: { text: string; value: number }) => {
                return x.value === values.barCodeFormatId;
              })}
              onChange={(e) => {
                setFieldValue("barCodeFormatId", e ? e.value : 0);
              }}
              dataList={BarCodeFormatEnum}
            />
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.productActivateInPriceList ?? false}
                      onChange={handleChange}
                      name="productActivateInPriceList"
                    />
                  }
                  name="productActivateInPriceList"
                  label="Permitir desactivar Productos en Lista de Precio?"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está checkeado se permite editar el total del precio de un Producto/Servicio
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.searchProductBySupplierCode ?? false}
                      onChange={handleChange}
                      name="searchProductBySupplierCode"
                    />
                  }
                  name="searchProductBySupplierCode"
                  label="Buscar Producto por Código de Proveedor"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está checkeado, el select de productos también buscará por Código de Proveedor y lo mostrará
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.qrCodeProducts ?? false}
                      onChange={handleChange}
                      name="qrCodeProducts"
                    />
                  }
                  name="qrCodeProducts"
                  label="Codigo QR en Productos"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className="c-mt-20">
            <Grid container>
              <Grid item md={10} xs={10}>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#0063cc" }}
                      color="default"
                      checked={values.stockTransferWithStock ?? false}
                      onChange={handleChange}
                      name="stockTransferWithStock"
                    />
                  }
                  name="stockTransferWithStock"
                  label="Transferencia de depósito con stock"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      En caso de NO estar activada, se podrá transferir mercadería sin la obligación de que el depósito
                      de origen cuenta con stock
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={1} xs={1} />
        <Grid item md={5} xs={5}>
          <Grid>
            <Grid className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.comex ?? false}
                        onChange={handleChange}
                        name="comex"
                      />
                    }
                    name="comex"
                    label="Comercio Exterior"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip
                    title={<span className="c-fs-11 ">Si esta activo Habilita el menu de Comercio Exterior</span>}
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.discountManuallyStock ?? false}
                        onChange={handleChange}
                        name="discountManuallyStock"
                      />
                    }
                    name="discountManuallyStock"
                    label="Doble Stock?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Si esta Activo aparece el campo stock (en cada producto seleccionado) en ventas, compras y
                        presupuestos, hace el descuento del mismo a partir de ese valor, ignorando el campo Cantidad
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            {values["discountManuallyStock"] === true && (
              <Grid>
                <Grid className="c-mt-20">
                  <CTextField
                    label="Cantidad"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    name="nameQuantity"
                    helperText=""
                    value={values.nameQuantity ?? ""}
                    disabled={undefined}
                    readOnly={undefined}
                    multiLine={undefined}
                    rows={undefined}
                    onClick={undefined}
                    error={undefined}
                    placeholder={undefined}
                    maxLength={undefined}
                    AutoComplete={""}
                    InputaLabelProps={undefined}
                  />
                </Grid>
                <Grid className="c-mt-20">
                  <CTextField
                    label="Stock"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    name="nameStock"
                    helperText=""
                    value={values.nameStock ?? ""}
                    disabled={undefined}
                    readOnly={undefined}
                    multiLine={undefined}
                    rows={undefined}
                    onClick={undefined}
                    error={undefined}
                    placeholder={undefined}
                    maxLength={undefined}
                    AutoComplete={""}
                    InputaLabelProps={undefined}
                  />
                </Grid>
              </Grid>
            )}
            <Grid className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.seeCostInPriceQuery ?? false}
                        onChange={handleChange}
                        name="seeCostInPriceQuery"
                      />
                    }
                    name="seeCostInPriceQuery"
                    label="Ver Costo En Consulta de Precio?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip title={<span className="c-fs-11 ">Ver costo en el modal de consulta de precios</span>}>
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.seeStockByOrder ?? false}
                        onChange={handleChange}
                        name="seeStockByOrder"
                      />
                    }
                    name="seeStockByOrder"
                    label="Ver Cantidad por Pedido?"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Si está activado, el Stock se va descontando en los Pedidos que se vayan cargando
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="c-mt-20">
              <Grid container>
                <Grid item md={10} xs={10}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.saleAlertWhioutStock ?? false}
                        onChange={handleChange}
                        name="saleAlertWhioutStock"
                      />
                    }
                    name="saleAlertWhioutStock"
                    label="Alerta de Venta sin stock"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
                  <CustomTooltip
                    title={
                      <span className="c-fs-11 ">
                        Si esta Activo alertará a la cuenta que está realizando una venta sin stock, de lo contrario no
                        se llevará a cabo dicha acción
                      </span>
                    }
                  >
                    <HelpOutlineIcon fontSize="medium" />
                  </CustomTooltip>
                </Grid>
              </Grid>
            </Grid>
            {values["saleAlertWhioutStock"] === true && (
              <Grid>
                <Grid className="c-mt-20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.quantityAlert ?? false}
                        onChange={handleChange}
                        name="quantityAlert"
                      />
                    }
                    name="quantityAlert"
                    label="Alerta de Cantidad"
                    labelPlacement="start"
                  />
                </Grid>
                <Grid className="c-mt-20">
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#0063cc" }}
                        color="default"
                        checked={values.stockAlert ?? false}
                        onChange={handleChange}
                        name="stockAlert"
                      />
                    }
                    name="stockAlert"
                    label="Alerta de Stock"
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            )}
            <Grid className="c-mt-20">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#0063cc" }}
                    color="default"
                    checked={values.allowBudgetWhitoutStock ?? false}
                    onChange={handleChange}
                    name="allowBudgetWhitoutStock"
                  />
                }
                name="allowBudgetWhitoutStock"
                label="Permitir Facturar/Presupuestar sin Stock?"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item md={10} xs={10}>
          <CTextField
            label="Leyenda debajo de letra en reporte"
            type="text"
            fullWidth
            onChange={handleChange}
            name="voucherReportLegend"
            helperText=""
            value={values.voucherReportLegend ?? ""}
            disabled={undefined}
            readOnly={undefined}
            multiLine={undefined}
            rows={undefined}
            onClick={undefined}
            error={undefined}
            placeholder={undefined}
            maxLength={undefined}
            AutoComplete={""}
            InputaLabelProps={undefined}
          />
        </Grid>
        <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-10">
          <CustomTooltip
            title={
              <span className="c-fs-11 ">
                Esta leyenda aparece debajo de la Letra del comprobante en el reporte. Para todos los comprobantes tipo
                'A' (Factura, Nota de Crédito y Nota de Débito)
              </span>
            }
          >
            <HelpOutlineIcon fontSize="medium" />
          </CustomTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
