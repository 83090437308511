import { Grid, FormControlLabel } from "@material-ui/core";
import React, { useState } from "react";
import CDatePicker from "../../../../common/CDatePicker";
import CreateRow from "./salesSettingList";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CustomTooltip from "../../../../common/CTooltip";

type SaleRow = {
  payCondition: { value: number; text: string };
  byDays: boolean;
  // dayFrom: string;
  // dayTo: string;
  observation?: string;
  priceVariationPercentage: number;
};

export default function SalesSettingsForm(props) {
  const today = Date.now();
  const { values, handleChange, setFieldValue } = props;
  const [newRowType, setNewRowType] = useState<SaleRow[]>([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date(today).toISOString());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  function setObservationBtn(i: any) {
    const rows = [...newRowType];
    rows.map((item, index) => {
      if (index === i) {
        if (item.observation) {
          item.observation = undefined;
        } else {
          item.observation = "";
        }
      }
    });

    setNewRowType(rows);
  }

  return (
    <>
      <Grid>
        <Grid container>
          <Grid item md={5} xs={5} className="c-mt-20">
            <Grid container>
              <Grid item md={7} xs={7}>
                <FormControlLabel
                  control={
                    <CDatePicker value={selectedDate} setValue={handleDateChange} label="Fecha" type="dayWithMonth" />
                  }
                  name="saleConfigurationDateAplication"
                  label=""
                  labelPlacement="start"
                />
              </Grid>
              <Grid item md={1} xs={1} className="c-grid-tooltip-info c-ml-10 c-mt-35">
                <CustomTooltip
                  title={
                    <span className="c-fs-11 ">
                      Si está Activo, cuando se genera un pedido se envia automaticamente un email al cliente
                    </span>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" />
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} xs={1} />
          <Grid item md={5} xs={5} className="c-mt-20" />
        </Grid>
        <CreateRow
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setObservationBtn={setObservationBtn}
        />
      </Grid>
    </>
  );
}
