import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { useState } from "react";
import CDialogDelete from "../../../../common/CDialogDelete";

export default function AliquotsList(props) {
  const { history, dataTable, deleteAliquots } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [dataDelete, setDataDelete]: any = useState({});

  const actionConfirmDelete = () => {
    deleteAliquots(dataDelete.id);
    setOpenDelete(false);
  };

  return (
    <>
      <Paper className="c-mt-20 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Nombre</TableCell>
                    <TableCell className="c-text-center">Activo</TableCell>
                    <TableCell className="c-text-center">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">
                        {item.name}
                      </TableCell>
                      <TableCell className="c-rows-elipsis">
                        {item.dateDeleteOn === null ? (
                          <CheckIcon
                            fontSize="medium"
                            className="c-icon-success"
                          />
                        ) : (
                          <ClearIcon
                            fontSize="medium"
                            className="c-icon-cancel"
                          />
                        )}
                      </TableCell>
                      <TableCell className="c-rows-elipsis">
                        {item.dateDeleteOn === null && (
                          <>
                            <IconButton
                              className="c-grid-btn-green"
                              onClick={() => {
                                history.push("/alicuota/editar/" + item.id);
                              }}
                            >
                              <EditIcon fontSize="medium" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-cancel"
                              onClick={() => {
                                setOpenDelete(true);
                                setDataDelete({
                                  id: item.id,
                                  title: "Baja de alicuota",
                                  content: `¿Está seguro de dar de baja ${item.name}?`,
                                });
                              }}
                            >
                              <BlockIcon fontSize="medium" />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
      <CDialogDelete
        open={openDelete}
        setOpen={setOpenDelete}
        title={dataDelete.title}
        content={dataDelete.content}
        actionConfirm={actionConfirmDelete}
      />
    </>
  );
}
