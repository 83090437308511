import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CTextField from "../../../../common/CTextField";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

import * as cardsService from "../../../../api/services/cards";

export default function CardsCreate() {
  const history = useHistory();
  const [initialValues, setInitialValues]: any = useState();

  useEffect(() => {
    setInitialValues({
      name: undefined,
    });
  }, []);

  function saveCards(filter) {
    let body = {
      name: filter["name"],
    };

    cardsService
      .saveCards(body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Tarjeta creada exitosamente");
          history.push("/tarjetas/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} onSubmit={saveCards}>
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-border-botom-green-ligth">
                    <Grid container spacing={1}>
                      <h3 className="c-text-fidel-green">Nueva tarjeta</h3>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Nombre"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="name"
                          helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                          value={values.name ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
