import React from "react";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import shortenText from "../../../common/CShortText";
import { Pagination } from "@material-ui/lab";
import CDialogDelete from "../../../common/CDialogDelete";

export default function ProvincesList(props) {
  const [openDelete, setOpenDelete] = React.useState(false);
  const [dataDelete, setDataDelete]: any = React.useState({});

  const { history, dataTable, paginationData, changePage, handleChangeRowsPerPage, deleteProvince } = props;

  const actionDeleteProvince = () => {
    deleteProvince(dataDelete.provinceId);
    setOpenDelete(false);
  };

  return (
    <>
      <Paper className="c-mt-20 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Provincia</TableCell>
                    <TableCell className="c-text-center">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">{shortenText(item.name ?? "-", 20)}</TableCell>
                      <TableCell>
                        <div>
                          <div>
                            <IconButton
                              className="c-grid-btn-green"
                              onClick={() => history.push("/provincias/editar/" + item.id)}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              className="c-grid-btn-cancel"
                              onClick={() => {
                                setOpenDelete(true);
                                setDataDelete({
                                  provinceId: item.id,
                                  title: "Eliminar",
                                  content: `¿Está seguro de que quiere anular la provincia de "${item.name}"?`,
                                });
                              }}
                            >
                              <DeleteForeverIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount > 1 ? paginationData.amount : 0}
            shape="rounded"
          />

          <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
      <CDialogDelete
        open={openDelete}
        setOpen={setOpenDelete}
        title={dataDelete.title}
        content={dataDelete.content}
        actionConfirm={actionDeleteProvince}
      />
    </>
  );
}
