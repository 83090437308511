import React from "react";
import { TextField, FormControl } from "@material-ui/core";
import CTextFieldInterface from "./CTextFieldInterfaces/interface";

const CTextField = (props: CTextFieldInterface) => {
  let {
    name,
    label,
    value,
    type,
    disabled,
    readOnly,
    multiLine,
    rows,
    onClick,
    onChange,
    error,
    fullWidth,
    helperText,
    placeholder,
    maxLength,
    AutoComplete,
    InputaLabelProps,
    defaultValue,
  } = props;
  return (
    <FormControl fullWidth={fullWidth ?? true}>
      <TextField
        defaultValue={defaultValue ?? undefined}
        name={name}
        label={label}
        placeholder={placeholder}
        size="small"
        variant="outlined"
        type={type ?? "text"}
        value={value}
        InputProps={{
          readOnly: readOnly ?? false,
        }}
        inputProps={{
          maxLength: maxLength ?? multiLine ? 500 : undefined,
        }}
        autoComplete={AutoComplete ?? ""}
        InputLabelProps={InputaLabelProps ?? (type === "date" ? { shrink: true } : undefined)}
        onClick={onClick}
        onChange={onChange}
        disabled={disabled ?? false}
        multiline={multiLine}
        rows={rows ?? 1}
        error={error ?? false}
        helperText={helperText ?? ""}
      />
    </FormControl>
  );
};
export default CTextField;
