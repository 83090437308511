import createReducer from "../lib";
import { AuthTypes } from "../actions/index";

const initialState = {
  sessionStatus: "WELCOME",
  sessionToken: undefined,
  isLoggedIn: false,
  loading: false,
  id: 0,
  username: "",
  password: "",
  dashboard: undefined,
  language: "en",
  firstName: undefined,
  showChangeLanguageModal: false,
  dateExp: undefined,
  isAdmin: false,
};

export const authReducer = createReducer(initialState, {
  [AuthTypes.SET_API_TOKEN](state:any, action:any) {
    return {
      ...state,
      sessionToken: action.token,
    };
  },

  [AuthTypes.LOGIN_SUCCESS](state:any, action:any) {
    return {
      ...state,
      sessionToken: action.token,
      dateExp: action.decoded,
      isLoggedIn: true,
      sessionStatus: "LOGGED_IN",
    };
  },

  [AuthTypes.TOGGLE_CHANGE_LANGUAGE_MODAL](state:any, action:any) {
    return {
      ...state,
      language: action.payload,
      showChangeLanguageModal: false,
    };
  },
  [AuthTypes.CHANGE_LANGUAGE](state:any, action:any) {
    return {
      ...state,
      language: action.payload,
    };
  },
  [AuthTypes.NO_SESSION](state:any, action:any) {
    return {
      ...state,
      isLoggedIn: false,
      sessionStatus: "WELCOME",
      dateExp: undefined,
    };
  },
  [AuthTypes.VALIDATE_SESSION_SUCCESS](state:any, action:any) {
    return {
      ...state,
      dashboard: action,
      isLoggedIn: true,
      sessionStatus: "LOGGED_IN",
    };
  },
  [AuthTypes.VALIDATE_SESSION_FAILED](state:any, action:any) {
    return {
      ...state,
      dashboard: undefined,
      isLoggedIn: false,
      sessionStatus: "WELCOME",
      sessionToken: "-",
      dateExp: undefined,
    };
  },

  [AuthTypes.LOGIN_REQUEST](state:any, action:any) {
    return {
      ...state,
      loading: true,
      username: action.username,
      password: action.password,
    };
  },
  [AuthTypes.LOGIN_LOADING_ENDED](state:any) {
    return { ...state };
  },
  [AuthTypes.LOGIN_RESPONSE](state:any, action:any) {
    return {
      ...state,
      isLoggedIn: true,
      sessionToken: action.SessionToken,
      firstName: action.WelcomeName,
      loading: false,
    };
  },
  [AuthTypes.LOGIN_FAILED](state:any) {
    return {
      ...state,
      isLoggedIn: false,
    };
  },
  [AuthTypes.LOGOUT](state:any) {
    return {
      ...state,
      sessionStatus: "WELCOME",
      sessionToken: "-",
      isLoggedIn: false,
      dateExp: undefined,
    };
  },
});
