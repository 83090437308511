import { API, errorHandling } from "../index";
import { DefaultResponse } from "../SSOClient";
import { SaleConfiguration } from "./sales.type";

const BASE_URL: string = "/api/sales";

export async function getSalesWhitIIBB(
    accountId: number
): Promise<DefaultResponse<boolean>> {
    return API.get(BASE_URL + "/exist-whit-IIBB", { params: { accountId } }).then(({ data }) => {
        return { result: true, data: data };
    }).catch(errorHandling);
}

export async function getSalesConfigurations(
    accountId: number
): Promise<DefaultResponse<SaleConfiguration[]>> {
    return API.get(BASE_URL + "/configuration", { params: { accountId } }).then(({ data }) => {
        return { result: true, data: data };
    }).catch(errorHandling);
}