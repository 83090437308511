import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../common/CTextField";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import * as provincesService from "../../../api/services/provinces";
import * as Yup from "yup";

export default function ProvincesEdit() {
  const history = useHistory();
  const { id }: any = useParams();

  const [initialValuesForm, setInitialValuesForm]: any = useState(undefined);
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
  });

  useEffect(() => {
    setIsLoading(true);
    getProvince(+id);
  }, []);

  async function getProvince(id: number) {
    setIsLoading(true);
    try {
      let { result, data, message }: any = await provincesService.getProvinceById(id);

      if (result === 200) {
        setInitialValuesForm({
          name: data.name,
        });
      } else {
        toggleMessage("error", message ?? "Provincia no encontrada");
        history.goBack();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function editProvince(e: any) {
    let body = { name: e.name ?? null };
    provincesService
      .editProvince(body, id)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Provincia editada exitosamente");
          history.push("/provincias/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValuesForm} onSubmit={editProvince} filterSchema={filterSchema}>
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item xs={12} className="c-border-botom-green-ligth">
                      <h3 className="c-text-fidel-green">Editar Provincia</h3>
                    </Grid>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Nombre"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="name"
                          helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                          value={values.name ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} className="c-mt-10">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
