import React, { useEffect, useState } from "react";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import * as accountService from "../../../../api/services/Account";
import * as ivaConditionsService from "../../../../api/services/ivaCondition";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import ImportConfigurations from "./importConfigurations";
import { useHistory } from "react-router-dom";
import IvaConditionTable from "./ivaconditionTable";
import ImportAccount from "./importAccount";
import * as yup from "yup";
import * as importService from "../../../../api/services/imports";

export default function ImportClients() {
  const history = useHistory();

  const [accountList, setAccountList]: any = useState([]);
  const [ivaConditionTable, setIvaConditionTable]: any = useState<{ id: number; name: string; nameTable: string }[]>(
    []
  );
  const [selectedFile, setSelectedFile]: any = useState();

  const [initialValues, setInitialValues] = useState({
    accountId: undefined,
    code: undefined,
    name: undefined,
    surname: undefined,
    email: undefined,
    fantasyName: undefined,
    identificationName: undefined,
    domilice: undefined,
    identificationNumber: undefined,
    cp: undefined,
    city: undefined,
    province: undefined,
    phoneArea: undefined,
    phone: undefined,
    mobileArea: undefined,
    mobile: undefined,
    ivaCondition: undefined,
    zone: undefined,
    seller: undefined,
    discount: undefined,
    payCondition: undefined,
    adjustBalanceBudget: undefined,
    adjustBalanceInvoice: undefined,
    deleteTable: undefined,
    ExcelArchive: undefined,
  });

  const filterSchema = yup.object().shape({
    accountId: yup.number().required("Campo Requerido"),
    code: yup.number().notRequired(),
    name: yup.number().notRequired(),
    surname: yup.number().notRequired(),
    email: yup.number().notRequired(),
    fantasyName: yup.number().notRequired(),
    identificationName: yup.number().notRequired(),
    domilice: yup.number().notRequired(),
    identificationNumber: yup.number().notRequired(),
    cp: yup.number().notRequired(),
    city: yup.number().notRequired(),
    province: yup.number().notRequired(),
    phoneArea: yup.number().notRequired(),
    phone: yup.number().notRequired(),
    mobileArea: yup.number().notRequired(),
    mobile: yup.number().notRequired(),
    ivaCondition: yup.number().notRequired(),
    zone: yup.number().notRequired(),
    seller: yup.number().notRequired(),
    discount: yup.number().notRequired(),
    payCondition: yup.number().notRequired(),
    adjustBalanceBudget: yup.number().notRequired(),
    adjustBalanceInvoice: yup.number().notRequired(),
    deleteTable: yup.number().notRequired(),
    expiration: yup.boolean().notRequired(),
    observation: yup.boolean().notRequired(),
    iibb: yup.boolean().notRequired(),
    priceList: yup.boolean().notRequired(),
    deposit: yup.boolean().notRequired(),
    excelArchive: yup.boolean().required("Campo requerido"),
  });

  useEffect(() => {
    setIsLoading(true);
    getAccounts();
    getIvaCondition();
  }, []);

  function handleRowChange(value, index) {
    let ivaConditions = [...ivaConditionTable];
    ivaConditions[index]["nameTable"] = value.target.value;
    setIvaConditionTable(ivaConditions);
  }

  async function getIvaCondition() {
    setIsLoading(true);
    try {
      let { result, data, message }: any = await ivaConditionsService.getIvaCondition("", null, 1, 500);
      if (result === 200) {
        data.map((item) => {
          let ivaCondition = {
            id: item.id,
            name: item.name,
            nameTable: "",
          };
          setIvaConditionTable((prev) => [...prev, ivaCondition]);
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    }
  }

  async function getAccounts() {
    setIsLoading(true);
    try {
      let { data } = await accountService.accountDataList({ search: "", page: 1, pageSize: 500 });
      if (data) {
        let results = data["results"];
        results.map((item) => {
          let account = {
            value: item["id"],
            text: item["name"],
          };

          setAccountList((prev) => [...prev, account]);
        });
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function saveImportClient(filter) {
    let json = JSON.parse(JSON.stringify(filter));
    json.ivaConditionList = ivaConditionTable;

    let body = new FormData();
    body.append("body", JSON.stringify(json));
    body.append("excelArchive", selectedFile);

    importService
      .saveCustomerImport(body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Cliente importado exitosamente");
          history.push("/cuenta/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} filterSchema={filterSchema} onSubmit={saveImportClient}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={5} className="c-mt-20">
                        <Grid item xs={12} className="c-border-botom-green-ligth">
                          <h3 className="c-text-fidel-green">Cuenta</h3>
                        </Grid>
                        <Grid>
                          <ImportAccount
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            accountList={accountList}
                            errors={errors}
                            setSelectedFile={setSelectedFile}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={5} className="c-mt-20">
                        <Grid item xs={12} className="c-border-botom-green-ligth">
                          <h3 className="c-text-fidel-green">Configuración</h3>
                        </Grid>
                        <ImportConfigurations values={values} handleChange={handleChange} errors={errors} />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4} className="c-mt-20"></Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6} className="c-mt-20">
                        <IvaConditionTable ivaConditionTable={ivaConditionTable} handleRowChange={handleRowChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} className="c-mt-20">
                  <Button
                    variant="contained"
                    size="small"
                    className="c-btn-fidel-green c-cursor-pointer c-m-5"
                    type="submit"
                  >
                    Guardar
                  </Button>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
