import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as voucherService from "../../../../api/services/voucher";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import SearchIcon from "@material-ui/icons/Search";
import VoucherList from "./voucherList";
import { useHistory } from "react-router-dom";

type Voucher = {
  dateDeleteOn: any;
  electronicCredit: boolean;
  id: number;
  letter: string;
  name: string;
};

export default function Vouchers() {
  const history = useHistory();

  const [voucherList, setVoucherList] = useState<Voucher[]>([]);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    getVouchers(valueSearch, 1, 50, null);
  }, []);

  async function getVouchers(search: string, page: number, pageSize: number, orderBy: any) {
    setIsLoading(true);
    try {
      let { result, data, message }: { result: boolean; data?: Voucher[]; message?: string } =
        await voucherService.getVoucherTypes(search, page, pageSize, orderBy);
      if (result && data) {
        setVoucherList(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function onClickSearch() {
    getVouchers(valueSearch, 1, 50, null);
  }

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const reloadData = () => {
    getVouchers(valueSearch, 1, 50, null);
  };

  function deleteVoucher(id: number) {
    voucherService.deleteVoucher(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Comprobante eliminado existosamente");
        reloadData();
      } else {
        toggleMessage("error", res["message"] ?? "Ocurrió un error al eliminar el comprobante seleccionado");
      }
    });
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/comprobantes/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Buscar"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch ?? ""}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={(e) => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {voucherList && voucherList.length !== 0 ? (
        <VoucherList dataTable={voucherList} history={history} deleteVoucher={deleteVoucher} />
      ) : (
        <Paper className="c-mt-20 c-mb-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
