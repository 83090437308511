import {
  Grid,
  IconButton,
  InputLabel,
  NativeSelect,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { Pagination } from "@material-ui/lab";

export default function AccountingAccountList(props) {
  const {
    history,
    dataTable,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
  } = props;

  return (
    <>
      <Paper>
        <Grid container spacing={1} justifyContent="center" className="c-mt-10">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Nombre</TableCell>
                    <TableCell className="c-text-center">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">
                        {item.text}
                      </TableCell>
                      <TableCell className="c-rows-elipsis">
                        <IconButton
                          className="c-grid-btn-green"
                          onClick={() =>
                            history.push("/cuentaContable/editar/" + item.id)
                          }
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount > 1 ? paginationData.amount : 0}
            shape="rounded"
          />

          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            style={{ padding: "7px 24px 0px 5px" }}
          >
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
    </>
  );
}
