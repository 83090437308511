import CustomTooltip from "../CTooltip/index";
import React from "react";

const shortenText = (text:string, length:number) => {
  //Función para acortar un texto que exceda del length (parámetro de entrada) y colocarle puntos suspensivos (...).
  if (text && text.length <= length) {
    return <span>{text}</span>;
  } else {
    return (
      <CustomTooltip title={<span className="c-fs-11">{text}</span>}>
        <span>{text && text.substring(0, length) + "..."}</span>
      </CustomTooltip>
    );
  }
};
export default shortenText;
