import { useContext, useEffect, useState } from "react";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { FormHelperText, Grid, IconButton } from "@material-ui/core";
import { Formik } from "formik";
import CTextField from "../../../common/CTextField";
import * as Yup from "yup";
import * as afipService from "../../../api/services/afip";
import * as voucherService from "../../../api/services/voucher";
import SearchIcon from "@mui/icons-material/Search";
import CCustomSelectOrSearch from "../../../common/CCustomSelectOrSearch";

export default function QueriesVoucher(props) {
  const { accountCertificate, salePointList, setResultTrue, setResults } = props;
  const [initialValuesForm, setInitialValuesForm]: any = useState();
  const [voucherList, setVoucherList]: any = useState([]);
  const filterSchema = Yup.object().shape({
    voucherNumber: Yup.string().required("Campo Requerido"),
    voucherType: Yup.number().required("Campo Requerido"),
    // salePoint: Yup.number().required("Campo Requerido"),
  });

  useEffect(() => {
    setIsLoading(true);
    setInitialValuesForm({
      voucherNumber: undefined,
      voucherType: undefined,
      salePoint: undefined,
    });
    getVouchers();
  }, []);

  async function getVouchers() {
    try {
      let { result, data, message } = await voucherService.getVoucherTypesWithoutPage();
      if (result === 200) {
        data.map((item) => {
          setVoucherList((prev) => [...prev, { text: item.name + " " + item.letter, value: item.id }]);
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getInfo(filter) {
    setIsLoading(true);
    const body = {
      accountCertificate: accountCertificate,
      voucherNumber: filter["voucherNumber"],
      voucherType: filter["voucherType"],
      salePoint: filter["salePoint"],
    };
    setResultTrue(true);
    try {
      let { result, data, message } = await afipService.getExist(body);

      if (result === 200) {
        setResults(data);
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {initialValuesForm && (
        <Grid>
          <Formik
            initialValues={initialValuesForm}
            validationSchema={filterSchema}
            onSubmit={getInfo}
            enableReinitialize={true}
          >
            {({ values, errors, setFieldValue, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CCustomSelectOrSearch
                          disabled={false}
                          name="voucherType"
                          placeHolder="Tipo Comprobante"
                          onChange={(e: { value: any }) => {
                            setFieldValue("voucherType", e ? e.value : undefined);
                          }}
                          dataList={voucherList}
                        />
                        {errors["voucherType"] && (
                          <FormHelperText className={errors["voucherType"] ? "c-font-cancel" : ""}>
                            {errors["voucherType"] ?? null}
                          </FormHelperText>
                        )}
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CCustomSelectOrSearch
                          disabled={false}
                          name="Punto de Venta"
                          placeHolder="Tipo de Cuenta"
                          onChange={(e: { value: any }) => {
                            setFieldValue("salePoint", e ? e.value : undefined);
                          }}
                          value={salePointList.find((x: any) => x.value === values.salePoint)}
                          dataList={salePointList}
                        />
                        {errors["salePoint"] && (
                          <FormHelperText className={errors["salePoint"] ? "c-font-cancel" : ""}>
                            {errors["salePoint"] ?? null}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Número Comprobante"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="voucherNumber"
                          helperText={<span className="c-font-cancel">{errors["voucherNumber"] ?? null}</span>}
                          value={values.voucherNumber ?? ""}
                          disabled={false}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                      <Grid item xs={1} className="c-mt-15 c-ml-25">
                        <IconButton className="c-grid-btn-info" type="submit">
                          <SearchIcon fontSize="medium" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
