import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TableContainer,
  TableHead,
  InputLabel,
  NativeSelect,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import LockIcon from "@mui/icons-material/Lock";
import { User } from "../../../../api/services/AdminUsers/adminUsers.types";
import { useState } from "react";
import ChangePass from "./modal/changePass";
import { toggleMessage } from "../../../../utils/events";
import UnblockUser from "./modal/unblockUser";
import * as userService from "../../../../api/services/AdminUsers";
import { redirectFidelSSO } from "../utils";

type IProps = {
  dataTable: User[];
  changePage: (event: any, value: any) => void;
  paginationData: {
    amount: number;
    currentPage: number;
    totalRecords: number;
  };
  handleChangeRowsPerPage: (event: {
    target: {
      value: string | number;
    };
  }) => void;
  history;
  reloadData: (page: number, pageSize: number) => void;
};

export default function AccountUsersList(props: IProps) {
  const { changePage, paginationData, handleChangeRowsPerPage, dataTable, history, reloadData } = props;
  const [openModalChangePass, setOpenModalChangePass] = useState<boolean>(false);
  const [openModalUnblockUser, setOpenModalUnblockUser] = useState<boolean>(false);
  const [user, setUser] = useState<{ id: number; name: string }>({ id: 0, name: "" });

  async function unlockUserAdmin(id) {
    let { result, message } = await userService.unlockUserAdmin(id);
    if (result) {
      toggleMessage("success", "Desbloqueado correctamente");
      setOpenModalUnblockUser(false);
      reloadData(1, 20);
    } else {
      toggleMessage("error", message);
    }
  }

  return (
    <Paper className="c-mt-20 c-grid-results" elevation={3}>
      <Grid container spacing={1} justifyContent="center">
        <Grid item xs={12}>
          <TableContainer>
            <Table className="c-table">
              <TableHead>
                <TableRow>
                  <TableCell className="c-text-center">Nombre</TableCell>
                  <TableCell className="c-text-center">Email</TableCell>
                  <TableCell className="c-text-center">Vendedor</TableCell>
                  <TableCell className="c-text-center">Rol</TableCell>
                  <TableCell className="c-text-center">Ultima Conexión</TableCell>
                  <TableCell className="c-text-center">Habilitado</TableCell>
                  <TableCell className="c-text-center ">Opciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataTable.map((item: any) => (
                  <TableRow key={item.id}>
                    <TableCell className="c-rows-elipsis">{item.name ?? "-"}</TableCell>
                    <TableCell className="c-rows-elipsis">{item.email ?? "-"}</TableCell>
                    <TableCell className="c-mwp-10">
                      {item.blocked === true ? (
                        <CheckIcon className="c-icon-success" />
                      ) : (
                        <ClearIcon className="c-icon-cancel" />
                      )}
                    </TableCell>
                    <TableCell className="c-mwp-10">{item.rol}</TableCell>
                    <TableCell className="c-mwp-10">
                      {item.lastConnection ? (
                        moment(item.lastConnection).format("DD/MM/yyyy hh:mm")
                      ) : (
                        <HorizontalRuleIcon className="c-icon-grey" />
                      )}
                    </TableCell>
                    <TableCell className="c-mwp-10">
                      {item.enabled === true ? (
                        <CheckIcon className="c-icon-success" />
                      ) : (
                        <ClearIcon className="c-icon-cancel" />
                      )}
                    </TableCell>
                    <TableCell>
                      <div>
                        <div>
                          <IconButton
                            className="c-grid-btn-gray"
                            onClick={() => {
                              redirectFidelSSO(item.email);
                            }}
                          >
                            <ArrowForwardIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            className="c-grid-btn-gray"
                            onClick={() => {
                              setOpenModalChangePass(true);
                              setUser({ id: item.id, name: `${item.name} ${item.lastname}` });
                            }}
                          >
                            <VpnKeyIcon fontSize="small" />
                          </IconButton>
                          {item.blocked && (
                            <IconButton
                              className="c-grid-btn-gray"
                              onClick={() => {
                                setOpenModalUnblockUser(true);
                                setUser({ id: item.id, name: `${item.name} ${item.lastname}` });
                              }}
                            >
                              <LockIcon fontSize="small" />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Pagination
          className="c-pagination"
          onChange={changePage}
          page={paginationData.currentPage}
          count={paginationData.amount > 1 ? paginationData.amount : 0}
          shape="rounded"
        />

        <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
          Registros por páginas
        </InputLabel>
        <NativeSelect
          defaultValue={20}
          onChange={handleChangeRowsPerPage}
          inputProps={{
            name: "age",
            id: "uncontrolled-native",
          }}
        >
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
        </NativeSelect>
      </Grid>
      {openModalChangePass && (
        <ChangePass open={openModalChangePass} user={user} handleClose={setOpenModalChangePass} />
      )}
      {openModalUnblockUser && (
        <UnblockUser
          open={openModalUnblockUser}
          user={user}
          handleClose={setOpenModalUnblockUser}
          unlockUserAdmin={unlockUserAdmin}
        />
      )}
    </Paper>
  );
}
