import { Fragment, useState } from "react";
import { ListItem, Drawer, List, ListItemText, ListItemIcon, Divider } from "@material-ui/core";
import { RoleEnum } from "../enums/index";
import "./styles.scss";
import PersonIcon from "@material-ui/icons/Person";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PublicIcon from "@material-ui/icons/Public";
import SettingsIcon from "@material-ui/icons/Settings";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import SearchIcon from "@material-ui/icons/Search";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailIcon from "@material-ui/icons/Mail";
import ErrorIcon from "@material-ui/icons/Error";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Container } from "@material-ui/core";
import { CListItemInterface, sidebarInterface } from "./layoutInterfaces/interface";
import PersonOffIcon from "@mui/icons-material/PersonOff";

const SideBar = ({ history, anchor, userData, Component, props }: sidebarInterface) => {
  const [expanded, setExpanded] = useState("");
  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const CListItem = ({ text, link, icon, divider }: CListItemInterface) => (
    <>
      <a href={link}  rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
        <ListItem
          className="c-list-item"
          button
        >
          <ListItemIcon>{<>{icon}</>}</ListItemIcon>
          <ListItemText>{text}</ListItemText>

        </ListItem>
      </a>

      {divider && <Divider></Divider>}
    </>
  );
  // const CCollapseItem = ({ text, childs, icon }: CCollapseItemInterface) => (
  //   <div className="c-list-collapse">
  //     <ListItem className="c-list-collapse-item">
  //       <ListItemIcon>{<>{icon}</>}</ListItemIcon>
  //       <ListItemText>{text}</ListItemText>
  //       <ExpandMore className="c-cursor-pointer" />
  //     </ListItem>
  //     <Divider></Divider>
  //     <Collapse
  //       className="c-list-collapse-body"
  //       in
  //       timeout="auto"
  //       unmountOnExit
  //     >
  //       <List className="c-list-collapse-body-list" component="div">
  //         {childs.map((child: any, i: any) => (
  //           <CListItem
  //             key={i}
  //             text={child["text"]}
  //             link={child["link"]}
  //             icon={child["icon"]}
  //             disabled={true}
  //           />
  //         ))}
  //       </List>
  //     </Collapse>
  //   </div>
  // );

  const Accordion = styled((props: any) => (
    <MuiAccordion children={""} disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "& .css-z9uefa-MuiButtonBase-root-MuiAccordionSummary-root": {
      minHeight: 0,
      padding: "0px 12px 0px 0px",
      height: 40,
    },
  }));

  const AccordionSummary = styled((props: any) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.8rem" }} />} {...props} />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
  }));

  return (
    <>
      <Container maxWidth={false}>
        <Drawer variant="permanent" className="" anchor="left" open={anchor}>
          <div>
            <List className="c-list">
              <CListItem text="Inicio" link="/inicio" icon={""} />
              {userData["role"] === RoleEnum.Admin.Description && (
                <Fragment>
                  <CListItem text="Cuentas" link="/cuenta/lista" divider icon={<MenuBookIcon fontSize="small" />} />
                  <CListItem
                    text="Cuentas Contables"
                    link="/cuentaContable/lista"
                    divider
                    icon={<PersonIcon fontSize="small" />}
                  />
                  <CListItem
                    text="Motivos de baja"
                    link="/motivosDeBaja/lista"
                    divider
                    icon={<PersonOffIcon fontSize="small" />}
                  />
                  <div>
                    <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <ListItem className="c-list-collapse-item">
                          <ListItemIcon>{<PublicIcon fontSize="small" />}</ListItemIcon>
                          <ListItemText>{"Regiones"}</ListItemText>
                        </ListItem>
                      </AccordionSummary>
                      <CListItem
                        text="Provicias"
                        link="/provincias/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Localidades"
                        link="/localidades/lista"
                        divider
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                    </Accordion>
                  </div>
                  <div>
                    <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <ListItem className="c-list-collapse-item">
                          <ListItemIcon>{<SettingsIcon fontSize="small" />}</ListItemIcon>
                          <ListItemText>{"Configuraciones"}</ListItemText>
                        </ListItem>
                      </AccordionSummary>
                      <CListItem
                        text="Comprobantes"
                        link="/comprobantes/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Condiciones IVA"
                        link="/condicionIva/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Documentos Identificatorios"
                        link="/documentoIdentificatorio/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Alicuotas"
                        link="/alicuota/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Tributos"
                        link="/tributo/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Reportes"
                        link="/"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Bancos"
                        link="/banco/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Tarjetas"
                        link="/tarjetas/lista"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                    </Accordion>
                  </div>
                  <div>
                    <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <ListItem className="c-list-collapse-item">
                          <ListItemIcon>{<AttachFileIcon fontSize="small" />}</ListItemIcon>
                          <ListItemText>{"Importar Datos"}</ListItemText>
                        </ListItem>
                      </AccordionSummary>
                      <CListItem
                        text="Clientes"
                        link="/importarDatos/clientes"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                      <CListItem
                        text="Proveedores"
                        link="/importarDatos/proveedores"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                    </Accordion>
                  </div>
                  <div>
                    <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <ListItem className="c-list-collapse-item">
                          <ListItemIcon>{<SearchIcon fontSize="small" />}</ListItemIcon>
                          <ListItemText>{"Consultas"}</ListItemText>
                        </ListItem>
                      </AccordionSummary>
                      <CListItem
                        text="Consultas de AFIP"
                        link="/consultas/afip"
                        icon={<ChevronRightIcon className="c-ml-10" fontSize="small" />}
                      />
                    </Accordion>
                  </div>
                  <CListItem text="Métodos" link="/" divider icon={<BrightnessHighIcon fontSize="small" />} />
                  <CListItem text="Novedades" link="/novedades/lista" divider icon={<MailIcon fontSize="small" />} />
                  <CListItem
                    text="Notificaciones"
                    link="/notificaciones/lista"
                    divider
                    icon={<ErrorIcon fontSize="small" />}
                  />
                  <CListItem text="Log" link="/log/lista" divider icon={<SearchIcon fontSize="small" />} />
                </Fragment>
              )}
            </List>
          </div>
        </Drawer>

        <div className="c-ml-90">
          <Component props={props} userData={userData} history={history} />
        </div>
      </Container>
    </>
  );
};

export default SideBar;
