import { useEffect} from "react";
import { setIsLoading } from "../../utils/events";

export default function LogOut(props) {
  useEffect(() => {
    clearSession();
  }, []);

  async function clearSession() {
    setIsLoading(true);
    try {
        localStorage.removeItem("tokenLog");
        localStorage.removeItem("dataBytokenLog");
        window.location.href = `${process.env.REACT_APP_SSO_API_URL}/logout`;
    } finally {
      setIsLoading(false);
    }
  }

  return <div></div>;
}
