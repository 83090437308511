import { Button, FormHelperText, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../common/CTextField";
import * as cityService from "../../../api/services/Cities";
import * as Yup from "yup";
import CCustomSelectOrSearch from "../../../common/CCustomSelectOrSearch";
import * as provincesService from "../../../api/services/provinces";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function CitiesEdit(props) {
  const { id }: any = useParams();
  const history = useHistory();

  const [provinceList, setProvinceList]: any = useState([]);
  const [initialValuesForm, setInitialValuesForm] = useState<{ name: string; province: number }>();

  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
    province: Yup.boolean(),
  });

  useEffect(() => {
    getCity();
    getProvincesList();
  }, []);

  function getCity() {
    cityService.getCitiesById(id).then((res) => {
      setInitialValuesForm({
        name: res["name"],
        province: res["provinceId"],
      });
    });
  }

  function getProvincesList() {
    const search = "";
    const orderBy = null;
    const page = 1;
    provincesService.getProvinces({ search, orderBy, page }).then((res) => {
      const provinces = res["data"];
      provinces.map((item) => {
        setProvinceList((prevProvinces) => [...prevProvinces, { value: item.id, text: item.name }]);
      });
    });
  }

  function editCity(e: { name: string; province: number }) {
    let body = {
      name: e.name ?? null,
      provinceId: e.province,
    };

    cityService
      .editCity(body, id)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Localidad creada exitosamente");
          history.push("/localidades/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValuesForm} onSubmit={editCity} filterSchema={filterSchema}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Ciudad"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="name"
                          helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                          value={values.name}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CCustomSelectOrSearch
                        disabled={false}
                        name="province"
                        placeHolder="Provincias"
                        onChange={(e) => {
                          setFieldValue("province", e ? e : undefined);
                        }}
                        value={provinceList.find((i) => {
                          return i.value === values.province;
                        })}
                        dataList={provinceList}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
