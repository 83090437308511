import { Grid, IconButton } from "@material-ui/core";
import { Formik } from "formik";
import CTextField from "../../../common/CTextField";
import * as Yup from "yup";
import SearchIcon from "@mui/icons-material/Search";

export default function QueriesForm(props) {
  const { initialValuesForm, getInfo } = props;
  const filterSchema = Yup.object().shape({
    cuit: Yup.number().required("Campo Requerido"),
  });

  return (
    <>
      {initialValuesForm && (
        <Grid>
          <Formik
            initialValues={initialValuesForm}
            validationSchema={filterSchema}
            onSubmit={getInfo}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Nombre de la Cuenta"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="accountName"
                          helperText={
                            <span className="c-font-cancel">
                              {errors["accountName"] ?? null}
                            </span>
                          }
                          value={values.accountName ?? ""}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Empresa del Certificado"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="certificate"
                          helperText={
                            <span className="c-font-cancel">
                              {errors["certificate"] ?? null}
                            </span>
                          }
                          value={values.certificate ?? ""}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="CUIT"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="cuit"
                          helperText={
                            <span className="c-font-cancel">
                              {errors["cuit"] ?? null}
                            </span>
                          }
                          value={values.cuit ?? ""}
                          disabled={false}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                      <Grid item xs={1} className="c-mt-15 c-ml-25">
                        <IconButton className="c-grid-btn-info" type="submit">
                          <SearchIcon fontSize="medium" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
