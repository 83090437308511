export const eventBus = {
  toastOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  toastDispatch(
    event: keyof typeof EVENTS,
    data: CustomEventInit<{ text: string; type: "warning" | "error" | "info" | "success" }>
  ) {
    let newEvent = new CustomEvent(event, data);
    document.dispatchEvent(newEvent);
  },

  loadOn(event: keyof typeof EVENTS, callback) {
    document.addEventListener(event, callback);
  },
  loadDispatch(event: keyof typeof EVENTS, data: CustomEventInit<{ value: boolean }>) {
    let newEvent = new CustomEvent(event, data);
    document.dispatchEvent(newEvent);
  },
};

const EVENTS = {
  TOGGLE_MESSAGE: "TOGGLE_MESSAGE",
  IS_LOADING: "IS_LOADING",
};

export const toggleMessage = (type: "warning" | "error" | "info" | "success", text) => {
  eventBus.toastDispatch("TOGGLE_MESSAGE", { detail: { text: text, type: type } });
};

export const setIsLoading = (value: boolean) => {
  eventBus.loadDispatch("IS_LOADING", { detail: { value: value } });
};
