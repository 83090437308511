import { Formik } from "formik";
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as voucherService from "../../../../api/services/voucher";
import { useHistory } from "react-router-dom";
import CTextField from "../../../../common/CTextField";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as Yup from "yup";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

export default function VoucherCreate() {
  const history = useHistory();

  const [initialValues, setInitialValues]: any = useState();
  const [AFIPRelation, setAFIPRelation]: any = useState([]);
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    letter: Yup.string().required("Campo requerido"),
    idAFIP: Yup.string().required("Campo requerido"),
    withIva: Yup.boolean().notRequired(),
    ivaDiscriminated: Yup.boolean().notRequired(),
    credit: Yup.boolean().notRequired(),
    electronicCredit: Yup.boolean().notRequired(),
    onlyBuy: Yup.boolean().notRequired(),
    active: Yup.boolean().notRequired(),
  });

  useEffect(() => {
    setInitialValues({
      name: undefined,
      letter: undefined,
      idAFIP: undefined,
      ivaDiscriminated: false,
      withIva: false,
      credit: false,
      electronicCredit: false,
      onlyBuy: false,
      active: false,
    });
    getAfipRelation();
  }, []);

  async function getAfipRelation() {
    setIsLoading(true);

    try {
      const { result, data, message } = await voucherService.getRelationAfip();

      if (result && data) {
        data.map((item) => {
          let type = {
            value: item.id,
            text: item.desc,
          };
          setAFIPRelation((prev) => [...prev, type]);
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function saveVouchers(filter) {
    setIsLoading(true);

    voucherService
      .saveVoucher(filter)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Comprobante creado exitosamente");
          history.push("/comprobantes/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} validationSchema={filterSchema} onSubmit={saveVouchers}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CTextField
                        label="Nombre"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="name"
                        helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                        value={values.name ?? ""}
                        disabled={undefined}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CTextField
                        label="Letra"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="letter"
                        helperText={<span className="c-font-cancel">{errors["letter"] ?? null}</span>}
                        value={values.letter ?? ""}
                        disabled={undefined}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      {values.onlyBuy === true ? (
                        <CTextField
                          label="Código AFIP"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="idAFIP"
                          helperText={<span className="c-font-cancel">{errors["idAFIP"] ?? undefined}</span>}
                          value={values.idAFIP ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      ) : (
                        <>
                          <CCustomSelectOrSearch
                            disabled={false}
                            name="idAFIP"
                            placeHolder="Relación AFIP"
                            onChange={(e: { value: any }) => {
                              setFieldValue("idAFIP", e ? e.value : undefined);
                            }}
                            dataList={AFIPRelation}
                          />
                          {errors["idAFIP"] && (
                            <FormHelperText className={errors["idAFIP"] ? "c-font-cancel" : ""}>
                              {errors["idAFIP"] ?? null}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.withIva ?? false}
                            onChange={handleChange}
                            name="withIva"
                          />
                        }
                        name="withIva"
                        label="Con IVA?"
                        labelPlacement="start"
                      />
                    </Grid>
                    {values.withIva === true ? (
                      <Grid item md={3} xs={3} className="c-mt-20">
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{ color: "#0063cc" }}
                              color="default"
                              checked={values.ivaDiscriminated ?? false}
                              onChange={handleChange}
                              name="ivaDiscriminated"
                            />
                          }
                          name="ivaDiscriminated"
                          label="IVA Discriminado? "
                          labelPlacement="start"
                        />
                      </Grid>
                    ) : (
                      (values.ivaDiscriminated = false)
                    )}
                  </Grid>
                  <Grid container>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.credit ?? false}
                            onChange={handleChange}
                            name="credit"
                          />
                        }
                        name="credit"
                        label="Credito "
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.electronicCredit ?? false}
                            onChange={handleChange}
                            name="electronicCredit"
                          />
                        }
                        name="electronicCredit"
                        label="Crédito Electrónica "
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.active ?? false}
                            onChange={handleChange}
                            name="active"
                          />
                        }
                        name="active"
                        label="Activo "
                        labelPlacement="start"
                      />
                    </Grid>
                    <Grid item md={3} xs={3} className="c-mt-20">
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#0063cc" }}
                            color="default"
                            checked={values.onlyBuy ?? false}
                            onChange={handleChange}
                            name="onlyBuy"
                          />
                        }
                        name="onlyBuy"
                        label="Sólo Compra? "
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
