import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import CTextField from "../../../common/CTextField";
import * as reasonsForUnsubscribeService from "../../../api/services/reasonsForUnsubscribe";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";

export default function ReasonsForUnsubscribeEdit() {
  const history = useHistory();

  const [initialValuesForm, setInitialValuesForm]: any = useState(undefined);
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo Requerido"),
  });
  const { id }: any = useParams();

  useEffect(() => {
    if (id) {
      getReasonsForUnsubscribeById(+id);
    } else {
      setInitialValuesForm({
        name: undefined,
      });
    }
  }, []);

  const getReasonsForUnsubscribeById = (id: number) => {
    setIsLoading(true);
    reasonsForUnsubscribeService
      .getReasonsForUnsubscribeById(id)
      .then((res) => {
        setInitialValuesForm({
          name: res["data"]["name"],
        });
      })
      .catch((err) => {
        toggleMessage("error", err["message"] ?? "Ocurrió un error al obtener los datos de la cuenta");
        history.goBack();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function editReasonsForUnsubscribe(e: any) {
    let name = e ?? null;

    reasonsForUnsubscribeService
      .editReasonsForUnsubscribe(name, id)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Motivo de baja editado exitosamente");
          history.push("/motivosDeBaja/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik
            initialValues={initialValuesForm}
            onSubmit={editReasonsForUnsubscribe}
            validationSchema={filterSchema}
          >
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item xs={12} className="c-border-botom-green-ligth">
                      <h3 className="c-text-fidel-green">Motivos de baja</h3>
                    </Grid>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Nuevo motivo de baja"
                          type="text"
                          fullWidth
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          name="name"
                          helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                          value={values.name ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
