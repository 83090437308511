import { Redirect, Route, Switch } from "react-router-dom";

import Login from "../components/login/login";

export default function PublicRoutes(props) {
  const { url } = props;

  return (
    <Switch>
      <Route exact path={`/login`} component={Login} />
      <Route render={() => <Redirect to={`/login?url=${encodeURIComponent(url)}`} />} />
    </Switch>
  );
}
