import { API, errorHandling, generateQueryParams } from "../index";
import { DefaultResponse } from "../SSOClient";
import { AdminUsers } from "./adminUsers.types";

const BASE_URL: string = "/api/admin/users";

export async function getAccountUsers(
    props: {
        accountId: number,
        orderBy?: null,
        page?: number,
        pageSize?: number,
    }
): Promise<DefaultResponse<AdminUsers>> {
    return API.get(BASE_URL + generateQueryParams(props)).then(({ data }) => {
        return ({
            result: true,
            data: data
        });
    }).catch(errorHandling);
}

export async function changePassword(id: number, Password: string): Promise<DefaultResponse<any>> {
    return API.put(BASE_URL + `/${id}/change-password`, { Password }).then(({ data }) => {
        return ({
            result: true,
            data: data
        });
    }).catch(errorHandling);
}

export async function unlockUserAdmin(id: number): Promise<DefaultResponse<any>> {
    return API.put(BASE_URL + `/${id}/unlock`).then(({ data }) => {
        return ({
            result: true,
            data: data
        });
    }).catch(errorHandling);
}