import { Button, Grid, Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as cardsService from "../../../../api/services/cards";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import CardList from "./cardList";

export default function Cards() {
  const [cardList, setCardList] = useState([]);
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    getCards(1, rowsPerPage);
  }, []);

  async function getCards(page, pageSize) {
    setIsLoading(true);
    try {
      let { result, data, message }: any = await cardsService.getCards();
      if (result === 200) {
        let currentPage = page;
        let count = data["length"];
        let amount = Math.ceil(count / pageSize);
        let indexOfLastPost = currentPage * pageSize;
        let indexOfFirstPost = indexOfLastPost - pageSize;

        let table: any = Object.values(data);
        setCardList(table.slice(indexOfFirstPost, indexOfLastPost));

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function reloadPage(page, pageSize) {
    getCards(page, pageSize);
  }

  function handleChangeRowsPerPage(event: { target: { value: string | number } }) {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadPage(1, event.target.value);
  }

  const changePage = (event, value) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadPage(page, rowsPerPage);
  };

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/tarjetas/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>
      </Grid>
      {cardList && cardList.length !== 0 ? (
        <CardList
          dataTable={cardList}
          history={history}
          paginationData={paginationData}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          changePage={changePage}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
