import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as aliquotsService from "../../../../api/services/aliquotsService";
import SearchIcon from "@material-ui/icons/Search";
import AliquotsList from "./aliquotsList";
import { useHistory } from "react-router-dom";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

export default function Aliquots() {
  const history = useHistory();

  const [aliquotsList, setAliquotsList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    getAliquots(valueSearch, 1, 50, null);
  }, []);

  async function getAliquots(search: string, page: number, pageSize: number, orderBy: any) {
    setIsLoading(true);
    try {
      let { result, data, message } = await aliquotsService.getAliquot({
        search,
        page,
        pageSize,
        orderBy,
      });
      if (result === 200) {
        setAliquotsList(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", "Ocurrió un error al cargar las alicuotas.");
    } finally {
      setIsLoading(false);
    }
  }

  function onClickSearch() {
    getAliquots(valueSearch, 1, 50, null);
  }

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const reloadData = () => {
    getAliquots("", 1, 50, null);
  };

  function deleteAliquots(id) {
    aliquotsService.deleteAliquot(id).then((res) => {
      if (res.result) {
        toggleMessage("success", "Alicuota dada de baja exitosamente");
        reloadData();
      } else {
        toggleMessage("error", res.message ?? "Ocurrió un error al dar de baja la alicuota seleccionada");
      }
    });
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/alicuota/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Buscar"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch ?? ""}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={() => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {aliquotsList && aliquotsList.length !== 0 ? (
        <AliquotsList dataTable={aliquotsList} history={history} deleteAliquots={deleteAliquots} />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
