import { DefaultResponse } from "../SSOClient";
import { API, errorHandling } from "../index";
import { IvaCondition } from "./ivaCondition.type";

const BASE_URL: string = "/api/admin/condition-iva";

export async function getIvaCondition(search, page, pageSize, orderBy) {
  return API.get(BASE_URL, {
    params: {
      search,
      page,
      pageSize,
      orderBy,
    },
  }).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function saveIvaCondition(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getIvaConditionById(id: number): Promise<DefaultResponse<IvaCondition>> {
  return API.get(BASE_URL + "/" + id).then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function editIvaCondition(id, body) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteIvaCondition(id) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
