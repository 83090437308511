import { Button, Grid, Paper, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as identDocumentsService from "../../../../api/services/identDocuments";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import SearchIcon from "@material-ui/icons/Search";
import IdentDocumentsList from "./identDocumentsList";
import { useHistory } from "react-router-dom";

export default function IdentDocuments(props) {
  const history = useHistory();

  const [identDocumentsList, setIdentDocumentsList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    getIdentDocuments(valueSearch, 1, 50, null);
  }, []);

  async function getIdentDocuments(search, page, pageSize, orderBy) {
    setIsLoading(true);

    try {
      let { result, data, message } = await identDocumentsService.getIdentDocument(search, page, pageSize, orderBy);
      if (result === 200) {
        setIdentDocumentsList(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function onClickSearch() {
    getIdentDocuments(valueSearch, 1, 50, null);
  }

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const reloadData = () => {
    getIdentDocuments("", 1, 50, null);
  };

  function deleteIdentDocuments(id) {
    identDocumentsService.deleteIdentDocument(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Documento identificatorio dado de baja exitosamente");
        reloadData();
      } else {
        toggleMessage(
          "error",
          res["message"] ?? "Ocurrió un error al dar de baja el documento identificatorio seleccionado"
        );
      }
    });
  }

  function addIdentDocuments(id) {
    identDocumentsService.addIdentDocument(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Documento identificatorio dado de alta exitosamente");
        setValueSearch("");
        reloadData();
      } else {
        toggleMessage(
          "error",
          res["message"] ?? "Ocurrió un error al dar de alta el documento identificatorio seleccionado"
        );
      }
    });
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/documentoIdentificatorio/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Buscar"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch ?? ""}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={() => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {identDocumentsList && identDocumentsList.length !== 0 ? (
        <IdentDocumentsList
          dataTable={identDocumentsList}
          history={history}
          deleteIdentDocuments={deleteIdentDocuments}
          addIdentDocuments={addIdentDocuments}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
