import React, { useState, useEffect } from "react";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import * as Yup from "yup";
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid } from "@material-ui/core";
import { Formik } from "formik";
import CCustomSelectOrSearch from "../../../common/CCustomSelectOrSearch";
import { colourEnum } from "../../../enums";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../common/CTextField";
import * as notificationService from "../../../api/services/notifications";
import { Notification } from "../../../api/services/notifications/notification.types";

export default function EditNotification() {
  const { id }: any = useParams();
  const history = useHistory();

  const [initialValuesForm, setInitialValuesForm] = useState<Notification>();
  const filterSchema = Yup.object().shape({
    colourEnum: Yup.number().required("Campo Requerido"),
    title: Yup.string().required("Campo Requerido"),
    message: Yup.string().required("Campo Requerido"),
  });

  useEffect(() => {
    getNotifications(id);
  }, []);

  async function getNotifications(id: number) {
    setIsLoading(true);
    try {
      let { result, data, message } = await notificationService.getNotificationById(id);

      if (result && data) {
        setInitialValuesForm(data);
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function editNotification(filter: Notification) {
    setIsLoading(true);
    try {
      let { result, message } = await notificationService.editNotifications(id, filter);
      if (result) {
        toggleMessage("success", "Notificación editada exitosamente");
        history.push("/notificaciones/lista");
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt60 c-ml-100">
          <Formik initialValues={initialValuesForm} validationSchema={filterSchema} onSubmit={editNotification}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1} className="c-mt-50">
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green">Notificaciones</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="c-mt-20">
                        <CCustomSelectOrSearch
                          disabled={false}
                          name="colourEnum"
                          placeHolder="Color"
                          onChange={(e: { value: any }) => {
                            setFieldValue("colourEnum", e ? e.value : undefined);
                          }}
                          value={colourEnum.find((x) => x.value === values.colourEnum)}
                          dataList={colourEnum}
                        />
                        {errors["colourEnum"] && (
                          <FormHelperText className={errors["colourEnum"] ? "c-font-cancel" : ""}>
                            {errors["colourEnum"] ?? null}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="c-mt-20">
                        <CTextField
                          label="Título"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="title"
                          helperText={<span className="c-font-cancel">{errors["title"] ?? null}</span>}
                          value={values.title ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} className="c-mt-20">
                        <CTextField
                          label="Mensaje"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="message"
                          helperText={<span className="c-font-cancel">{errors["message"] ?? null}</span>}
                          value={values.message ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
