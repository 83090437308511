import { SSOAPI } from "./SSOClient";

const BASE_URL: string = `${process.env.REACT_APP_SSO_API_URL}`;

export async function verifyToken(ssoToken: string) {
  return SSOAPI.get(
    BASE_URL + "/verifytoken?ssoToken=" + encodeURIComponent(ssoToken), {
    headers: {
      Authorization: "Bearer l1Q7zkOL59cRqWBkQ12ZiGVW2DBL"
    }
  }
  ).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        token: res.data.token,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText,
      };
    }
  });
}

export async function recoverPassword(url: string, email: string) {
  return SSOAPI.get(
    BASE_URL + "/recover-password?url=" + url + "&email=" + email
  ).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText,
      };
    }
  });
}

export async function passwordChange(url: string, body: any) {
  return SSOAPI.post(BASE_URL + "/password-change?url=" + url, body).then(
    (res) => {
      if (res["status"] === 200) {
        return {
          result: res.status,
          data: res.data,
          message: res.statusText,
        };
      } else {
        return {
          result: res.status,
          message: res.statusText,
        };
      }
    }
  );
}
