import React from "react";
import { Redirect, Route, Switch, useHistory, BrowserRouter as Router } from "react-router-dom";

import { RoleEnum } from "../enums/index";

//componentes
// import Main from "../components/main";
import Account from "../components/Admin/account/account";
import AccountCreate from "../components/Admin/account/accountCreate";
import Sidebar from "../layout/Sidebar";
import AccountEdit from "../components/Admin/account/accountEdit";
import TributesEdit from "../components/Admin/configurationsItems/tributes/tributesEdit";
import TributesCreate from "../components/Admin/configurationsItems/tributes/tributesCreate";
import Tributes from "../components/Admin/configurationsItems/tributes/tributes";
import AccountingAccount from "../components/Admin/accountingAccount/accountingAccount";
import accountingAccountCreate from "../components/Admin/accountingAccount/accountingAccountCreate";
import accountingAccountEdit from "../components/Admin/accountingAccount/accountingAccountEdit";
import ReasonsForUnsubscribe from "../components/Admin/reasonsForUnsubscribe/reasonsForUnsubscribe";
import ReasonsForUnsubscribeCreate from "../components/Admin/reasonsForUnsubscribe/reasonsForUnsubscribeCreate";
import ReasonsForUnsubscribeEdit from "../components/Admin/reasonsForUnsubscribe/reasonsForUnsubscribeEdit";
import Provinces from "../components/Admin/provinces/provinces";
import ProvincesCreate from "../components/Admin/provinces/provincesCreate";
import ProvincesEdit from "../components/Admin/provinces/provincesEdit";
import Cities from "../components/Admin/cities/cities";
import CitiesCreate from "../components/Admin/cities/citiesCreate";
import CitiesEdit from "../components/Admin/cities/citiesEdit";
import AccountSettings from "../components/Admin/account/accountSettings/accountSettings";
import AccountUsers from "../components/Admin/account/accountUsers/accountUsers";
import UnsubscribedAccount from "../components/Admin/account/unsubscribedAccounts/unsubscribedAccount";
import Banks from "../components/Admin/configurationsItems/banks/banks";
import BanksCreate from "../components/Admin/configurationsItems/banks/banksCreate";
import BanksEdit from "../components/Admin/configurationsItems/banks/banksEdit";
import Vouchers from "../components/Admin/configurationsItems/voucher/vouchers";
import VoucherCreate from "../components/Admin/configurationsItems/voucher/voucherCreate";
import VoucherEdit from "../components/Admin/configurationsItems/voucher/voucherEdit";
import IvaConditions from "../components/Admin/configurationsItems/IvaConditions/ivaConditions";
import IvaConditionsCreate from "../components/Admin/configurationsItems/IvaConditions/ivaConditionsCreate";
import IvaConditionsEdit from "../components/Admin/configurationsItems/IvaConditions/ivaConditionsEdit";
import IdentDocuments from "../components/Admin/configurationsItems/identificationDocuments/identDocuments";
import IdentDocumentsCreate from "../components/Admin/configurationsItems/identificationDocuments/identDocumentsCreate";
import IdentDocumentsEdit from "../components/Admin/configurationsItems/identificationDocuments/identDocumentsEdit";
import Aliquots from "../components/Admin/configurationsItems/Aliquots/aliquots";
import AliquotsEdit from "../components/Admin/configurationsItems/Aliquots/aliquotsEdit";
import AliquotsCreate from "../components/Admin/configurationsItems/Aliquots/aliquotsCreate";
import Cards from "../components/Admin/configurationsItems/cards/cards";
import CardsCreate from "../components/Admin/configurationsItems/cards/cardsCreate";
import Notifications from "../components/Admin/notifications/notifications";
import CreateNotification from "../components/Admin/notifications/notificationsCreate";
import EditNotification from "../components/Admin/notifications/notificationsEdit";
import NewsList from "../components/Admin/news/news";
import NewsCreate from "../components/Admin/news/newsCreate";
import NewsEdit from "../components/Admin/news/newsEdit";
import Queries from "../components/Admin/quieries/queries";
import ImportProvider from "../components/Admin/importer/providers/importProvider";
import ImportClients from "../components/Admin/importer/customers/importClients";
import Log from "../components/Admin/logList/log";
import LogDetail from "../components/Admin/logList/logDetail";
import { toggleMessage } from "../utils/events";
import DeletedNewsList from "../components/Admin/news/deletedNewsList";
import LogOut from "../components/logout/logout";

function PrivateRoutes() {
  const token = localStorage.getItem("dataBytokenLog");
  let tokenDecode = token && JSON.parse(token);

  const userData = getUserData();
  const [anchor] = React.useState(true);
  const history = useHistory();

  function getUserData() {
    let tokenDecoded: any = localStorage.getItem("dataBytokenLog");
    tokenDecoded = tokenDecoded ? JSON.parse(tokenDecoded) : null;
    return {
      username: tokenDecoded?.displayName ?? "",
      role: tokenDecoded?.roles ?? "",
      roleName: undefined,
    };
  }
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    // setAnchor(open);
  };

  function checkAuthorized(authorizedRoles: any, withMessage?: string) {
    let response = false;
    if (tokenDecode.roles) {
      if (authorizedRoles.length > 0) {
        if (authorizedRoles.some((x) => tokenDecode.roles.includes(x.Description))) response = true;
        if (!response && withMessage) {
          toggleMessage("warning", "No está autorizado para ingresar.");
        }
      } else response = true;
    }

    return response;
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/inicio" render={() => <Redirect to="/cuenta/lista" />} />

        <Route
          exact
          path="/cuenta/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Account}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />
        <Route
          exact
          path="/cuenta/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/DadosDeBaja"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={UnsubscribedAccount}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/usuario/lista/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountUsers}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/ConfiguracionCuenta/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountSettings}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/DadosDeBaja"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={UnsubscribedAccount}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/usuario/lista/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountUsers}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/ConfiguracionCuenta/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountSettings}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/log/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Log}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/log/ver/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={LogDetail}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/importarDatos/proveedores"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ImportProvider}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/importarDatos/clientes"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ImportClients}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/novedades/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={NewsList}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/novedades/dadosDeBaja"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={DeletedNewsList}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/novedades/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={NewsCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/novedades/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={NewsEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/banco/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Banks}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/banco/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={BanksCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/banco/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={BanksEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/ConfiguracionCuenta/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountSettings}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuenta/ConfiguracionCuenta/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountSettings}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/tarjetas/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Cards}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/tarjetas/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={CardsCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/alicuota/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Aliquots}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/alicuota/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AliquotsEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/alicuota/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AliquotsCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/tributo/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Tributes}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/tributo/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={TributesCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/tributo/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={TributesEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/comprobantes/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Vouchers}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/comprobantes/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={VoucherCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/comprobantes/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={VoucherEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/condicionIva/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IvaConditions}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/condicionIva/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IvaConditionsCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/condicionIva/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IvaConditionsEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuentaContable/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={AccountingAccount}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuentaContable/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={accountingAccountCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/cuentaContable/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={accountingAccountEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/motivosDeBaja/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ReasonsForUnsubscribe}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/motivosDeBaja/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ReasonsForUnsubscribeCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/motivosDeBaja/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ReasonsForUnsubscribeEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/provincias/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Provinces}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/provincias/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ProvincesCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/provincias/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={ProvincesEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/localidades/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Cities}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/localidades/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={CitiesCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/localidades/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={CitiesEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/documentoIdentificatorio/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IdentDocuments}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/documentoIdentificatorio/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IdentDocumentsCreate}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/documentoIdentificatorio/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={IdentDocumentsEdit}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/notificaciones/lista"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Notifications}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/notificaciones/crear"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={CreateNotification}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/notificaciones/editar/:id"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={EditNotification}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/consultas/afip"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={Queries}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route
          exact
          path="/logout"
          render={(props) =>
            checkAuthorized([RoleEnum.Admin]) ? (
              <Sidebar
                history={history}
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                userData={userData}
                Component={LogOut}
                props={props}
              />
            ) : (
              <Route render={() => <Redirect to="/inicio" />} />
            )
          }
        />

        <Route render={() => <Redirect to="/inicio" />} />
      </Switch>
    </Router>
  );
}

export default PrivateRoutes;
