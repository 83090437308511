import { Table, TableContainer, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import CTextField from "../../../../common/CTextField";

export default function IvaConditionTable(props) {
  const { ivaConditionTable, handleRowChange } = props;

  return (
    <>
      <TableContainer>
        <Table className="c-table">
          <TableHead>
            <TableRow>
              <TableCell className="c-text-center">Nombre</TableCell>
              <TableCell className="c-text-center">Columna</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ivaConditionTable.map((item: any, index: number) => (
              <TableRow key={index} id={item.id}>
                <TableCell className="c-rows-elipsis">{item.name}</TableCell>
                <TableCell className="c-rows-elipsis">
                  <CTextField
                    label={item.name}
                    type="text"
                    fullWidth
                    onChange={(value) => {
                      handleRowChange(value, index);
                    }}
                    name="ivaConditionList[index]['nameTable']"
                    helperText=""
                    value={ivaConditionTable[index]["nameTable"] ?? ""}
                  />
                </TableCell>
              </TableRow>
              // )
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
