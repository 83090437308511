import { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { Alert } from "@material-ui/lab";
import { eventBus } from "../../utils/events";

type toastType = {
  text: string;
  type: "warning" | "error" | "info" | "success";
};

function Toast() {
  const [dataToast, setDataToast] = useState<{
    open: boolean;
    text: string;
    type: "warning" | "error" | "info" | "success";
  }>();
  const hasMessages = Array.isArray(true);
  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setDataToast(undefined);
  };

  const styles = {
    message: {
      fontWeight: hasMessages ? 500 : 700,
      fontSize: 14,
    },
  };

  const Message = ({ children }) => <div style={styles.message}>{children}</div>;

  useEffect(() => {
    eventBus.toastOn("TOGGLE_MESSAGE", (event: { detail: toastType }) => {
      setDataToast({
        open: true,
        text: event.detail.text,
        type: event.detail.type,
      });
    });
  }, []);

  return (
    <div>
      {dataToast?.open && (
        <Snackbar autoHideDuration={6000} onClose={handleClose} open>
          <Alert
            elevation={6}
            variant="filled"
            style={{ minWidth: 400 }}
            severity={dataToast.type}
            onClose={handleClose}
          >
            <Message>{dataToast.text}</Message>
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}

export default Toast;
