import { Button, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as notificationsService from "../../../api/services/notifications";
import { Notification } from "../../../api/services/notifications/notification.types";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import ActiveNotificationList from "./activeNotificationsList";
import DeletedNotificationList from "./deletedNotificationsList";

export default function Notifications() {
  const history = useHistory();

  const [activeNotificationList, setActiveNotificationList] = useState<Notification[]>([]);
  const [deletedNotificationList, setDeletedNotificationList] = useState<Notification[]>([]);
  const [activeList, setActiveList] = useState<boolean>(true);
  const [rowsPerPageActive, setRowsPerPageActive] = useState(20);
  const [rowsPerPageDeleted, setRowsPerPageDeleted] = useState(20);
  const [paginationDataActive, setPaginationDataActive] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [paginationDataDeleted, setPaginationDataDeleted] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    getNotifications("", 1, 20, "-Id", false);
    getNotifications("", 1, 20, "-Id", true);
  }, []);

  const reloadData = (page: number, pageSize: number) => {
    getNotifications("", page, pageSize, "-Id", false);
    getNotifications("", page, pageSize, "-Id", true);
  };

  async function getNotifications(search: string, page: number, pageSize: number, orderBy: any, deleted: boolean) {
    setIsLoading(true);
    try {
      let { result, data, message } = await notificationsService.getNotifications(
        search,
        page,
        pageSize,
        orderBy,
        deleted
      );
      if (result && data) {
        let currentPage = page;
        let count = data.totalRecords;
        let amount = data.totalRecords / pageSize;
        let indexOfLastPost = currentPage * pageSize;
        let indexOfFirstPost = indexOfLastPost - pageSize;

        if (!deleted) {
          setActiveNotificationList(data.list.slice(indexOfFirstPost, indexOfLastPost));

          setPaginationDataActive({
            currentPage: currentPage,
            amount: amount,
            totalRecords: count,
          });
        } else {
          setDeletedNotificationList(data.list.slice(indexOfFirstPost, indexOfLastPost));

          setPaginationDataDeleted({
            currentPage: currentPage,
            amount: amount,
            totalRecords: count,
          });
        }
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function editActive(e, id: number) {
    try {
      activeNotificationList.map((item) => {
        if (item.id === id) {
          let body = {
            id: item.id,
            title: item.title,
            message: item.message,
            colourEnum: item.colourEnum,
            active: e.target.checked,
          };
          notificationsService.editNotifications(id, body).then(() => {
            toggleMessage("success", "Notificación editada exitosamente");
            reloadData(1, 20);
          });
        }
      });
    } finally {
      setIsLoading(false);
    }
  }

  function deleteNotification(id: number, value: boolean) {
    notificationsService.deleteNotification(id, value);

    reloadData(1, 20);
  }

  const handleChangeRowsPerPageDeleted = (event: { target: { value: string | number } }) => {
    setRowsPerPageDeleted(+event.target.value);
    setPaginationDataDeleted({ ...paginationDataDeleted, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const changePageDeleted = (event, value) => {
    let page = value;

    setPaginationDataDeleted({
      ...paginationDataDeleted,
      currentPage: value,
    });
    reloadData(page, rowsPerPageDeleted);
  };

  const handleChangeRowsPerPageActive = (event: { target: { value: string | number } }) => {
    setRowsPerPageActive(+event.target.value);
    setPaginationDataActive({ ...paginationDataActive, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const changePageActive = (event, value) => {
    let page = value;

    setPaginationDataActive({
      ...paginationDataActive,
      currentPage: value,
    });
    reloadData(page, rowsPerPageActive);
  };

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="space-between">
        <a href={"/notificaciones/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>

        {activeList === true ? (
          <Button
            variant="contained"
            size="small"
            className="c-btn-cancel  c-cursor-pointer c-mt-5 c-ml-5"
            onClick={() => setActiveList(false)}
          >
            Dados de baja
          </Button>
        ) : (
          <Button
            variant="contained"
            size="small"
            className="c-btn-info  c-cursor-pointer c-mt-5 c-ml-5"
            onClick={() => setActiveList(true)}
          >
            Dados de alta
          </Button>
        )}
      </Grid>
      {activeList == true ? (
        <ActiveNotificationList
          activeNotificationList={activeNotificationList}
          editActive={editActive}
          deleteNotification={deleteNotification}
          paginationData={paginationDataActive}
          changePage={changePageActive}
          handleChangeRowsPerPage={handleChangeRowsPerPageActive}
          reloadData={reloadData}
          rowsPerPageActive={rowsPerPageActive}
        />
      ) : (
        <DeletedNotificationList
          deletedNotificationList={deletedNotificationList}
          editActive={editActive}
          deleteNotification={deleteNotification}
          paginationData={paginationDataDeleted}
          changePage={changePageDeleted}
          handleChangeRowsPerPage={handleChangeRowsPerPageDeleted}
          reloadData={reloadData}
          rowsPerPageDeleted={rowsPerPageDeleted}
        />
      )}
    </div>
  );
}
