import { Formik } from "formik";
import { Button, FormHelperText, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import * as tributesService from "../../../../api/services/tributes";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../../common/CTextField";
import * as Yup from "yup";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";
import * as voucherService from "../../../../api/services/voucher";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

export default function TributesEdit() {
  const { id }: any = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues]: any = useState();
  const [AFIPRelation, setAFIPRelation]: any = useState([]);
  const filterSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    afipId: Yup.string().required("Campo requerido"),
  });

  useEffect(() => {
    getTributes(id);
    getAfipRelation();
  }, []);

  async function getAfipRelation() {
    setIsLoading(true);

    try {
      const { result, data, message }: any = await voucherService.getRelationAfip();

      if (result === 200) {
        data.map((item) => {
          let type: any = {
            value: item["id"],
            text: item["Desc"],
          };
          setAFIPRelation((prev) => [...prev, type]);
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getTributes(id) {
    setIsLoading(true);
    try {
      const { result, data, message }: any = await tributesService.getTributesById(id);
      if (result === 200) {
        setInitialValues({
          name: data["name"],
          value: data["value"],
          afipId: data["afipId"],
          dateDeleteOn: data["dateDeleteOn"] === null ? true : false,
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function editTributes(filter) {
    setIsLoading(true);
    let body = {
      name: filter["name"],
      afipId: filter["afipId"],
    };
    tributesService
      .editTributes(id, body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Tributo editado exitosamente");
          history.push("/tributo/lista");
        }
      })
      .catch((err) => toggleMessage("error", "Ocurrió un error al editar el tributo."))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} validationSchema={filterSchema} onSubmit={editTributes}>
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CTextField
                        label="Nombre"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="name"
                        helperText={<span className="c-font-cancel">{errors["name"] ?? null}</span>}
                        value={values.name ?? ""}
                        disabled={undefined}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item md={5} xs={5} className="c-mt-20">
                      <CCustomSelectOrSearch
                        disabled={false}
                        name="afipId"
                        placeHolder="Relación AFIP"
                        onChange={(e: { value: any }) => {
                          setFieldValue("afipId", e ? e.value : undefined);
                        }}
                        value={AFIPRelation.find((x) => x.value == values.afipId)}
                        dataList={AFIPRelation}
                      />
                      {errors["afipId"] && (
                        <FormHelperText className={errors["afipId"] ? "c-font-cancel" : ""}>
                          {errors["afipId"] ?? null}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
