import {
  Grid,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

export default function CardList(props) {
  const {
    dataTable,
    history,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
  } = props;
  return (
    <>
      <Paper className="c-mt-20 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Nombre</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">
                        {item.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount > 1 ? paginationData.amount : 0}
            shape="rounded"
          />

          <InputLabel
            variant="standard"
            htmlFor="uncontrolled-native"
            style={{ padding: "7px 24px 0px 5px" }}
          >
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
    </>
  );
}
