import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import CTextField from "../../../common/CTextField";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import * as accountingAccountService from "../../../api/services/accountingAccount";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function AccountingAccountEdit() {
  const [initialValuesForm, setInitialValuesForm]: any = useState(undefined);

  const history = useHistory();
  const { id }: any = useParams();
  const filterSchema = Yup.object().shape({
    accountPlanName: Yup.string().required("Campo Requerido"),
  });

  useEffect(() => {
    if (id) {
      getAccountingAccountById(+id);
    } else {
      setInitialValuesForm({
        accountPlanName: undefined,
      });
    }
    setIsLoading(false);
  }, []);

  async function getAccountingAccountById(id: number) {
    setIsLoading(true);
    try {
      let { result, data, message } = await accountingAccountService.getAccountingAccountById(+id);

      if (result === 200) {
        setInitialValuesForm({
          accountPlanName: data["text"],
        });
      } else {
        toggleMessage("error", message ?? "Ocurrio un error al obtener los datos de la cuenta");
        history.goBack();
      }
    } finally {
      setIsLoading(false);
    }
  }

  function saveAccountingAccount(e: any) {
    setIsLoading(true);

    let accountPlanName = e.accountPlanName;

    accountingAccountService
      .editAccountingAccount(accountPlanName, +id)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Cuenta contable editada exitosamente");
          history.goBack();
        }
      })
      .catch((err) => toggleMessage("error", err["message"]))
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValuesForm} onSubmit={saveAccountingAccount} filterSchema={filterSchema}>
            {({ values, errors, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={5} xs={5} className="c-mt-20">
                        <CTextField
                          label="Nombre"
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="accountPlanName"
                          helperText={<span className="c-font-cancel">{errors["accountPlanName"] ?? null}</span>}
                          value={values.accountPlanName ?? ""}
                          disabled={undefined}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6} className="c-mt-20">
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
