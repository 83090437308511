import { DefaultResponse } from "../SSOClient";
import { API, errorHandling } from "../index";
import { News, SaveNew } from "./news.type";

const BASE_URL: string = "/api/news";

export async function getNews(search: string, orderBy: any, deleted: boolean, page: number, pageSize: number): Promise<DefaultResponse<{
  totalRecords: number,
  list: News[],
}>> {
  return API.get(BASE_URL, {
    params: {
      notificationType: "News",
      search: search,
      orderBy,
      deleted,
      page,
      pageSize,
    },
  }).then((res) => {
    return {
      result: true,
      data: {
        totalRecords: res.headers["total-records"],
        list: res.data,
      }
    };
  }).catch(errorHandling);
}
export async function getNewsById(id: number): Promise<DefaultResponse<News>> {
  return API.get(BASE_URL + "/" + id).then(({ data }) => {
    return {
      result: true,
      data
    };
  });
}

export async function saveNews(body: SaveNew): Promise<DefaultResponse<any>> {
  return API.post(BASE_URL, body).then(({ data }) => {
    return {
      result: true,
      data
    };
  }).catch(errorHandling);
}

export async function editNews(id: number, body: SaveNew): Promise<DefaultResponse<any>> {
  return API.put(BASE_URL + "/" + id, body).then(({ data }) => {
    return {
      result: true,
      data
    };
  }).catch(errorHandling);
}

export async function activateNew(id: number) {
  return API.patch(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteNew(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
