import { API, generateQueryParams } from "./index";

const BASE_URL: string = "/api/log";

export async function getLogs(params: any) {
  return API.get(BASE_URL + generateQueryParams(params)).then((res) => {
    if (res.status === 200) {
      return {
        totalResult: res.headers["total-records"],
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText,
      };
    }
  });
}

export async function getLogsById(id: number) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText,
      };
    }
  });
}

export async function deleteLog(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText,
      };
    }
  });
}
