import React from "react";
import { FormHelperText } from "@material-ui/core";
import CustomSearchSelect from "../CCustomSearchSelect/index";
import CustomSelect from "../CCustomSelect/index";

export default function (props: any) {
  const {
    name,
    value,
    placeHolder,
    disabled,
    loading,
    dataList,
    isSearch,
    onLoad,
    onChange,
    className,
    isMulti,
    defaultOpctions,
    defaultValue,
    clearable,
  } = props;

  if (isSearch) {
    return (
      <>
        <CustomSearchSelect
          OnChange={onChange}
          Name={name}
          PlaceHolder={placeHolder}
          LoadOptions={onLoad}
          Disabled={disabled}
          Value={value}
          ClassName={undefined}
          isMulti={isMulti}
          defaultOpctions={defaultOpctions}
          defaultValue={defaultValue}
          Clearable={clearable}
        />
      </>
    );
  } else {
    return (
      <CustomSelect
        Name={name}
        PlaceHolder={placeHolder}
        Options={dataList}
        Disabled={disabled}
        OnChange={onChange}
        ClassName={className ?? ""}
        Value={value}
        Loading={loading}
        isMulti={isMulti}
        defaultValue={defaultValue}
        Clearable={clearable}
      />
    );
  }
}
