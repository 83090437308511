import React, { useEffect, useState } from "react";
import * as authService from "../../api/services/authService";

import { useDispatch } from "react-redux";
import { AuthTypes } from "../../redux/store/actions/index";
import * as loginSSOService from "../../api/services/loginSSOService";
import { useHistory } from "react-router-dom";
import { setIsLoading } from "../../utils/events";

export default function Login(props) {
  const [loginData, setLoginData]: any = useState({});
  const dispatch = useDispatch();
  let search = window.location.search;
  const urlParams = new URLSearchParams(search);
  let ssoToken: string | null = urlParams.get("ssoToken");
  let url: string | null = urlParams.get("url");

  useEffect(() => {
    checkToken();
  }, []);

  async function checkToken() {
    setIsLoading(true);
    try {
      if (!url && !ssoToken) {
        window.location.href = `${process.env.REACT_APP_SSO_API_URL}/login?serviceURL=${process.env.REACT_APP_DEFAULT_REDIRECT}&validationPath=${process.env.REACT_APP_VALIDATION_PATH}`;
      } else if (!ssoToken && url) {
        window.location.href = `${process.env.REACT_APP_SSO_API_URL}/login?serviceURL=${url}&validationPath=${process.env.REACT_APP_VALIDATION_PATH}`;
      } else if (ssoToken && url) {
        let { result, token }: any = await loginSSOService.verifyToken(ssoToken);
        if (result === 200) {
          setLoginData({
            token,
            url,
          });
        }
        let { response, access_token, decode, role }: any = await authService.auth(token);
        if (response == true) {
          if(role){
            window.location.href = process.env.REACT_APP_FIDEL_REACT!;
          }else{
            dispatch({
              type: AuthTypes.LOGIN_SUCCESS,
              token: access_token,
              decoded: decode,
            });
            window.location.href = url;
          }
        } else {
          window.location.href = `${process.env.REACT_APP_FIDEL_SISTEMA_FRONT}/login?url=${encodeURIComponent(
            process.env.REACT_APP_FIDEL_SISTEMA_FRONT ?? ""
          )}&ssoToken=${encodeURIComponent(ssoToken)}/${encodeURIComponent(
            process.env.REACT_APP_VALIDATION_PATH ?? ""
          )}`;
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  return <div></div>;
}
