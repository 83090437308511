import { API, generateQueryParams } from "./index";

const BASE_URL: string = "/api/provinces";

export async function getProvinces(
  params: {
    page?: number,
    pageSize?: number
    search?: string,
    orderBy?: null | string,
  }
) {
  return API.get(BASE_URL + generateQueryParams(params))
    .then((res) => {
      if (res["status"] === 200) {
        return {
          result: res.status,
          data: res.data,
          message: res.statusText
        }
      } else {
        return {
          result: res.status,
          message: res.statusText ?? "Ocurrió un error.",
        };
      }
    })
}

export async function saveProvince(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText
      }
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  })
}

export async function editProvince(body: any, id: number) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText
      }
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  })
}

export async function getProvinceById(id: number) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText
      }
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  })
}

export async function deleteProvince(id: number) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText
      }
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  })
}
