import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Checkbox,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import { colourEnum } from "../../../enums";
import React from "react";
import CDialogDelete from "../../../common/CDialogDelete";
import AddIcon from "@mui/icons-material/Add";
import Pagination from "@material-ui/lab/Pagination";
import { Notification } from "../../../api/services/notifications/notification.types";

type IProps = {
  deletedNotificationList: Notification[];
  editActive(e: any, id: number): void;
  deleteNotification(id: number, value: boolean): void;
  paginationData: {
    amount: number;
    currentPage: number;
    totalRecords: number;
  };
  changePage: (event: any, value: any) => void;
  handleChangeRowsPerPage: (event: {
    target: {
      value: string | number;
    };
  }) => void;
  reloadData: (page: number, pageSize: number) => void;
  rowsPerPageDeleted: number;
};

export default function DeletedNotificationList(props: IProps) {
  const {
    deletedNotificationList,
    editActive,
    deleteNotification,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
    rowsPerPageDeleted,
    reloadData,
  } = props;
  const [openActive, setOpenActive] = React.useState(false);
  const [dataActive, setDataActive]: any = React.useState({});

  const actionConfirm = () => {
    deleteNotification(dataActive.id, false);
    setOpenActive(false);
    reloadData(paginationData.currentPage, rowsPerPageDeleted);
  };

  return (
    <>
      {deletedNotificationList && deletedNotificationList.length !== 0 ? (
        <>
          <Paper className="c-mt-20 c-grid-results" elevation={3}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="c-table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="c-text-center">Título</TableCell>
                        <TableCell className="c-text-center">Mensaje</TableCell>
                        <TableCell className="c-text-center">Color</TableCell>
                        <TableCell className="c-text-center">Activo</TableCell>
                        <TableCell className="c-text-center">Opciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {deletedNotificationList.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell className="c-rows-elipsis">{item.title}</TableCell>
                          <TableCell className="c-rows-elipsis">{item.message}</TableCell>
                          <TableCell className="c-rows-elipsis">
                            {colourEnum.map((e) => {
                              if (e.value === item.colourEnum) {
                                {
                                  return e.text;
                                }
                              }
                            })}
                          </TableCell>
                          <TableCell className="c-rows-elipsis">
                            <Checkbox
                              style={{ color: "#0063cc" }}
                              checked={item.active}
                              onChange={(e) => {
                                editActive(e, item.id);
                              }}
                              name="active"
                            />
                          </TableCell>
                          <TableCell className="c-rows-elipsis">
                            <div>
                              <IconButton
                                className="c-grid-btn-green"
                                onClick={() => {
                                  setOpenActive(true);
                                  setDataActive({
                                    id: item.id,
                                    title: "Dar de alta",
                                    content: `¿Está seguro de dar de alta la notificación?`,
                                  });
                                }}
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Pagination
                className="c-pagination"
                onChange={changePage}
                page={paginationData.currentPage}
                count={paginationData.amount > 1 ? paginationData.amount : 0}
                shape="rounded"
              />
              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
                Registros por páginas
              </InputLabel>
              <NativeSelect
                defaultValue={20}
                onChange={handleChangeRowsPerPage}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </NativeSelect>
            </Grid>
          </Paper>
          <CDialogDelete
            open={openActive}
            setOpen={setOpenActive}
            title={dataActive.title}
            content={dataActive.content}
            actionConfirm={actionConfirm}
          />
        </>
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
