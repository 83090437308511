import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as banksService from "../../../../api/services/banks";
import { Bank } from "../../../../api/services/banks/banks.types";
import CTextField from "../../../../common/CTextField";
import { setIsLoading, toggleMessage } from "../../../../utils/events";

export default function BanksEdit(props) {
  const { id }: any = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState<Bank>();

  useEffect(() => {
    getBank(+id);
  }, []);

  async function getBank(id) {
    setIsLoading(true);
    try {
      let { result, data, message } = await banksService.getBankById(id);

      if (result && data) {
        setInitialValues(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  function editBank(filter: Bank) {
    let body = {
      name: filter["name"],
    };

    banksService
      .editBanks(id, body)
      .then((res) => {
        if (res.result) {
          toggleMessage("success", "Banco creado exitosamente");
          history.push("/banco/lista");
        }
      })
      .catch((err) => toggleMessage("error", err["message"]));
  }

  return (
    <>
      {initialValues && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik initialValues={initialValues} onSubmit={editBank}>
            {({ values, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-border-botom-green-ligth">
                    <h3 className="c-text-fidel-green">Banco</h3>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid container>
                    <Grid item xs={4}>
                      <CTextField
                        label="Nombre"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="name"
                        helperText=""
                        value={values.name ?? ""}
                        disabled={undefined}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={4}>
                      <CTextField
                        label="Código"
                        type="text"
                        fullWidth
                        onChange={handleChange}
                        name="name"
                        helperText=""
                        value={values.formattedCode ?? ""}
                        disabled={true}
                        readOnly={undefined}
                        multiLine={undefined}
                        rows={undefined}
                        onClick={undefined}
                        error={undefined}
                        placeholder={undefined}
                        maxLength={undefined}
                        AutoComplete={""}
                        InputaLabelProps={undefined}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                  <Grid item xs={6} className="c-mt-20">
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-fidel-green c-cursor-pointer c-m-5"
                      type="submit"
                    >
                      Guardar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-cancel c-cursor-pointer c-m-5"
                      onClick={() => history.goBack()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
