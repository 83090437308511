import React, { useEffect, useState } from "react";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { Grid, Button, FormHelperText } from "@material-ui/core";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import CTextField from "../../../common/CTextField";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as newsService from "../../../api/services/news";
import { SaveNew } from "../../../api/services/news/news.type";

export default function NewsEdit() {
  const { id }: any = useParams();
  const history = useHistory();

  const [initialValuesForm, setInitialValuesForm] = useState<SaveNew>();

  const filterSchema = Yup.object().shape({
    texto: Yup.string().required("Campo requerido"),
    titulo: Yup.string().required("Campo requerido"),
    urlYoutube: Yup.string().url("La URL no es válida").nullable(),
    urlImagen: Yup.string().url("La URL no es válida").nullable(),
  });

  useEffect(() => {
    getNew(id);
  }, []);

  async function getNew(id: number) {
    setIsLoading(true);
    try {
      let { result, data, message } = await newsService.getNewsById(id);
      if (result && data) {
        setInitialValuesForm(data);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function editNews(filter) {
    setIsLoading(true);
    let body = filter;
    try {
      let { result, message } = await newsService.editNews(id, body);

      if (result) {
        toggleMessage("success", "Novedad editada exitosamente");
        history.push("/novedades/lista");
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {initialValuesForm && (
        <Grid className="c-mt-60 c-ml-100">
          <Formik
            initialValues={initialValuesForm}
            validateOnChange={false}
            validationSchema={filterSchema}
            onSubmit={editNews}
          >
            {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth">
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          <h3 className="c-text-fidel-green">Novedades</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
                        <Grid item md={6} xs={6} className="c-mt-20">
                          <CTextField
                            label="Título"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                            name="titulo"
                            helperText={<span className="c-font-cancel">{errors["titulo"] ?? null}</span>}
                            value={values.titulo ?? ""}
                            disabled={undefined}
                            readOnly={undefined}
                            multiLine={undefined}
                            rows={undefined}
                            onClick={undefined}
                            error={undefined}
                            placeholder={undefined}
                            maxLength={undefined}
                            AutoComplete={""}
                            InputaLabelProps={undefined}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
                        <Grid item md={6} xs={6} className="c-mt-20">
                          <CTextField
                            label="URL YouTube"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                            name="urlYoutube"
                            helperText={<span className="c-font-cancel">{errors["urlYoutube"] ?? null}</span>}
                            value={values.urlYoutube ?? ""}
                            disabled={undefined}
                            readOnly={undefined}
                            multiLine={undefined}
                            rows={undefined}
                            onClick={undefined}
                            error={undefined}
                            placeholder={undefined}
                            maxLength={undefined}
                            AutoComplete={""}
                            InputaLabelProps={undefined}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
                        <Grid item md={6} xs={6} className="c-mt-20">
                          <CTextField
                            label="URL Instagram"
                            type="text"
                            fullWidth
                            onChange={handleChange}
                            name="urlImagen"
                            helperText={<span className="c-font-cancel">{errors["urlImagen"] ?? null}</span>}
                            value={values.urlImagen ?? ""}
                            disabled={undefined}
                            readOnly={undefined}
                            multiLine={undefined}
                            rows={undefined}
                            onClick={undefined}
                            error={undefined}
                            placeholder={undefined}
                            maxLength={undefined}
                            AutoComplete={""}
                            InputaLabelProps={undefined}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item md={1} xs={1} className="c-mt-20"></Grid>
                        <Grid item md={10} xs={10} className="c-mt-20">
                          <CKEditor
                            id="texto"
                            name="texto"
                            editor={ClassicEditor}
                            data={values.texto ?? ""}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setFieldValue("texto", data);
                            }}
                          />
                          {errors["texto"] && (
                            <FormHelperText className={errors["texto"] ? "c-font-cancel" : ""}>
                              {errors["texto"] ?? null}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-fidel-green c-cursor-pointer c-m-5"
                        type="submit"
                      >
                        Guardar
                      </Button>

                      <Button
                        variant="contained"
                        size="small"
                        className="c-btn-cancel  c-cursor-pointer c-m-5"
                        onClick={() => history.goBack()}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
}
