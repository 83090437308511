import { Checkbox, FormControlLabel, Grid, Paper } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";

import LogList from "./logList";
import * as logService from "../../../api/services/logService";
import CCustomSelectOrSearch from "../../../common/CCustomSelectOrSearch";
import { LogTypeEnum } from "../../../enums";
import Button from "@mui/material/Button";
import CDatePicker from "../../../common/CDatePicker";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function Log() {
  const today = new Date();
  const [logList, setLogList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedDateFrom, setSelectedDateFrom]: any = useState(today);
  const [selectedDateTo, setSelectedDateTo]: any = useState(today);
  const [all, setAll] = useState(false);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    getList({ page: 1, pageSize: rowsPerPage, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  }, []);

  async function getList(params) {
    setIsLoading(true);
    try {
      let { page } = params;
      let { result, totalResult, data, message } = await logService.getLogs(params);

      if (result === 200) {
        let currentPage = page;
        let count = totalResult;
        let amount = count / rowsPerPage;

        setLogList(data);

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const changePage = (event, value) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });

    getList({ page: page, pageSize: rowsPerPage, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  };

  const handleChangeRowsPerPage = (event: {
    target: {
      value: string | number;
    };
  }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    getList({ page: 1, pageSize: rowsPerPage, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  };

  function handleType(e) {
    let typeId = e;

    getList({ page: 1, pageSize: rowsPerPage, typeId, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  }

  const deleteLog = (id: number) => {
    let { result, message }: any = logService.deleteLog(id);
    if (result === 200) {
      getList({ page: 1, pageSize: rowsPerPage, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
    } else {
      toggleMessage("error", message);
    }
  };

  const handleDate = (date, index) => {
    let formatedDate = new Date(date).toISOString();
    if (index === 1) {
      setSelectedDateFrom(formatedDate);
    } else {
      setSelectedDateTo(formatedDate);
    }
  };

  function handleCheck(event: { target: { checked: any } }) {
    setAll(event.target.checked);
    getList({ page: 1, pageSize: rowsPerPage, dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  }

  function searchDate() {
    getList({ page: 1, dateFrom: selectedDateFrom, dateTo: selectedDateTo, pageSize: rowsPerPage });
  }

  return (
    <div className="c-mt-60 c-ml-100">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-border-botom-green-ligth">
              <h3 className="c-text-fidel-green">Buscar</h3>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <Paper className="c-grid-results" elevation={3}>
                <Grid container>
                  <Grid item md={5} xs={5}>
                    <Grid container>
                      <CDatePicker
                        value={selectedDateFrom}
                        setValue={(date) => {
                          handleDate(date, 1);
                        }}
                        label="Fecha desde"
                        type="dateFrom"
                        disabled={all}
                      />
                      <CDatePicker
                        value={selectedDateTo}
                        setValue={(date) => {
                          handleDate(date, 2);
                        }}
                        label="Fecha hasta"
                        type="dateTo"
                        disabled={all}
                      />
                      <Grid item md={1} xs={1} className="c-mt-20">
                        <Button
                          variant="contained"
                          size="small"
                          className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
                          onClick={searchDate}
                        >
                          Aplicar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={3} xs={3} className="c-mt-20">
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "#0063cc" }}
                          color="default"
                          checked={all}
                          onChange={handleCheck}
                          name="all"
                        />
                      }
                      name="all"
                      label="Todo? "
                      labelPlacement="start"
                    />
                  </Grid>
                  <Grid item md={4} xs={4} className="c-mt-20">
                    <CCustomSelectOrSearch
                      disabled={false}
                      name="typeId"
                      placeHolder="Tipo de Cuenta"
                      onChange={(e: { value: any }) => {
                        handleType(e ? e.value : undefined);
                      }}
                      dataList={LogTypeEnum}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LogList
        logList={logList}
        changePage={changePage}
        paginationData={paginationData}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={rowsPerPage}
        deleteLog={deleteLog}
      />
    </div>
  );
}
