import * as accountingAccountService from "../../../api/services/accountingAccount";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Paper, TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import AccountingAccountList from "./accountingAccountList";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function AccountingAccount(history: any) {
  const [valueSearch, setValueSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataTable, setDataTable] = useState([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  history = useHistory();

  useEffect(() => {
    accountingAccountList("", null, 1, rowsPerPage);
  }, []);

  const reloadData = (page: number, pageSize: number) => {
    accountingAccountList(valueSearch ?? "", null, page, pageSize);
  };

  async function accountingAccountList(search: string, orderBy: null, page: number, pageSize: number) {
    setIsLoading(true);

    await accountingAccountService
      .getAccountingAccountList(search, orderBy, page, pageSize)
      .then((res) => {
        if (res["result"]) {
          let currentPage = 1;
          let count = res["length"];
          let amount = 1;

          setDataTable(res["data"]);

          setPaginationData({
            currentPage: currentPage,
            amount: amount,
            totalRecords: count,
          });
        } else {
          toggleMessage("error", res["message"]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const changePage = (value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  return (
    <div className="c-mt-60 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/cuentaContable/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>

      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <AccountingAccountList
          dataTable={dataTable}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
