import { API } from "./index";

const BASE_URL: string = "/api/tributes";

export async function getTributes(search, page, pageSize, orderBy) {
  return API.get(BASE_URL, {
    params: {
      search,
      page,
      pageSize,
      orderBy,
    },
  }).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function saveTributes(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getTributesById(id) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function editTributes(id, body) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteTributes(id) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function addTributes(id) {
  return API.patch(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
