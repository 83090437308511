import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as accountService from "../../../../api/services/Account";
import * as categoriesService from "../../../../api/services/categories";
import * as salesService from "../../../../api/services/sales";
import { Grid, Button } from "@material-ui/core";
import { Formik } from "formik";
import AccountSettingsForm from "./accountSettingsForm";
import VoucherSettingsForm from "./voucherSettingsForm";
import BudgetSettingsForm from "./budgetSettingsForm";
import OrdersSettingsForm from "./ordersSettingsForm";
import ProductsAndServicesSettingsForm from "./productsAndServicesSettings";
import SalesSettingsForm from "./salesSettingsForm";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import { PutConfigAccount } from "../../../../api/services/Account/account.types";
import { Categories } from "../../../../api/services/categories/category.types";
import {
  getCategories,
  getConfig,
  getOrderConfiguration,
  getProductServicesConfiguration,
  getSalesConfigurations,
  getSalesPoints,
} from "./controller";

export default function AccountSettings() {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [initialValuesForm, setInitialValuesForm] = useState<PutConfigAccount>();
  const [accountName, setAccountName] = useState<string>("");
  const [categories, setCategories] = useState<{ value: number; text: string }[]>();
  const [existIIBB, setExistIIBB] = useState<boolean>();
  const [salePoints, setSalePoints] = useState<{ value: string; text: string }[]>();
  const [userEmail, setUserEmail] = useState<string>();
  useEffect(() => {
    setIsLoading(true);
    getEmail(+id);
    Promise.all([getAccountByIdAdmin(+id), salesService.getSalesWhitIIBB(+id), categoriesService.getCategories(+id)])
      .then((res) => {
        if (res[1].data) {
          setExistIIBB(res[1].data);
        }
        if (res[2].data) {
          let list: { value: number; text: string }[] = [];
          res[2].data.map((item: Categories) => {
            return list.push({ value: item.id, text: item.name });
          });
          setCategories(list);
        }
      })
      .catch(() => {
        toggleMessage("error", "Ocurrió un error");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  async function getEmail(id: number) {
    await accountService.getAccountById(id).then(({ result, data, message }) => {
      if (result && data) {
        setUserEmail(data.email);
      } else {
        toggleMessage("error", message);
      }
    });
  }

  async function getAccountByIdAdmin(id: number) {
    let account = await accountService.getAccountById(id);
    if (account.data) {
      let name = account.data.name + (account.data.company ? ` (${account.data.company})` : "");
      setAccountName(name);
      //Inicialización de los valores del formulario
      let promise = await Promise.all([
        getConfig(account.data.accountConfigurationId ?? 0),
        getProductServicesConfiguration(account.data.id),
        getOrderConfiguration(account.data.orderConfigurationId),
        getSalesConfigurations(id),
        getCategories(id),       
        getSalesPoints(account.data.whitoutAFIPCertificate, account.data.certificates),
      ]);
      
      if (promise[0] && promise[1] && promise[2] && promise[3] && promise[4] && promise[5]) {
        let body: PutConfigAccount = {
          id: id,
          ...promise[0],
          productServiceConfiguration: promise[1],
          ...promise[2],
          ...promise[3],
          selectedSalesCategories: promise[4].selectedSalesCategories,
          selectedPurchaseCategories: promise[4].selectedPurchaseCategories,
          selectedSalePoint: promise[5].selectedSalePoint
        };
        
        setSalePoints(promise[5].salePointsList);
        setInitialValuesForm(body);
      }
    } else {
      toggleMessage("error", "Ocurrió un error");
    }
  }

  async function saveSettings(filter: PutConfigAccount, type: "apply" | "exit" | "login") {
    setIsLoading(true);
    let body = {
      id: filter.id,
      retentionAgent: filter.retentionAgent,
      ecommerce: filter.ecommerce,
      ivaWebOrders: filter.ecommerce === true ? filter.ivaWebOrders : false,
      allowBudgetWhitoutStock: filter.allowBudgetWhitoutStock,
      applyAutoBalanceInPurchaseCreditNoteAndReturn: filter.applyAutoBalanceInPurchaseCreditNoteAndReturn,
      applyAutoBalanceInPurchaseInvoiceAndBudget: filter.applyAutoBalanceInPurchaseInvoiceAndBudget,
      applyAutoBalanceOnSaleCreditNoteAndReturn: filter.applyAutoBalanceOnSaleCreditNoteAndReturn,
      applyAutoBalanceOnSaleInvoiceAndBudget: filter.applyAutoBalanceOnSaleInvoiceAndBudget,
      barCodeFormatId: filter.barCodeFormatId,
      barCodeReaderDefault: filter.barCodeReaderDefault,
      billTypeId: filter.billTypeId,
      budgetData: filter.budgetData,
      coinDefaultId: filter.coinDefaultId,
      comex: filter.comex,
      commander: filter.commander,
      confirmBudgetDefault: filter.confirmBudgetDefault,
      cuitChargedOnSalesInvoice: filter.cuitChargedOnSalesInvoice,
      customerDefaultCity: filter.customerDefaultCity,
      dashboard: filter.dashboard,
      declaredValueInsurance: filter.declaredValueInsurance,
      discountManuallyStock: filter.discountManuallyStock,
      displayQuantityInApp: filter.displayQuantityInApp,
      dolarBlue: filter.dolarBlue,
      dontUpdatePurchaseCost: filter.dontUpdatePurchaseCost,
      ecommerceUrl: filter.ecommerce === true ? filter.ecommerceUrl : " ",
      expirationCustomerDefault: filter.expirationCustomerDefault,
      expirationProviderDefault: filter.expirationProviderDefault,
      exportBill: filter.exportBill,
      fiscalBonus: filter.fiscalBonus,
      salePointFiscalBonus: filter.fiscalBonus === true ? filter.salePointFiscalBonus : false,
      freightInsurance: filter.freightInsurance,
      hideBalanceSalesReceipts: filter.hideBalanceSalesReceipts,
      hideBrandWebOrders: filter.ecommerce === true ? filter.hideBrandWebOrders : false,
      hideStockWebOrders: filter.ecommerce === true ? filter.hideStockWebOrders : false,
      perceptionAgentIIBB: filter.perceptionAgentIIBB,
      iibbCalculationCustomerAliquot: filter.iibbCalculationCustomerAliquot,
      ivaBudgetPurchasePrice: filter.ivaBudgetPurchasePrice,
      ivaBudgetSalePrice: filter.ivaBudgetSalePrice,
      logoFidelVouchers: filter.logoFidelVouchers,
      mandatoryConceptForPurchase: filter.mandatoryConceptForPurchase,
      mandatoryConceptForSale: filter.mandatoryConceptForSale,
      multipleCoins: filter.multipleCoins,
      multipleCoinsBudget: filter.multipleCoinsBudget,
      multiplier: filter.multiplier,
      nameQuantity: filter.discountManuallyStock === true ? filter.nameQuantity : null,
      nameStock: filter.discountManuallyStock === true ? filter.nameStock : null,
      seeStockByOrder: filter.seeStockByOrder,
      payConditionId: filter.payConditionId !== null ? filter.payConditionId : 0,
      portalClient: filter.portalClient,
      automaticApplyPositiveBalance: filter.automaticApplyPositiveBalance,
      remitReportPrintData: filter.remitReportPrintData,
      printDataInReportRemitBudget: filter.printDataInReportRemitBudget,
      allowPrintPricesOrder: filter.allowPrintPricesOrder,
      showBalanceInOrder: filter.showBalanceInOrder,
      allowSendProductsInSales: filter.allowSendProductsInSales,
      productImagesFTP: filter.productImagesFTP,
      productActivateInPriceList: filter.productActivateInPriceList,
      profitsPercentageWebOrders: filter.ecommerce === true ? filter.profitsPercentageWebOrders : false,
      quantityPerPackage: filter.quantityPerPackage,
      saleConfigurationDateAplication:
        filter.saleConfigurationDateAplication !== null
          ? filter.saleConfigurationDateAplication
          : new Date().toISOString(),
      selectedSalePoint: filter.selectedSalePoint,
      saleAlertWhioutStock: filter.saleAlertWhioutStock,
      searchPricesWithDiscountsWebOrders: filter.ecommerce === true ? filter.searchPricesWithDiscountsWebOrders : false,
      searchProductBySupplierCode: filter.searchProductBySupplierCode,
      seeBonus: filter.seeBonus,
      seeCostInPriceQuery: filter.seeCostInPriceQuery,
      system: filter.system,
      tiendaNube: filter.tiendaNube,
      totalSalesReports: filter.totalSalesReports,
      transferOrderValueToStock: filter.transferOrderValueToStock,
      allowUpdatePriceActiveStatusOrder: filter.allowUpdatePriceActiveStatusOrder,
      allowUpdatePriceForOrderToBill: filter.allowUpdatePriceForOrderToBill,
      useServiceOrder: filter.useServiceOrder,
      voucherCombine: filter.voucherCombine,
      confirmCommandVoucher: filter.confirmCommandVoucher,
      voucherReportLegend: filter.voucherReportLegend,
      selectedSalesCategories: filter.selectedSalesCategories,
      salesConfiguration: filter.salesConfiguration,
      salePointExporter: filter.exportBill === true ? filter.salePointExporter : false,
      sendAutomaticCustomerOrderEmail: filter.sendAutomaticCustomerOrderEmail,
      sendAutomaticAccountOrderEmail: filter.sendAutomaticAccountOrderEmail,
      selectedPurchaseCategories: filter.selectedPurchaseCategories,
      productServiceConfiguration: {
        id: filter.productServiceConfiguration.id,
        allowTotalEdit:
          filter.productServiceConfiguration !== undefined ? filter.productServiceConfiguration.allowTotalEdit : false,
      },
      extendedObservationReport: filter.extendedObservationReport,
      quickPrint: filter.quickPrint,
      quantityAlert: filter.quantityAlert,
      stockAlert: filter.stockAlert,
      sendEmailSavingVouchers: filter.sendEmailSavingVouchers,
      stockTransferWithStock: filter.stockTransferWithStock,
      sendUnpaidTNOrders: filter.sendUnpaidTNOrders,
      shippingCostTN: filter.shippingCostTN,
      ivaPerception: filter.ivaPerception,
      netSubtotalInUnconfirmedBudget: filter.netSubtotalInUnconfirmedBudget,
      showSubtotalVATInBudgetNotConfirmed: filter.showSubtotalVATInBudgetNotConfirmed,
      qrCodeProducts: filter.qrCodeProducts,
      wooCommerce: filter.wooCommerce
    };

    let { result, message } = await accountService.saveAccountSettings(body);
    if (result) {
      toggleMessage("success", "Configuración guardada exitosamente");
      switch (type) {
        case "apply":
          setIsLoading(false);
          break;
        case "exit":
          history.push("/cuenta/lista");
          break;
        case "login":
          localStorage.removeItem("tokenLog");
          localStorage.removeItem("dataBytokenLog");
          window.location.href = `${process.env.REACT_APP_SSO_API_URL}/login?serviceURL=${process.env.REACT_APP_FIDEL_SISTEMA_FRONT}&validationPath=${process.env.REACT_APP_VALIDATION_PATH_VIEJO}&loginAs=${userEmail}`;
          break;
      }
    } else {
      toggleMessage("error", message);
      setIsLoading(false);
    }
  }

  return (
    <>
      {initialValuesForm && salePoints && categories ? (
        <Grid className="c-mt-40 c-ml-100">
          <Formik initialValues={initialValuesForm} onSubmit={() => {}}>
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Grid container justifyContent="flex-start">
                  <Button
                    variant="contained"
                    size="small"
                    className="c-btn-info c-cursor-pointer c-mt-5 c-ml-5"
                    onClick={() => history.goBack()}
                  >
                    Volver
                  </Button>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <Grid container>
                          <h3 className="c-text-fidel-green c-mt-25">Configuración de la cuenta </h3>
                          <h2 className="c-text-fidel-green c-text-bold c-mt-20"> - {accountName}</h2>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <AccountSettingsForm
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    existIIBB={existIIBB}
                  />
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green c-mt-20">Configuracion de comprobantes</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <VoucherSettingsForm
                          setFieldValue={setFieldValue}
                          values={values}
                          handleChange={handleChange}
                          salePoints={salePoints}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green c-mt-20">Configuracion de presupuestos</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <BudgetSettingsForm
                          setFieldValue={setFieldValue}
                          values={values}
                          handleChange={handleChange}
                          categories={categories}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green c-mt-20">Configuracion de pedidos</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <OrdersSettingsForm setFieldValue={setFieldValue} values={values} handleChange={handleChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green c-mt-20">Configuracion de productos y servicios</h3>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <ProductsAndServicesSettingsForm
                          setFieldValue={setFieldValue}
                          values={values}
                          handleChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <Grid container justifyContent="space-between">
                          <h3 className="c-text-fidel-green c-mt-20">Configuracion de ventas</h3>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item md={12} xs={12}>
                        <SalesSettingsForm setFieldValue={setFieldValue} values={values} handleChange={handleChange} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20"></Grid>
                  <Grid item xs={6} className="c-mt-20">
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-fidel-green c-cursor-pointer c-m-5"
                      onClick={() => {
                        saveSettings(values, "apply");
                      }}
                      id="aplicar"
                    >
                      Aplicar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-info c-cursor-pointer c-m-5"
                      onClick={() => {
                        saveSettings(values, "exit");
                      }}
                      id="saveAndExit"
                    >
                      Guardar y salir
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-fidel-green c-cursor-pointer c-m-5"
                      onClick={() => {
                        saveSettings(values, "login");
                      }}
                      id="saveAndLogin"
                    >
                      Guardar y login
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      className="c-btn-cancel  c-cursor-pointer c-m-5"
                      onClick={() => history.goBack()}
                    >
                      Cancelar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
}
