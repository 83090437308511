import { Checkbox, FormControlLabel, FormHelperText, Grid, Input } from "@material-ui/core";
import { useState } from "react";
import CCustomSelectOrSearch from "../../../../common/CCustomSelectOrSearch";

export default function ImportAccount(props) {
  const { setFieldValue, accountList, errors, handleChange, values, setSelectedFile } = props;

  function handleFileChange(event) {
    setSelectedFile(event.target.files[0]);
  }

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12} className="c-mt-20">
          <Grid>
            <CCustomSelectOrSearch
              disabled={false}
              name="accountId"
              placeHolder="Cuenta"
              onChange={(e: { value: any }) => {
                setFieldValue("accountId", e ? e.value : undefined);
              }}
              dataList={accountList}
            />
            {errors["accountId"] && (
              <FormHelperText className={errors["accountId"] ? "c-font-cancel" : ""}>
                {errors["accountId"] ?? null}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20">
          <h3 className="c-text-fidel-green">Excel</h3>
        </Grid>
        <Grid item xs={12} className="c-mt-20">
          <Input
            name="excelArchive"
            margin="dense"
            type="file"
            onChange={(e) => {
              handleFileChange(e);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20">
          <h3 className="c-text-fidel-green">Preferencias</h3>
        </Grid>
        <Grid item xs={12} className="c-mt-20">
          <FormControlLabel
            control={
              <Checkbox
                style={{ color: "#0063cc" }}
                color="default"
                checked={values.deleteTable ?? false}
                onChange={handleChange}
                name="deleteTable"
              />
            }
            name="deleteTable"
            label="Eliminar Tabla "
            labelPlacement="start"
          />
          {errors["deleteTable"] && (
            <FormHelperText className={errors["deleteTable"] ? "c-font-cancel" : ""}>
              {errors["deleteTable"] ?? null}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
}
