import { API } from "./index";

const BASE_URL: string = "api/cards";

export async function getCards() {
  return API.get(BASE_URL).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function saveCards(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
