import { useContext, useState } from "react";
import QueriesForm from "./queriesForm";
import { Grid } from "@material-ui/core";
import QueriesVoucher from "./queriesVoucher";
import * as afipService from "../../../api/services/afip";
import QueriesResult from "./queriesResult";
import { setIsLoading, toggleMessage } from "../../../utils/events";

export default function Queries() {
  const [dataTrue, setDataTrue] = useState(false);
  const [resultTrue, setResultTrue] = useState(false);
  const [results, setResults] = useState({
    existsInBd: undefined,
    saleId: undefined,
  });
  const [accountCertificate, setAccountCertificate] = useState();
  const [salePointList, setSalePointList]: any = useState([]);
  const [initialValuesForm, setInitialValuesForm]: any = useState({
    cuit: undefined,
    accountName: undefined,
    certificate: undefined,
    voucherNumber: undefined,
    voucherType: undefined,
    salePoint: undefined,
  });

  async function getInfo(filter) {
    setIsLoading(true);
    let cuit = filter["cuit"];
    try {
      let { result, data, message } = await afipService.getCuitByCuit(cuit);
      if (result === 200) {
        setInitialValuesForm({
          ...initialValuesForm,
          cuit: cuit,
          accountName: data["accountName"],
          certificate: data["nameCertificate"],
        });
        setAccountCertificate(data["accountCertificateId"]);
        setSalePointList(data["salePoints"]);
        setDataTrue(true);
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Grid className="c-ml-100 c-mt-60">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="c-border-botom-green-ligth">
                <h2 className="c-text-fidel-green">Datos de la cuenta</h2>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {dataTrue === false && <QueriesForm initialValuesForm={initialValuesForm} getInfo={getInfo} />}
        {dataTrue === true && (
          <>
            <QueriesForm initialValuesForm={initialValuesForm} getInfo={getInfo} />
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20">
                    <h2 className="c-text-fidel-green">Datos del comprobante a consultar</h2>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {resultTrue === false ? (
              <>
                <QueriesVoucher
                  initialValuesForm={initialValuesForm}
                  dataTrue={dataTrue}
                  setDataTrue={setDataTrue}
                  accountCertificate={accountCertificate}
                  salePointList={salePointList}
                  setResultTrue={setResultTrue}
                  setResults={setResults}
                />
              </>
            ) : (
              <>
                <QueriesVoucher
                  initialValuesForm={initialValuesForm}
                  dataTrue={dataTrue}
                  setDataTrue={setDataTrue}
                  accountCertificate={accountCertificate}
                  salePointList={salePointList}
                  setResultTrue={setResultTrue}
                  setResults={setResults}
                />
                <QueriesResult results={results} />
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
