import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import CDialogDelete from "../../../common/CDialogDelete";
import moment from "moment";
import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function ActiveNewsList(props) {
  const { history, paginationData, changePage, handleChangeRowsPerPage, newsActiveList, deleteNew, sortTable } = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const [dataDelete, setDataDelete]: any = React.useState({});
  const [sort, setSort] = useState<"asc" | "desc">("desc");

  const actionConfirm = () => {
    deleteNew(dataDelete.id, true);
    setOpenDelete(false);
  };

  return (
    <>
      {newsActiveList && newsActiveList.length !== 0 ? (
        <>
          <Paper className="c-mt-20 c-grid-results" elevation={3}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="c-table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="c-text-center">
                          Fecha de creación
                          {sort === "desc" ? (
                            <IconButton
                              className="c-grid-btn-white"
                              onClick={() => {
                                setSort("asc");
                                sortTable("+Id");
                              }}
                            >
                              <ArrowDropUpIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              className="c-grid-btn-white"
                              onClick={() => {
                                setSort("desc");
                                sortTable("-Id");
                              }}
                            >
                              <ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell className="c-text-center">título</TableCell>
                        <TableCell className="c-text-center">Opciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newsActiveList.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell className="c-rows-elipsis">
                            {moment(item.dateCreateOn).format("DD/MM/yyyy hh:mm")}
                          </TableCell>
                          <TableCell className="c-rows-elipsis">{item.titulo}</TableCell>
                          <TableCell className="c-rows-elipsis">
                            <div>
                              <IconButton
                                className="c-grid-btn-green"
                                onClick={() => history.push("/novedades/editar/" + item.id)}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                className="c-grid-btn-cancel"
                                onClick={() => {
                                  setOpenDelete(true);
                                  setDataDelete({
                                    id: item.id,
                                    title: "Dar de baja",
                                    content: `¿Está seguro de dar de baja la novedad?`,
                                  });
                                }}
                              >
                                <BlockIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Pagination
                className="c-pagination"
                onChange={changePage}
                page={paginationData.currentPage}
                count={paginationData.amount > 1 ? paginationData.amount : 0}
                shape="rounded"
              />

              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
                Registros por páginas
              </InputLabel>
              <NativeSelect
                defaultValue={20}
                onChange={handleChangeRowsPerPage}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </NativeSelect>
            </Grid>
          </Paper>
          <CDialogDelete
            open={openDelete}
            setOpen={setOpenDelete}
            title={dataDelete.title}
            content={dataDelete.content}
            actionConfirm={actionConfirm}
          />
        </>
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
