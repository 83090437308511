/** Roles y sus respectivos IDs y Descripciones */
export const RoleEnum = {
  Admin: { Id: 1, Description: "Administrador" },
  Sistema: { Id: 2, Description: "Sistema" },
  Contabilidad: { Id: 3, Description: "Contabilidad" },
  Basico: { Id: 3, Description: "Sistema-Básico" },
};

export const UrlEnum = {
  Facebook: "https://www.facebook.com/fidelgestion",
  LinkedIn: "https://www.linkedin.com/company/agencia-programarte",
  Instagram: "https://www.instagram.com/fidelsistema/",
};

export const BillTypeEnum = [
  { value: 1, text: "Simple" },
  { value: 2, text: "Transporte" },
];

export const PayConditionEnum = [
  { value: 0, text: "Todos" },
  { value: 1, text: "CuentaCorriente" },
  { value: 2, text: "Contado" },
];
export const PayCondition = {
  Todos: { value: 0, text: "Todos" },
  CuentaCorriente: { value: 1, text: "Cuenta corriente" },
  Contado: { value: 2, text: "Contado" },
};

export const CoinEnum = [
  { value: 1, text: "Peso" },
  { value: 2, text: "Dolar" },
  { value: 5, text: "DolarBlue" },
];

export const BarCodeFormatEnum = [
  { value: 1, text: "Lectura de Código de Barras para Balanza por Precio" },
  { value: 2, text: "Lectura de Código de Barras para Balanza por Peso" },
];

export const colourEnum = [
  { value: 1, text: "amarillo" },
  { value: 2, text: "rojo" },
  { value: 3, text: "verde" },
];

export const LogTypeEnum = [
  { value: 100, text: "Debug" },
  { value: 200, text: "Information" },
  { value: 300, text: "Warning" },
  { value: 400, text: "Error" },
  { value: 500, text: "FatalError" },
  { value: 600, text: "AFIP" },
];
