import { Grid, Paper, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import * as provincesService from "../../../api/services/provinces";
import ProvincesList from "./provincesList";

export default function Provinces() {
  const [valueSearch, setValueSearch] = useState("");
  const [dataTable, setDataTable] = useState([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const history = useHistory();

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const changePage = (e: any, value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page);
  };

  const reloadData = (page: number, pageSize?: number) => {
    provincesList(page, pageSize ? pageSize : rowsPerPage, valueSearch ?? "", null);
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const onClickSearch = () => {
    provincesList(1, rowsPerPage, valueSearch, null);
  };

  useEffect(() => {
    setIsLoading(true);
    provincesList(1, rowsPerPage, "", null);
  }, []);

  async function provincesList(page: number, pageSize: number, search?: string, orderBy?: null | string) {
    setIsLoading(true);

    try {
      let { result, data, message }: any = await provincesService.getProvinces({
        search,
        orderBy,
      });
      if (result === 200) {
        let currentPage = page;
        let count = data["length"];
        let amount = Math.ceil(count / rowsPerPage);
        let indexOfLastPost = currentPage * rowsPerPage;
        let indexOfFirstPost = indexOfLastPost - rowsPerPage;

        let table: any = Object.values(data);
        setDataTable(table.slice(indexOfFirstPost, indexOfLastPost));

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const deleteProvince = (id) => {
    provincesService.deleteProvince(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Provincia dada de baja exitosamente");
        reloadData(paginationData.currentPage ?? 1);
      } else {
        toggleMessage("error", res["message"] ?? "Ocurrió un error al dar de baja la provincia seleccionada");
      }
    });
  };

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <a href={"/provincias/crear"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          >
            Agregar Nuevo
          </Button>
        </a>

      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Provincia"
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer"
          startIcon={<SearchIcon />}
          onClick={() => onClickSearch()}
        >
          Buscar
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <ProvincesList
          dataTable={dataTable}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          deleteProvince={deleteProvince}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
