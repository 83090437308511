import { useEffect, useState } from "react";
import moment from "moment";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { RoleEnum } from "../../../enums";
import shortenText from "../../../common/CShortText";
import { propsInterface } from "./AdminInterfaces/interfaces";
import * as reasonsForUnsubscribeService from "../../../api/services/reasonsForUnsubscribe";
import { toggleMessage } from "../../../utils/events";
import UnsubscribeAccountModal from "./modals/unsubscribeAccount";
import { redirectFidelSSO } from "./utils";

export default function AccountList(props: propsInterface) {
  const {
    history,
    dataTable,
    paginationData,
    changePage,
    handleChangeRowsPerPage,
    // rowsPerPage,
    unsubscribeAccountById,
  } = props;
  const [openDelete, setOpenDelete] = useState(false);
  const [dataDelete, setDataDelete] = useState<{
    id: number;
    title: string;
    content: string;
    motivoBajaId?: number;
    fechaBaja?: Date;
  }>({
    id: 0,
    title: "",
    content: "",
    motivoBajaId: 1,
    fechaBaja: new Date(),
  });
  const [reasonsList, setReasonsList] = useState<{ value: number; text: string }[]>();
  useEffect(() => {
    if (openDelete) {
      reasonsForUnsubscribeService.getReasonsForUnsubscribeSelect().then(({ result, data, message }) => {
        if (result && data) {
          setReasonsList(data);
        } else {
          toggleMessage("error", message);
        }
      });
    }
  }, [openDelete]);

  const getLastConnectionByUsers = (users: any) => {
    let response: string = "-";
    if (users && users.length !== 0) {
      let lastUser = users[users.length - 1];

      if (lastUser?.lastConnection) {
        response = moment(lastUser.lastConnection).format("DD/MM/yyyy hh:mm");
      }
    }
    return response;
  };

  const actionConfirmUnsubscribeAccount = (values: {
    id: number;
    motivoBajaId?: number | undefined;
    fechaBaja?: Date | undefined;
  }) => {
    unsubscribeAccountById(values.id, {
      motivoBajaId: values.motivoBajaId,
      fechaBaja: moment(values.fechaBaja).format("yyyy-MM-DD HH:mm:ss"),
    });
    setOpenDelete(false);
  };

  return (
    <>
      <Paper className="c-mt-20 c-grid-results" elevation={3}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12}>
            <TableContainer>
              <Table className="c-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="c-text-center">Nombre</TableCell>
                    <TableCell className="c-text-center">Empresa</TableCell>
                    <TableCell className="c-text-center">Plan</TableCell>
                    <TableCell className="c-text-center">AFIP</TableCell>
                    <TableCell className="c-text-center">Fecha de Inicio</TableCell>
                    <TableCell className="c-text-center">Última Conexión</TableCell>
                    <TableCell className="c-text-center ">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item: any) => (
                    <TableRow key={item.id}>
                      <TableCell className="c-rows-elipsis">{shortenText(item.name ?? "-", 20)}</TableCell>
                      <TableCell className="c-rows-elipsis">{shortenText(item.company ?? "-", 20)}</TableCell>
                      <TableCell className="c-mwp-10">{item.accountPlan ?? "-"}</TableCell>
                      <TableCell className="c-mwp-10">
                        {item.accountTypeId === RoleEnum.Sistema.Id ? (
                          !item.whitoutAFIPCertificate ? (
                            <CheckIcon className="c-icon-success" />
                          ) : (
                            <ClearIcon className="c-icon-cancel" />
                          )
                        ) : (
                          <HorizontalRuleIcon className="c-icon-grey" />
                        )}
                      </TableCell>
                      <TableCell className="c-mwp-10">{moment(item.dateCreateOn).format("DD/MM/yyyy hh:mm")}</TableCell>
                      <TableCell className="c-mwp-10">{getLastConnectionByUsers(item.users)}</TableCell>
                      <TableCell>
                        <div>
                          <a href={"/cuenta/editar/" + item.id}>
                            <IconButton
                              className="c-grid-btn-green"
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </a>
                          <a href="#">
                            <IconButton
                              className="c-grid-btn-cancel"
                              onClick={() => {
                                setOpenDelete(true);
                                setDataDelete({
                                  ...dataDelete,
                                  id: item.id,
                                  title: "Baja de registro",
                                  content: `${item.name} - ${item.company}`,
                                });
                              }}
                              >
                            <ThumbDownIcon fontSize="small" />
                          </IconButton>
                          </a>
                          <a href={"/usuario/lista/" + item.id}>
                          <IconButton
                            className="c-grid-btn-info"
                          >
                            <PeopleAltIcon fontSize="small" />
                          </IconButton>
                          </a>
                          <a href={"/cuenta/ConfiguracionCuenta/" + item.id}>
                          <IconButton
                            className="c-grid-btn-cancel"
                          >
                            <SettingsIcon fontSize="small" />
                          </IconButton>
                          </a>
                          <IconButton
                            className="c-grid-btn-gray"
                            onClick={() => {
                              redirectFidelSSO(item.email);
                            }}
                          >
                            <ArrowForwardIcon fontSize="small" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Pagination
            className="c-pagination"
            onChange={changePage}
            page={paginationData.currentPage}
            count={paginationData.amount > 1 ? paginationData.amount : 0}
            shape="rounded"
          />

          <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
            Registros por páginas
          </InputLabel>
          <NativeSelect
            defaultValue={20}
            onChange={handleChangeRowsPerPage}
            inputProps={{
              name: "age",
              id: "uncontrolled-native",
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={25}>25</option>
          </NativeSelect>
        </Grid>
      </Paper>
      <Grid>
        <UnsubscribeAccountModal
          openDelete={openDelete}
          setOpenDelete={setOpenDelete}
          reasonsList={reasonsList}
          dataDelete={dataDelete}
          setDataDelete={setDataDelete}
          actionConfirmUnsubscribeAccount={actionConfirmUnsubscribeAccount}
        />
      </Grid>
    </>
  );
}
