import { API, errorHandling } from "../index";
import { DefaultResponse } from "../SSOClient";
import { Notification } from "./notification.types";

const BASE_URL: string = "/api/notifications";

export async function getNotifications(
  search: string,
  page: number,
  pageSize: number,
  orderBy: any,
  deleted: boolean
): Promise<DefaultResponse<{ list: Notification[], totalRecords: number }>> {
  return API.get(BASE_URL, {
    params: { search, page, pageSize, orderBy, deleted },
  }).then((res) => {
    return {
      result: true,
      data: { list: res.data, totalRecords: res.headers["total-records"] }
    };
  }).catch(errorHandling);
}

export async function editNotifications(id: number, body: any): Promise<DefaultResponse<Notification[]>> {
  return API.put(BASE_URL + "/" + id, body).then(({ data }) => {
    return {
      result: true,
      data
    };
  }).catch(errorHandling);
}

export async function saveNotifications(body: any) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getNotificationById(id: number): Promise<DefaultResponse<Notification>> {
  return API.get(BASE_URL + "/" + id).then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function deleteNotification(id: number, deleted: boolean) {
  return API.delete(BASE_URL + "/" + id, {
    params: {
      delete: deleted,
    },
  }).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
