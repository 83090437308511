import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Button, Grid } from "@material-ui/core";
import { Formik } from "formik";
import CTextField from "../../../../../common/CTextField";
import * as Yup from "yup";
import * as userService from "../../../../../api/services/AdminUsers";
import { toggleMessage } from "../../../../../utils/events";

type IProps = {
  open: boolean;
  user: { id: number; name: string };
  handleClose: Dispatch<SetStateAction<boolean>>;
};

function ChangePass(props: IProps) {
  let { open, handleClose, user } = props;
  const initialValues: {
    newPass: string;
    confirmPass: string;
  } = {
    newPass: "",
    confirmPass: "",
  };

  const validationSchema = Yup.object({
    newPass: Yup.string().required("La nueva contraseña es requerida"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("newPass"), null], "Las contraseñas deben coincidir")
      .required("La confirmación de contraseña es requerida"),
  });

  async function savePass(values: { newPass: string; confirmPass: string }) {
    let { result, data, message } = await userService.changePassword(user.id, values.newPass);
    if (result) {
      toggleMessage("success", "Editado correctamente");
      handleClose(false);
    } else {
      toggleMessage("error", message);
    }
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={savePass}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <Dialog
            open={open}
            onClose={() => {
              handleClose(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <form onSubmit={handleSubmit}>
              <DialogTitle id="alert-dialog-title">
                <Grid container>
                  <h4>
                    <VpnKeyIcon fontSize="small" />
                    Cambiar contraseña
                  </h4>
                </Grid>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={6} className="c-mt-5">
                    <h4>Usuario</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <h4>{user.name}</h4>
                  </Grid>
                  <Grid item xs={6} className="c-mt-5">
                    <h4>Nueva contraseña</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <CTextField
                      label=""
                      type="password"
                      fullWidth
                      onChange={handleChange}
                      name="newPass"
                      AutoComplete="new-password"
                      helperText={<span className="c-font-cancel">{errors["newPass"] ?? null}</span>}
                      value={values.newPass ?? ""}
                    />
                  </Grid>
                  <Grid item xs={6} className="c-mt-5">
                    <h4>Repetir contraseña</h4>
                  </Grid>
                  <Grid item xs={6}>
                    <CTextField
                      label=""
                      type="password"
                      fullWidth
                      onChange={handleChange}
                      name="confirmPass"
                      AutoComplete="new-password"
                      helperText={<span className="c-font-cancel">{errors["confirmPass"] ?? null}</span>}
                      value={values.confirmPass ?? ""}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Grid container justifyContent="flex-end" className="c-border-top">
                <Button
                  variant="contained"
                  size="small"
                  className="c-btn-fidel-green c-cursor-pointer c-m-5"
                  type="submit"
                >
                  Confirmar
                </Button>
                <Button
                  className="c-btn-cancel  c-cursor-pointer c-m-5"
                  onClick={() => {
                    handleClose(false);
                  }}
                >
                  No
                </Button>
              </Grid>
            </form>
          </Dialog>
        )}
      </Formik>
    </Fragment>
  );
}

export default ChangePass;
