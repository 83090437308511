import { Button, Grid, Paper } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AccountUsersList from "./accountUsersList";
import * as usersService from "../../../../api/services/AdminUsers";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import { User } from "../../../../api/services/AdminUsers/adminUsers.types";

export default function AccountUsers(props) {
  const { history } = props;

  const [dataTable, setDataTable] = useState<User[]>([]);
  const { id }: any = useParams();
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    getAccountUsers(null, 1, 100);
  }, []);

  const changePage = (event, value) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const reloadData = (page: number, pageSize: number) => {
    getAccountUsers(null, page, pageSize);
  };

  async function getAccountUsers(orderBy, page, pageSize) {
    try {
      setIsLoading(true);
      let { data, result, message } = await usersService.getAccountUsers({ orderBy, page, pageSize, accountId: id });
      if (result && data) {
        let currentPage = page;
        let count = data.currentRecordsCount;
        let amount = data.pagesCount;
        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
        setDataTable(data.results);
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-start">
        <Button
          variant="contained"
          size="small"
          className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
          onClick={() => history.goBack()}
        >
          Volver
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <AccountUsersList
          dataTable={dataTable}
          changePage={changePage}
          paginationData={paginationData}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          history={history}
          reloadData={reloadData}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
