import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as logService from "../../../api/services/logService";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { Grid } from "@material-ui/core";
import CTextField from "../../../common/CTextField";
import { Formik } from "formik";
import { LogTypeEnum } from "../../../enums";

export default function LogDetail() {
  const { id }: any = useParams();
  const [logDetail, setLogDetail]: any = useState();
  const [errorType, setErrorType]: any = useState();

  useEffect(() => {
    getLogsById(id);
  }, []);

  async function getLogsById(id: number) {
    setIsLoading(true);

    try {
      let { result, data, message } = await logService.getLogsById(id);

      if (result === 200) {
        setLogDetail(data);
        let textError: any = "";
        LogTypeEnum.map((item) => {
          if (item.value === data.logLevel) {
            textError = item.text;
          }
        });
        setErrorType(textError);
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {logDetail && (
        <div className="c-mt-60 c-ml-100">
          <Formik initialValues={logDetail} onSubmit={() => {}}>
            {({ values, handleChange }) => (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green">Información</h3>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={1} md={1} className="c-mt-30">
                        <h4 className="c-ml-30">Fecha</h4>
                      </Grid>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        <CTextField
                          label=""
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="dateCreateOn"
                          helperText=""
                          value={values["dateCreateOn"] ?? ""}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={1} md={1} className="c-mt-30">
                        <h4 className="c-ml-30">Usuario</h4>
                      </Grid>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        <CTextField
                          label=""
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="userName"
                          helperText=""
                          value={values.userName}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={1} md={1} className="c-mt-30">
                        <h4 className="c-ml-30">Cuenta</h4>
                      </Grid>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        <CTextField
                          label=""
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="accountName"
                          helperText=""
                          value={values["accountName"] ?? ""}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={1} md={1} className="c-mt-30">
                        <h4 className="c-ml-30">Tipo</h4>
                      </Grid>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        <CTextField
                          label=""
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="logLevel"
                          helperText=""
                          value={errorType}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={1} md={1} className="c-mt-30">
                        <h4 className="c-ml-30">Referencia</h4>
                      </Grid>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        <CTextField
                          label=""
                          type="text"
                          fullWidth
                          onChange={handleChange}
                          name="referrerUrl"
                          helperText=""
                          value={values["referrerUrl"] ?? ""}
                          disabled={true}
                          readOnly={undefined}
                          multiLine={undefined}
                          rows={undefined}
                          onClick={undefined}
                          error={undefined}
                          placeholder={undefined}
                          maxLength={undefined}
                          AutoComplete={""}
                          InputaLabelProps={undefined}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-mt-20">
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green">Mensaje Corto</h3>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={8} md={8} className="c-mt-20">
                        {values.shortMessage}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className="c-mt-20">
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="c-border-botom-green-ligth">
                        <h3 className="c-text-fidel-green">Mensaje Largo</h3>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} className="c-mt-20">
                        {values.fullMessage}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Formik>
        </div>
      )}
    </>
  );
}
