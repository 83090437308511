import { useEffect, useState } from "react";
import { Grid, Paper, Button, TextField } from "@material-ui/core";
import * as accountService from "../../../../api/services/Account";
import UnsubscribedAccountList from "./unsubscribedAccountList";
import { setIsLoading, toggleMessage } from "../../../../utils/events";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router";
import { DeletedAccount } from "../../../../api/services/Account/account.types";

export default function UnsubscribedAccount(props) {
  const history = useHistory();
  const [dataTable, setDataTable] = useState<DeletedAccount[]>();
  const [valueSearch, setValueSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    getUnsubscribedAccount({ page: 1, pageSize: rowsPerPage });
  }, []);

  async function getUnsubscribedAccount(props: {
    page: number;
    pageSize: number;
    search?: string;
  }) {
    let { page, pageSize, search = "" } = props;
    try {
      let { result, data, message } =
        await accountService.getUnsubscribedAccount();

      if (result && data) {
        let currentPage = page;
        let count = data.length;
        let amount = data.length / pageSize;
        let indexOfLastPost = currentPage * pageSize;
        let indexOfFirstPost = indexOfLastPost - pageSize;

        let table = data.filter((account) => {
          return account.name.toLowerCase().includes(search);
        });
        setDataTable(table.slice(indexOfFirstPost, indexOfLastPost));

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: count,
        });
      } else {
        toggleMessage("error", message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  const reloadData = (page: number, rowsPerPage: number) => {
    getUnsubscribedAccount({ page, pageSize: rowsPerPage });
  };

  const changePage = (event, value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  function subscribeAccount(id: number) {
    accountService.subscribeAccount(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Cuenta dada de baja exitosamente");
        history.push("/cuenta/lista");
      } else {
        toggleMessage(
          "error",
          res["message"] ??
            "Ocurrió un error al dar de baja la cuenta seleccionada"
        );
      }
    });
  }

  const unsubscribeAccountById = (
    id: number,
    values: { motivoBajaId?: number; fechaBaja?: string }
  ) => {
    accountService.unsubscribeAccountById(id, values).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Cuenta dada de baja exitosamente");
        reloadData(paginationData.currentPage ?? 1, rowsPerPage);
      } else {
        toggleMessage(
          "error",
          res["message"] ??
            "Ocurrió un error al dar de baja la cuenta seleccionada"
        );
      }
    });
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9áéíóúüÜÁÉÍÓÚñÑ ]/g, ""));
    // getUnsubscribedAccount({ page: 1, pageSize: rowsPerPage, search: e.target.value.replace(/[^A-Za-z0-9 ]/g, "") });
  };

  const onClickSearch = () => {
    getUnsubscribedAccount({
      page: 1,
      pageSize: rowsPerPage,
      search: valueSearch,
    });
  };

  return (
    <>
      <Grid container justifyContent="flex-start">
        <Button
          variant="contained"
          size="small"
          className="c-btn-info c-cursor-pointer c-mt-50 c-ml-100"
          onClick={() => history.goBack()}
        >
          Volver
        </Button>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Nombre/Empresa"
          variant="outlined"
          className="c-mt-10  c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-btn-info c-ml-10 c-cursor-pointer"
          startIcon={<SearchIcon />}
          onClick={(e) => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <UnsubscribedAccountList
          dataTable={dataTable}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          subscribeAccount={subscribeAccount}
          unsubscribeAccountById={unsubscribeAccountById}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
