import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  InputLabel,
  NativeSelect,
  Button,
  TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import CDialogDelete from "../../../common/CDialogDelete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import * as newsService from "../../../api/services/news";
import { News } from "../../../api/services/news/news.type";

export default function DeletedNewsList() {
  const history = useHistory();
  const [openActive, setOpenActive] = React.useState(false);
  const [dataActive, setDataActive] = React.useState<{
    id: number;
    title: string;
    content: string;
  }>();
  const [sort, setSort] = useState<"asc" | "desc">("desc");
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [valueSearch, setValueSearch] = useState("");
  const [newsDeletedList, setNewsDeletedList] = useState<News[]>();

  const actionConfirm = () => {
    activateNew(dataActive?.id ?? 0);
    setOpenActive(false);
    window.location.reload();
  };

  useEffect(() => {
    getDeletedNews({ search: "", page: 1, pageSize: 20, orderBy: "-Id" });
  }, []);

  async function getDeletedNews(props: { search: string; page: number; pageSize: number; orderBy: any }) {
    setIsLoading(true);
    let { search, page, pageSize, orderBy } = props;
    try {
      let { result, data, message } = await newsService.getNews(search, orderBy, true, page, pageSize);
      if (result && data) {
        let currentPage = page;
        let amount = data.totalRecords / pageSize;

        setNewsDeletedList(data.list);

        setPaginationData({
          currentPage: currentPage,
          amount: amount,
          totalRecords: data.totalRecords,
        });
      } else {
        toggleMessage("error", message);
      }
    } catch (error) {
      toggleMessage("error", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData({ page: 1, pageSize: +event.target.value });
  };

  function sortTable(orderBy: "+Id" | "-Id") {
    reloadData({ page: paginationData.currentPage, pageSize: rowsPerPage, orderBy });
  }

  const changePage = (event, value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData({ page, pageSize: rowsPerPage });
  };

  const reloadData = (props: { page: number; pageSize: number; search?: string; orderBy?: any }) => {
    let { search, page, pageSize, orderBy } = props;
    getDeletedNews({ search: search ?? valueSearch, page, pageSize, orderBy: orderBy ?? "-Id" });
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
    reloadData({ page: 1, pageSize: rowsPerPage, search: e.target.value });
  };

  const onClickSearch = () => {
    reloadData({ page: 1, pageSize: rowsPerPage, search: valueSearch });
  };

  function activateNew(id: number) {
    newsService.activateNew(id);
    reloadData({ page: 1, pageSize: 20 });
  }

  return (
    <div className="c-mt-40 c-ml-100">
      <Grid container justifyContent="flex-end">
        <a href={"/novedades/lista"}>
          <Button
            variant="contained"
            size="small"
            className="c-btn-info  c-cursor-pointer c-mt-5 c-ml-5"
          >
            Dados de alta
          </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label="Buscar..."
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer c-btn-info"
          startIcon={<SearchIcon />}
          onClick={(e) => {
            onClickSearch();
          }}
        >
          Buscar
        </Button>
      </Grid>
      {newsDeletedList && newsDeletedList.length !== 0 ? (
        <>
          <Paper className="c-mt-20 c-grid-results" elevation={3}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer>
                  <Table className="c-table">
                    <TableHead>
                      <TableRow>
                        <TableCell className="c-text-center">
                          Fecha de creación
                          {sort === "desc" ? (
                            <IconButton
                              className="c-grid-btn-white"
                              onClick={() => {
                                setSort("asc");
                                sortTable("+Id");
                              }}
                            >
                              <ArrowDropUpIcon fontSize="small" />
                            </IconButton>
                          ) : (
                            <IconButton
                              className="c-grid-btn-white"
                              onClick={() => {
                                setSort("desc");
                                sortTable("-Id");
                              }}
                            >
                              <ArrowDropDownIcon fontSize="small" />
                            </IconButton>
                          )}
                        </TableCell>
                        <TableCell className="c-text-center">título</TableCell>
                        <TableCell className="c-text-center">Opciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {newsDeletedList.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell className="c-rows-elipsis">
                            {moment(item.dateCreateOn).format("DD/MM/yyyy hh:mm")}
                          </TableCell>
                          <TableCell className="c-rows-elipsis">{item.titulo}</TableCell>
                          <TableCell className="c-rows-elipsis">
                            <div>
                              <IconButton
                                className="c-grid-btn-green"
                                onClick={() => {
                                  setOpenActive(true);
                                  setDataActive({
                                    id: item.id,
                                    title: "Dar de alta",
                                    content: `¿Está seguro de dar de alta la novedad?`,
                                  });
                                }}
                              >
                                <AddIcon fontSize="small" />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Pagination
                className="c-pagination"
                onChange={changePage}
                page={paginationData.currentPage}
                count={paginationData.amount > 1 ? paginationData.amount : 0}
                shape="rounded"
              />

              <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{ padding: "7px 24px 0px 5px" }}>
                Registros por páginas
              </InputLabel>
              <NativeSelect
                defaultValue={20}
                onChange={handleChangeRowsPerPage}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
              </NativeSelect>
            </Grid>
          </Paper>
          <CDialogDelete
            open={openActive}
            setOpen={setOpenActive}
            title={dataActive?.title ?? ""}
            content={dataActive?.content ?? ""}
            actionConfirm={actionConfirm}
          />
        </>
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
