import { API } from "./index";

const BASE_URL: string = "/api/afip/";

export async function getCuitByCuit(cuit: number) {
  return API.get(BASE_URL + "cuit/" + cuit).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getExist(body) {
  return API.get(BASE_URL + "exists", {
    params: {
      voucherNumber: body["voucherNumber"],
      voucherType: body["voucherType"],
      accountCertificate: body["accountCertificate"],
      salePoint: body["salePoint"],
    },
  }).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res["data"],
        message: res["statusText"],
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
