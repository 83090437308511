import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
} from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export default function QueriesResult(props) {
  const { results } = props;
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-border-botom-green-ligth c-mt-20">
              <h2 className="c-text-fidel-green">Resultado de la Consulta</h2>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5} className="c-mt-20">
              <TableContainer>
                <Table className="c-table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="c-text-center">
                        Existe comprobante en DB
                      </TableCell>
                      <TableCell className="c-text-center">Id</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell className="c-rows-elipsis">
                        {results["existsInBd"] === true ? (
                          <CheckIcon
                            fontSize="medium"
                            className="c-icon-success"
                          />
                        ) : (
                          <ClearIcon
                            fontSize="medium"
                            className="c-icon-success"
                          />
                        )}
                      </TableCell>
                      <TableCell className="c-rows-elipsis">
                        {results["saleId"]}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
