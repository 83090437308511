import { API, generateQueryParams } from "./index";

const BASE_URL: string = "/api/aliquots";

export async function getAliquot(params: { search: string, page: number, pageSize: number, orderBy: any }) {
  return API.get(BASE_URL + generateQueryParams({
    params
  })).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}

export async function saveAliquot(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getAliquotById(id) {
  return API.get(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}

export async function editAliquot(id, body) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteAliquot(id) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}

export async function addAliquot(id) {
  return API.patch(BASE_URL + "/" + id).then((res) => {
    if (res.status === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res.statusText ?? "Ocurrió un error.",
      };
    }
  });
}
