import { API, errorHandling, generateQueryParams } from "../index";
import { DefaultResponse } from "../SSOClient";
import { Bank } from "./banks.types";

const BASE_URL: string = "/api/banks";

export async function getBanks(props: {
  search?: string;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  deleted?: boolean;
}): Promise<DefaultResponse<{ list: Bank[]; totalRecords: number }>> {
  return API.get(BASE_URL + generateQueryParams(props))
    .then(({ data, headers }) => {
      return {
        result: true,
        data: { list: data, totalRecords: headers["total-records"] },
      };
    })
    .catch(errorHandling);
}

export async function saveBanks(body) {
  return API.post(BASE_URL, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function getBankById(id: number): Promise<DefaultResponse<Bank>> {
  return API.get(BASE_URL + "/" + id).then(({ data }) => {
    return {
      result: true,
      data: data,
    };
  }).catch(errorHandling);
}

export async function editBanks(id, body) {
  return API.put(BASE_URL + "/" + id, body).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}

export async function deleteBank(id) {
  return API.delete(BASE_URL + "/" + id).then((res) => {
    if (res["status"] === 200) {
      return {
        result: res.status,
        data: res.data,
        message: res.statusText,
      };
    } else {
      return {
        result: res.status,
        message: res["statusText"] ?? "Ocurrió un error.",
      };
    }
  });
}
