import { Grid, Paper, TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import * as citiesService from "../../../api/services/Cities";
import CitiesList from "./citiesList";
import { setIsLoading, toggleMessage } from "../../../utils/events";
import { City } from "../../../api/services/Cities/city.types";

export default function Cities() {
  const history = useHistory();

  const [valueSearch, setValueSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataTable, setDataTable] = useState<City[]>([]);
  const [paginationData, setPaginationData] = useState({
    amount: 0,
    currentPage: 1,
    totalRecords: 0,
  });

  useEffect(() => {
    setIsLoading(true);
    citiesList("", null, 1, rowsPerPage);
  }, []);

  async function citiesList(search = "", orderBy = null, page: number, pageSize: number) {
    let { result, data, message } = await citiesService.getCities({ search, orderBy, page, pageSize });
    if (result && data) {
      let currentPage = page;
      let count = data.totalRecords;
      let amount = Math.ceil(data.totalRecords / pageSize);
      setDataTable(data.list);
      setPaginationData({
        currentPage: currentPage,
        amount: amount,
        totalRecords: count,
      });
      setIsLoading(false);
    } else {
      toggleMessage("error", message);
      setIsLoading(false);
    }
  }

  const reloadData = (page: number, pageSize: number) => {
    citiesList(valueSearch ?? "", null, page, pageSize);
  };

  const changePage = (event: any, value: number) => {
    let page = value;

    setPaginationData({
      ...paginationData,
      currentPage: value,
    });
    reloadData(page, rowsPerPage);
  };

  const handleChangeValueSearch = (e: { target: { value: string } }) => {
    setValueSearch(e.target.value.replace(/[^A-Za-z0-9 ]/g, ""));
  };

  const onClickSearch = () => {
    citiesList(valueSearch, null, 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event: { target: { value: string | number } }) => {
    setRowsPerPage(+event.target.value);
    setPaginationData({ ...paginationData, currentPage: 1 });
    reloadData(1, +event.target.value);
  };

  const deleteCityById = (id: number) => {
    citiesService.deleteCity(id).then((res) => {
      if (res["result"]) {
        toggleMessage("success", "Localidad eliminada exitosamente");
        reloadData(paginationData.currentPage ?? 1, rowsPerPage);
      } else {
        toggleMessage("error", res["message"] ?? "Ocurrió un error al eliminar la localidad seleccionada");
      }
    });
  };

  return (
    <div className="c-mt-60 c-ml-100">
      <Grid container justifyContent="flex-start">
      <a href={"/localidades/crear"}>
        <Button
          variant="contained"
          size="small"
          className="c-btn-fidel-green c-cursor-pointer c-mt-5 c-ml-5"
        >
          Agregar Nuevo
        </Button>
        </a>
      </Grid>
      <Grid container justifyContent="center">
        <TextField
          size="small"
          id="filled-search"
          label=""
          variant="outlined"
          className="c-mt-10 c-text-center"
          value={valueSearch}
          onChange={handleChangeValueSearch}
        />
        <Button
          variant="contained"
          size="small"
          className="c-mt-10 c-ml-10 c-cursor-pointer"
          startIcon={<SearchIcon />}
          onClick={() => onClickSearch()}
        >
          Buscar
        </Button>
      </Grid>
      {dataTable && dataTable.length !== 0 ? (
        <CitiesList
          dataTable={dataTable}
          history={history}
          paginationData={paginationData}
          changePage={changePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          deleteCityById={deleteCityById}
        />
      ) : (
        <Paper className="c-mt-20" elevation={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} className="c-text-center">
              <h5>No se encontraron resultados.</h5>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
}
