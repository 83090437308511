import { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import { eventBus } from "../../utils/events";

function IsLoading() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    eventBus.loadOn("IS_LOADING", (event: { detail: { value: boolean } }) => {
      setIsLoading(event.detail.value);
    });
  });

  return (
    <Dimmer className="c-loader" active={isLoading}>
      <Loader content="Cargando..." />
    </Dimmer>
  );
}

export default IsLoading;
