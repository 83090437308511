import React from "react";
import { Button } from "semantic-ui-react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogDeleteInterface } from "./CDialogDeleteInterfaces/interface";

export default function DialogDelete({
  open,
  setOpen,
  title,
  content,
  ButtonOpen,
  actionConfirm,
}:DialogDeleteInterface) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {ButtonOpen && <ButtonOpen />}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={actionConfirm} color="green" autoFocus>
            Confirmar
          </Button>
          <Button onClick={handleClose} color="red">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
