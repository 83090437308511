import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { TableRow, TableCell } from "semantic-ui-react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import UndoIcon from "@mui/icons-material/Undo";
import CDatePicker from "../../../../common/CDatePicker";
import { FieldArrayRenderProps, FormikErrors } from "formik";
import CTextField from "../../../../common/CTextField";
import {
  AccountById,
  Certificate,
} from "../../../../api/services/Account/account.types";
import { toggleMessage } from "../../../../utils/events";
import { Buffer } from "buffer";

type IProps = {
  item: Certificate;
  index: number;
  id?: number;
  arrayHelpers: FieldArrayRenderProps;
  handleChange: {
    (e: ChangeEvent<AccountById>): void;
    <T = string | ChangeEvent<AccountById>>(
      field: T
    ): T extends ChangeEvent<AccountById>
      ? void
      : (e: string | ChangeEvent<AccountById>) => void;
  };
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  errors: FormikErrors<any>;
};

export function TableOfCertificate(props: IProps) {
  const { item, index, id, arrayHelpers, setFieldValue, errors } = props;
  const [renovateCertificate, setRenovateCertificate] =
    useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();

  function readFileAsBytesAsync(file: File): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as ArrayBuffer;
        resolve(new Uint8Array(result));
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  }
  return (
    <TableRow key={index}>
      <TableCell className="c-mwp-10 c-maxw-50">
        {item.id ? (
          <>
            {!renovateCertificate ? (
              <>{item.cUIT}</>
            ) : (
              <Grid container justifyContent="center">
                <Grid item md={12} xs={12} className="c-mt-5">
                  {fileName}
                </Grid>
                <Grid item md={6} xs={6} className="c-mt-5">
                  <input
                    id={`contained-button-file-${index}`}
                    type="file"
                    title=""
                    className="c-none"
                    onChange={async (e) => {
                      let file = e.currentTarget.files?.[0];
                      if (file) {
                        setFileName(file.name);
                        await readFileAsBytesAsync(file)
                          .then((fileBytes) => {
                            const buffer = Buffer.from(fileBytes.buffer);
                            const base64String = buffer.toString("base64");
                            setFieldValue(
                              `certificateList[${index}].certificate`,
                              base64String
                            );
                          })
                          .catch((error) => {
                            toggleMessage("error", error);
                          });
                      }
                    }}
                  />
                  <label htmlFor={`contained-button-file-${index}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      component="span"
                      startIcon={<UploadIcon />}
                      disabled={false}
                    >
                      Cambiar Archivo
                    </Button>
                  </label>
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid container justifyContent="center">
            <Grid item md={12} xs={12} className="c-mt-5">
              {fileName}
            </Grid>
            <Grid item md={6} xs={6} className="c-mt-5">
              <input
                id={`contained-button-file-${index}`}
                type="file"
                title=""
                className="c-none"
                onChange={async (e) => {
                  let file = e.currentTarget.files?.[0];
                  if (file) {
                    setFileName(file.name);
                    await readFileAsBytesAsync(file)
                      .then((fileBytes) => {
                        const buffer = Buffer.from(fileBytes.buffer);
                        const base64String = buffer.toString("base64");
                        setFieldValue(
                          `certificateList[${index}].certificate`,
                          base64String
                        );
                      })
                      .catch((error) => {
                        toggleMessage("error", error);
                      });
                  }
                }}
              />
              <label htmlFor={`contained-button-file-${index}`}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component="span"
                  startIcon={<UploadIcon />}
                  disabled={false}
                >
                  Cambiar Archivo
                </Button>
              </label>
            </Grid>
            {errors[`certificateList[${index}].certificate`] && (
              <FormHelperText
                className={
                  errors[`certificateList[${index}].certificate`]
                    ? "c-font-cancel"
                    : ""
                }
              >
                {errors[`certificateList[${index}].certificate`] ?? null}
              </FormHelperText>
            )}
          </Grid>
        )}
      </TableCell>
      <TableCell className="c-mwp-10"> {id ? item.apiKey : "-"}</TableCell>
      <TableCell className="c-mwp-10">
        <Checkbox
          style={{ color: "#0063cc" }}
          checked={item.whitoutCertificateAFIP}
          onChange={(e) => {
            setFieldValue("certificateList", [
              { whitoutCertificateAFIP: e.target.checked },
            ]);
            setFieldValue("whitoutAFIPCertificate", e.target.checked);
          }}
          name="whitoutAFIPCertificate"
        />
      </TableCell>
      <TableCell className="c-mwp-10 c-maxw-50">
        <CDatePicker
          value={item.certificateExpiredDay}
          setValue={(date) => {
            setFieldValue(
              `certificateList[${index}].certificateExpiredDay`,
              date
            );
          }}
          label="FECHA"
          type="dayWithMonth"
        />
        {errors[`certificateList[${index}].certificate`] && (
          <FormHelperText
            className={
              errors[`certificateList[${index}].certificate`]
                ? "c-font-cancel"
                : ""
            }
          >
            {errors[`certificateList[${index}].certificate`] ?? null}
          </FormHelperText>
        )}
      </TableCell>
      <TableCell>
        <div>
          <div>
            {id && (
              <>
                {renovateCertificate ? (
                  <IconButton
                    onClick={() => {
                      setRenovateCertificate(false);
                      item.changeCertificate = false;
                    }}
                    className="c-grid-btn-cancel"
                  >
                    <UndoIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setRenovateCertificate(true);
                      item.changeCertificate = true;
                    }}
                    className="c-grid-btn-green"
                  >
                    <AutorenewIcon />
                  </IconButton>
                )}
              </>
            )}
            <IconButton
              className="c-grid-btn-cancel"
              onClick={() => arrayHelpers.remove(index)}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
}

export function TableOfCUIT(props: IProps) {
  const { item, index, id, arrayHelpers, handleChange, errors, setFieldValue } =
    props;
  const [renovateCertificate, setRenovateCertificate] =
    useState<boolean>(false);

  return (
    <TableRow key={index}>
      <TableCell className="c-mwp-10 c-maxw-50">
        {item.id ? (
          item.cUIT
        ) : (
          <CTextField
            label=""
            type="number"
            fullWidth
            onChange={(e) => {
              handleChange(e);
            }}
            name={`certificateList[${index}].cUIT`}
            helperText={
              <span className="c-font-cancel">
                {errors[`certificateList[${index}].cUIT`] ?? null}
              </span>
            }
            value={item.cUIT ?? ""}
          />
        )}
      </TableCell>
      <TableCell className="c-mwp-10"> {id ? item.apiKey : ""}</TableCell>
      <TableCell className="c-mwp-10">
        <Checkbox
          style={{ color: "#0063cc" }}
          checked={item.whitoutCertificateAFIP}
          onChange={(e) => {
            setFieldValue("certificateList", [
              { whitoutCertificateAFIP: e.target.checked },
            ]);
            setFieldValue("whitoutAFIPCertificate", e.target.checked);
          }}
          name="whitoutAFIPCertificate"
        />
      </TableCell>
      <TableCell className="c-mwp-10 c-maxw-50" />
      <TableCell>
        <div>
          {id && (
            <>
              {renovateCertificate ? (
                <IconButton
                  onClick={() => {
                    setRenovateCertificate(false);
                  }}
                  className="c-grid-btn-cancel"
                >
                  <UndoIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    setRenovateCertificate(true);
                  }}
                  className="c-grid-btn-green"
                >
                  <AutorenewIcon />
                </IconButton>
              )}
            </>
          )}
          <IconButton
            className="c-grid-btn-cancel"
            onClick={() => arrayHelpers.remove(index)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </TableCell>
    </TableRow>
  );
}
